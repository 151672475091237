import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageAddressService {

  constructor(private http: HttpClient) { }

  getUserAddress(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/useraddresslist?${environment.queryStringData}`, data);
  }

  addUserAddress(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/adduseraddress?${environment.queryStringData}`, data);
  }

  deleteUserAddress(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/deleteuseraddress?${environment.queryStringData}`, data);
  }

  editUserAddress(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/getuseraddressbyid?${environment.queryStringData}`, data);
  }

  updateUserAddress(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/updateuseraddress?${environment.queryStringData}`, data);
  }
}
