<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-manage-orders">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title" id="MainOrder">My Subscription Orders</h2>
            </div>
            <div class="cart-resize">
                <div class="p-3 osahan-cart-item">
                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
                        <div class="d-flex flex-column pt-2">
                            <div class="row ml-0 mr-0">
                                <div class="col-lg-12 col-md-12 col-xs-12 m-0">
                                    <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                    <div class="panel panel-default" >
                                    <div class="panel-heading row" role="tab" id="headingOne">
                                        <h4 class="panel-title col-md-7 col-lg-7 col-xs-6">&nbsp;</h4>
                                        
                                    <h6 class="panel-title col-md-3 col-lg-3 col-xs-3 ">
                                         
                                        <a href="javascript:void(0);"
                                        class="card-link btn btn-primary btn-block "
                                        (click)="AddextraOrder();" >Add Extra Order</a>
                                      </h6> 
                                      <h5 class="panel-title col-md-2 col-lg-2 col-xs-3 ">
                                         
                                        <a role="button" data-toggle="collapse"  data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne" class="collapsed card-link btn btn-primary btn-block" style="text-align: center;">
                                          Filter
                                        </a>
                                      </h5>
                                </div>
                                </div>
                                </div>
                            </div>
                                <div id="collapseOne" class="panel-collapse collapse col-12" aria-labelledby="headingOne" role="tabpanel">
                                    <div class="panel-body col-lg-12 col-md-12 col-xs-12 row">
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    
                                    <label class="form-label">From Date </label>
                                    <div class="input-group">
                                        <input type="date" name="fromDate" class="form-control"
                                            placeholder="Enter From Date" onkeydown="return false" id="fromDate" (change)="changeDate()"
                                            autocomplete="off" required>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    <label class="form-label">To Date </label>
                                    <div class="input-group">
                                        <input type="date" name="toDate" class="form-control"
                                            placeholder="Enter To Date" onkeydown="return false" id="toDate"
                                            autocomplete="off" required>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    <label class="form-label">Order No. </label>
                                    <div class="input-group">
                                        <input type="text" class="form-control" #orderNo autocomplete="off"
                                            name="orderNo" id="orderNo" placeholder="Enter Order No." required>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    <label class="form-label">Tiffin Type </label>
                                    <div class="input-group">
                                        <select name="tiffin_for" class="form-control" id="tiffin_for">
                                            <option value="">Select Tiffin Type</option>
                                            <option value="1">Breakfast</option>
                                            <option value="2">Lunch</option>
                                            <option value="3">Dinner</option>
                                        </select>
                                    </div>
                                </div>
                                </div>
                                <div class="panel-body col-lg-12 col-md-12 col-xs-12 row">
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    <label class="form-label">Order Status </label>
                                    <div class="input-group">
                                        <select name="order_status" class="form-control" id="order_status">
                                            <option value="">Select Order Status</option>
                                            <option value="0">Pending</option>
                                            <option value="6">Placed</option>
                                            <option value="9">Reschedule</option>
                                            <option value="3">Cancel</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 col-xs-12 m-0">
                                    <label class="form-label">Extra Order </label>
                                    <div class="input-group">
                                        <input type="checkbox" class="form-control" id="extra_order" name="extra_order">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 col-xs-12 m-0"> 
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="search" name="search" value="search" class="btn btn-primary btn-md btn-block" (click)="getTiffinOrderList(1)">Search</button>
                                </div>
                                <div class="col-lg-2 col-md-2 col-xs-12 m-0"> 
                                    <label class="form-label">&nbsp;</label>
                                    <button type="button" id="reset" name="reset" value="reset" class="btn btn-danger btn-md btn-block" style="border-radius: 20px;" (click)="resetTiffinData()">Reset</button>
                                </div>
                                </div>
                                </div>
                                <div class="col-lg-12 col-md-12 col-xs-12 m-0  osahan-cart-item-profile round" style="margin-top: 1% !important;">
                                    <button class="tablink col-4" id="currentordertab" (click)="selectionTab ('currentordertab',1 )" >Current Order</button>
                                    <button class="tablink col-4" id="futureordertab"(click)="selectionTab('futureordertab',1 )">Future Order</button>
                                    <button class="tablink col-4" id="allordertab" (click)="selectionTab('allordertab',1)">All Order</button>
                                    <input type="hidden" name="tabvalue" id="tabvalue">
                                </div>


                                  
                                <div class="col-lg-12 col-md-12 col-xs-12 mt-4 mb-4" style="text-align: center;font-size: large;"
                                        id="divNoOrders"> No Order Available</div>
                                <div class="col-lg-4 col-md-4 col-xs-12 m-0"
                                    *ngFor="let orders of orderList | paginate: { itemsPerPage: pageLimit,currentPage: p ,totalItems: totalOrders}">
                                    <div class="card card-body" style="margin: 15px 0;"
                                        [ngStyle]="{ 'background-color': orders.order_status == 0 ? '#fff3e2' : (orders.order_status == 1 ? '#eeffee' : 
                                                (orders.order_status == 2 ? '#e8faff' : (orders.order_status == 3 ? '#ffeae9' : (orders.order_status == 5 ? '#ffffe6' : 
                                                (orders.order_status == 6 ? '#eeffee' : (orders.order_status == 7 ? '#e8faff' : 'none')))))) }">
                                        <span class="star" style="position: absolute;right: 5%;"
                                            *ngIf="orders.extra_order == 1">
                                            <span class="badge badge-success" style="font-size: 90%;">
                                                Extra Order
                                            </span>
                                        </span>
                                        <!-- <br> -->
                                        <!-- <h6 class="card-title"><b>Order No :</b><br> #{{orders.order_id}}_{{orders.external_id}}</h6> -->
                                        <h6 class="card-title"><b>Order no. :</b><br> #{{orders.order_id}}</h6>
                                        <h6 class="card-title"><b>Subscription name :</b> {{orders.tiffin_package_name}}
                                        </h6>
                                        <h6 class="card-title"><b>Tiffin order type :</b>
                                            #{{orders.text_tiffin_order_type}}</h6>
                                        <h6 class="card-title"><b>Order date :</b> {{orders.order_datetime}}</h6>
                                        <h6 class="card-title"
                                            *ngIf="orders.order_status == 3 && orders.subscription_order_cancel_status == 1">
                                            <b> Reschedule Date :</b> {{orders.order_reschedule_date}}
                                        </h6>
                                        <h6 class="card-title"><b>Tiffin end date:</b> {{orders.tiffin_end_date}}</h6>
                                        <hr>
                                        <!-- <p class="card-text"><b>Pay Mode: </b>
                                            <span *ngIf="orders.payment_note != ''"
                                                style="background: #51A351; border: 1px dashed white; padding: 5px; font-family: lato, ubuntu, helvetica, sans-serif; color: white;">
                                                {{orders.payment_note}}
                                            </span>
                                        </p> -->
                                        <p class="card-text"><b>No. of items:</b> {{orders.order_items}}</p>
                                        <!-- <hr> -->
                                        <h6 class="card-subtitle mb-2 text-muted"><b>Total:</b> {{currencySymbol}}
                                            {{orders.orderAmt}}</h6>
                                        <p class="card-text" *ngIf="orders.order_status == 0"
                                            style="color: #ff9500; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 1"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 2"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 3"
                                            style="color: #bd362f; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 5"
                                            style="color: #A6A303; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 6"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 7"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>

                                        <a href="javascript:void(0);" class="card-link btn btn-primary btn-block "
                                            (click)="getOrderDetail(orders);">View
                                            Detail</a>
                                        <div class="row"
                                            *ngIf="orders.is_cancel == 1 && orders.extra_order == 0 && orders.is_reschedule_order != 1 ">
                                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-1">
                                                <a href="javascript:void(0);"
                                                    class="card-link btn btn-primary btn-block "
                                                    (click)="reScheduleOrder(orders);">Re-schedule</a>
                                            </div>
                                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-1">
                                                <a href="javascript:void(0);"
                                                    class="card-link btn btn-primary btn-block"
                                                    (click)="extraOrder(orders);">Extra order</a>
                                            </div>
                                        </div>
                                        <!-- order_status 0 or 1 ,extra_order = 1 -->
                                        <div class="row"
                                            *ngIf="orders.extra_order == 1 && (orders.order_status == '0' || orders.order_status == '1')">
                                            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-1">
                                                <a href="javascript:void(0);"
                                                    class="card-link btn btn-danger btn-block "
                                                    style="border-radius: 20px;"
                                                    (click)="cancleExtraOrder(orders);">Cancel</a>
                                            </div>
                                            <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mt-1">
                                                <a href="javascript:void(0);"
                                                    class="card-link btn btn-primary btn-block"
                                                    (click)="extraOrder(orders);">Extra Order</a>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="orderList != undefined && orderList.length > 0">
                                <div class="col-12 text-right">
                                    <pagination-controls (pageChange)="getTiffinOrderList($event)" responsive="true"
                                        ></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="bg-white rounded shadow mt-3 profile-details row ml-0 mr-0 p-3 mb-5">
                        <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6" routerLink="/manageaddress">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-truck bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Addresses </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a>
                        <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6" routerLink="/profile">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-map-pin bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Profile </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="OrderDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Order detail</b>
                </h5>
                <button type="button" class="close" (click)="closeAllModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #OrderDetailForm="ngForm">
                    <div class="row ml-0 mr-0">
                        <div class="card col-lg-12 col-md-12 col-xs-12">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-5">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span><b>Order Id: </b></span><br>
                                            <span><b>Order date: </b></span><br>
                                            <!-- <span><b>Pickup Date: </b></span> -->
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div style="text-align: right; display: block;">
                                            <p>#{{invoiceId}}</p>
                                            <p>{{orderDate}}</p>
                                            <!-- <p>{{pickupDate}}</p> -->
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-row" #divItems>
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="col-6">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span><b>Item total </b></span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{itemTotal}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row" *ngFor="let taxes of orderItemTaxes">
                                    <div class="col-6">
                                        <div style="height: 100%; line-height: 30px;">
                                            <div *ngIf="taxes.is_gst == 0">
                                                <span>{{taxes.name}}</span>
                                            </div>
                                            <div *ngIf="taxes.is_gst == 1">
                                                <span>SGST</span><br>
                                                <span>CGST</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div style="text-align: right; display: block;">
                                            <div *ngIf="taxes.is_gst == 0">
                                                <p>{{currencySymbol}} {{taxes.tax_amount}}</p>
                                            </div>
                                            <div *ngIf="taxes.is_gst == 1">
                                                <p>{{currencySymbol}} {{taxes.tax_amount / 2}}</p>
                                                <p>{{currencySymbol}} {{taxes.tax_amount / 2}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row" *ngIf="discountAmount != 0">
                                    <div class="col-6">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Discount</span>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{discountAmount}}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="col-6">
                                        <div style="height: 100%; line-height: 30px;">
                                            <h5>
                                                <b>Total</b>
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div style="text-align: right; display: block;">
                                            <h6>{{currencySymbol}} {{orderTotal}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="reScheduleOrderModal" tabindex="-1" role="dialog" style="height: 250px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Reschedule Order</b>
                </h5>
                <button type="button" class="close" (click)="closeAllModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #rescheduleForm="ngForm" (submit)="reScheduleOrderSubmit()">
                    <div class="row ml-0 mr-0">
                        <div class="col-md-12 form-group">
                            <label class="form-label">Reschedule Date</label>
                            <input type="date" name="reScheduleDate" class="form-control" placeholder="Enter Date"
                                onkeydown="return false" id="reScheduleDate" autocomplete="off" required>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade ss-extraorder" id="AddextraOrderModal" tabindex="-1" role="dialog" 
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Add Extra Order</b>
                </h5>
                <button type="button" class="close" (click)="closeAllModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <form #rescheduleForm="ngForm" (submit)="AddextraOrderSearch()"> -->
                    <div class="row ml-0 mr-0">
                        <div class="col-md-4 form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" name="from_date_extra" class="form-control" placeholder="Enter Date"
                                onkeydown="return false" id="from_date_extra" autocomplete="off" >
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" name="to_date_extra" class="form-control" placeholder="Enter Date"
                                onkeydown="return false" id="to_date_extra" autocomplete="off" >
                        </div>
                        <div class="col-md-4 form-group">
                            <label class="form-label">Tiffin Type</label>
                            <select name="tiffin_for_extra" class="form-control" id="tiffin_for_extra">
                                <option value="">Select Tiffin Type</option>
                                <option value="1">Breakfast</option>
                                <option value="2">Lunch</option>
                                <option value="3">Dinner</option>
                            </select>
                        </div>
                        </div>
                        <div class="row ml-0 mr-0 ">
                          
                        <div class="col-lg-2 col-md-2 col-xs-12  form-group" > 
                            <!-- <label class="form-label">&nbsp;</label> -->
                            <button type="button" id="search_extra_order" name="search_extra_order" value="search_extra_order" class="btn btn-primary btn-md btn-block" style="border-radius: 20px;" (click)="getTiffinOrderListForExtraOrder(1)">Search</button>
                        </div>
                        <div class="col-lg-2 col-md-2 col-xs-12  form-group"> 
                            <!-- <label class="form-label">&nbsp;</label> -->
                            <button type="button" id="reset_extra_order" name="reset_extra_order" value="reset_extra_order" class="btn btn-danger btn-md btn-block" style="border-radius: 20px;" (click)="resetTiffinDataForExtraOrder()">Reset</button>
                        </div>
                        <!-- <button type="button" class="btn btn-primary btn-block">Search</button>
                        <button type="button" class="btn btn-primary btn-block">Reset</button> -->
                    </div>
                <!-- </form> -->
                <div style="overflow-x:auto;">
                    <table>
                      <tr>
                        <th>Order no</th>
                        <th>Subscription name</th>
                        <th>Tiffin order type</th>
                        <th>Order date</th>
                        <th>Reschedule Date</th>
                        <th>Tiffin end date</th>
                        <th>No. of items</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Extra Order</th>
                        <!-- <th>Points</th>
                        <th>Points</th> -->
                      </tr>
                      <tr id="divNoOrdersforextra"> <td colspan="10" style="text-align:center">No Order Available</td></tr>
                      <ng-container *ngFor="let extraorders of orderListforExtraorder | paginate: { id:'pagination2' ,itemsPerPage: pageLimit,currentPage: p1 ,totalItems: totalExtraOrders}">
                      <tr>
                        <td>#{{extraorders.order_id}}</td>
                        <td>{{extraorders.tiffin_package_name}}</td>
                        <td>#{{extraorders.text_tiffin_order_type}}</td>
                        <td>{{extraorders.order_datetime}}</td>
                        <td>{{extraorders.order_reschedule_date}}</td>
                        <td>{{extraorders.tiffin_end_date}}</td>
                        <td>{{extraorders.order_items}}</td>
                        <td>{{currencySymbol}} {{extraorders.orderAmt}}</td>
                        <td><p class="card-text" *ngIf="extraorders.order_status == 0"
                            style="color: #ff9500; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 1"
                            style="color: #51A351; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 2"
                            style="color: #2F96B4; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 3"
                            style="color: #bd362f; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 5"
                            style="color: #A6A303; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 6"
                            style="color: #51A351; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p>
                        <p class="card-text" *ngIf="extraorders.order_status == 7"
                            style="color: #2F96B4; font-weight: bolder;">
                            {{extraorders.order_status_display}}</p></td>
                        <td><ng-container *ngIf="extraorders.is_cancel == 1 && extraorders.extra_order == 0 && extraorders.is_reschedule_order != 1 "><a href="javascript:void(0);"
                            class="card-link btn btn-primary btn-block"
                            (click)="extraOrder1(extraorders);">Extra order</a></ng-container>
                        </td>
                      </tr>
                    </ng-container>
                    </table>
                    
                  </div>
                  <div class="row" *ngIf="orderListforExtraorder != undefined && orderListforExtraorder.length > 0">
                    <div class="col-12 text-right">
                        <pagination-controls id="pagination2" (pageChange)="getTiffinOrderListForExtraOrder($event)" responsive="true"
                            ></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="extraOrderModal" tabindex="-1" role="dialog" style="height: 250px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Extra Order</b>
                </h5>
                <button type="button" class="close" (click)="closeAllModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #extraOrderForm="ngForm" (submit)="extraOrderSubmit(1)">
                    <div class="row">
                        <div class="col-8 form-group">
                            <div class="input-group" style="height: 100%; line-height: 30px;">
                                <span><b>Extra Item Qty : </b></span>
                            </div>
                        </div>
                        <div class="col-4 form-group">
                            <span class="count-number float-right">
                                <div class="input-group" style="text-align: right; display: block;">
                                    <button type="button" class="btn-sm left dec btn btnCart" id="decrease"
                                        value="Decrease Value" (click)="countItemChange(0)">
                                        <i class="feather-minus btnIcon"></i>
                                    </button>
                                    <input disabled min="1" max="50" value="1" class="count-number-input"
                                        id="itemQuantity" type="text">
                                    <button type="button" class="btn-sm right inc btn btnCart" id="increase"
                                        value="Increase Value" (click)="countItemChange(1)">
                                        <i class="feather-plus btnIcon"></i>
                                    </button>
                                </div>
                            </span>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade ss-login" id="extraOrderModal1" tabindex="-1" role="dialog" style="height: 250px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Extra Order</b>
                </h5>
                <button type="button" class="close" (click)="closeModalforextraorder()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #extraOrderForm="ngForm" (submit)="extraOrderSubmit(2)">
                    <div class="row">
                        <div class="col-8 form-group">
                            <div class="input-group" style="height: 100%; line-height: 30px;">
                                <span><b>Extra Item Qty : </b></span>
                            </div>
                        </div>
                        <div class="col-4 form-group">
                            <span class="count-number float-right">
                                <div class="input-group" style="text-align: right; display: block;">
                                    <button type="button" class="btn-sm left dec btn btnCart" id="decrease"
                                        value="Decrease Value" (click)="countItemChange1(0)">
                                        <i class="feather-minus btnIcon"></i>
                                    </button>
                                    <input disabled min="1" max="50" value="1" class="count-number-input"
                                        id="itemQuantity1" type="text">
                                    <button type="button" class="btn-sm right inc btn btnCart" id="increase"
                                        value="Increase Value" (click)="countItemChange1(1)">
                                        <i class="feather-plus btnIcon"></i>
                                    </button>
                                </div>
                            </span>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>