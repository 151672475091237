import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';
import { ProfileModel, LocationModel } from '../profile/profile.model';
import { ProfileService } from '../profile/profile.service';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { ManageAddressService } from '../manage-address/manage-address.service';
import { PlaceOrderService } from './place-order.service';
import { HomeService } from '../home/home.service';
import { SharedService } from '../_services/sharedServices.service';
import { NgForm } from '@angular/forms';
import { ManageAddressModel } from '../manage-address/manage-address.model';
import { Loader, LoaderOptions } from 'google-maps';

declare var Razorpay: any;
declare var $: any;

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit {
  @ViewChild('divSubTotal', { static: true }) divSubTotal: ElementRef;
  @ViewChild('divOrderSummary', { static: true }) divOrderSummary: ElementRef;
  @ViewChild('ManageAddressForm') manageAddressForm: NgForm;

  data: ProfileModel;
  locationData: LocationModel;

  addressData: ManageAddressModel;
  @ViewChild('map') mapElement: any;
  map: google.maps.Map;
  options: LoaderOptions = {/* todo */ };
  loader = new Loader(this.cookieService.get("google_map_key"), this.options);

  cookieValue: any;
  locationId: any;
  cartTotalAmount: any;
  userId: any;
  currencySymbol: any;

  placeOrderList: any;
  placeOrderItemList: any;
  taxList: Array<any> = [];
  grandTotalAmount: any;
  orderSubTotal: any;
  discount: any;
  orderType: any = "H";
  addressList: Array<any> = [];
  ConfirmAddress: Array<any> = [];
  paymentgateway_value: Array<any> = [];
  DeliveryCharge: Array<any> = [];

  isOrderAvailable: any;
  orderTime: any;
  payuform: any = {};

  orderNo: any;
  roundoffamt: any = 0.00;
  grandTotalWithoutPackingCharges: any;
  grandTotalWithoutPackingCharges1: any;
  grandTotalAmount1: any;
  delivery_charge_applyon: any;
  internet_charge_applyon: any;
  packing_charge_applyon: any;
  service_charge_applyon: any;
  packingchargeinclude: any;
  orderTypeid: any;
  servicechargeinclude: any;
  internethandlingchargeinclude: any;
  deliverychargeinclude: any;
  internetchanges: any;
  deliverycharegepercentage: any;
  constructor(private cookieService: CookieService, private renderer: Renderer2, private toastr: ToastrService, private service: CheckoutService, private router: Router, private profileService: ProfileService, private homeService: HomeService,
    private authenticationService: AuthenticationService, private spinner: NgxSpinnerService, private addressService: ManageAddressService, private sharedService: SharedService, private placeOrderService: PlaceOrderService, private http: HttpClient) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;
    this.data = new ProfileModel();
    this.locationData = new LocationModel();
    this.addressData = new ManageAddressModel();

    this.orderSubTotal = 0;
    this.orderNo = "";
    this.divOrderSummary.nativeElement.innerHTML = null;
    $("#divOrderSuccess").hide();


   
    this.cookieValue = this.cookieService.get("userId");
    if (this.cookieValue) {
      this.userId = this.cookieValue;

      this.getProfile();
      this.getUserAddress();
    }

    if (this.cookieService.get("location")) {
      this.cookieValue = this.cookieService.get("location");
      this.locationId = this.cookieValue;

      this.getLocationDetail();

      if (localStorage.getItem(this.locationId + "_discount")) {
        this.discount = JSON.parse(localStorage.getItem(this.locationId + "_discount"));
        // document.getElementById("divCouponRemove").style.display = "block";
      } else {
        // document.getElementById("divCouponRemove").style.display = "none";
      }

      if (localStorage.getItem(this.locationId + "_placeOrder")) {
        this.placeOrderList = JSON.parse(localStorage.getItem(this.locationId + "_placeOrder"));
        this.orderSubTotal = (this.discount ? (parseFloat(this.discount.amount) + parseFloat(this.placeOrderList.cart.sub_total)) : parseFloat(this.placeOrderList.cart.sub_total));
        this.cartTotalAmount = this.orderSubTotal;
        this.grandTotalAmount = parseFloat(this.orderSubTotal).toFixed(2);
        if (parseInt(this.cartTotalAmount) <= 0) {
          this.router.navigate(['/menucategory']);
          return;
        }
        this.placeOrderItemList = this.placeOrderList.cart.items;
        // this.setSubTotalUI();
      } else {
        this.router.navigate(['/menucategory']);
        return;
      }
      //payment gateway checking
      // for (var i = 0; i < environment.pgateway_types.length; i++) {
      //   if (environment.pgateway_types[i]["paymenttype"].toLowerCase() == "payumoney" || environment.pgateway_types[i]["paymenttype"].toLowerCase() == "razorpay") {
      //     this.paymentgateway_value[0] = environment.pgateway_types[i]["paymentgateway_value"];
      //     this.paymentgateway_value[0]["paymenttypeunkid"] = environment.pgateway_types[i]["paymenttypeunkid"];
      //     this.paymentgateway_value[0]["paymenttype"] = environment.pgateway_types[i]["paymenttype"];
      //   }
      // }
      if(this.cookieService.get("emp_login") == "1"){
        // $('input[name=ordertype]').parent().removeClass('col-6');
        // $('input[name=ordertype]').parent().addClass('col-4');
        $('input[name=ordertype][value=D]').parent().css('display','');
      }else{
        $('input[name=ordertype][value=D]').parent().css('display','none');
      }
    } else {
      this.router.navigate(['/menucategory']);
    }
    
    this.spinner.hide();
    this.loadExternalScript('https://checkout.razorpay.com/v1/checkout.js');
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  getLocationDetail() {
    var obj = {
      "location_id": this.locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];

        environment.currencySymbol = response.payload.currency.sign;
        var pgateway_types = response.payload.pgateway_types;
        environment.pgateway_types = pgateway_types;
        environment.roundoff = response.payload.roundoff;
        this.cookieService.set("currencySymbol", response.payload.currency.sign);
        this.cookieService.set("pgateway_types", JSON.stringify(pgateway_types));
        this.cookieService.set("google_map_key", response.payload.google_map_key);
        this.cookieService.set("roundoff", response.payload.roundoff);
        this.cookieService.set("sms_gateway_enable", response.payload._sms_gateway_enable);
        if (typeof response.payload.display_price_inc_tax == undefined)
          response.payload.display_price_inc_tax = 0;
        this.cookieService.set("display_price_inc_tax", response.payload.display_price_inc_tax);
        if (response.payload.packingchargepostingtype)
          this.cookieService.set("packing_charge_posting_type", response.payload.packingchargepostingtype);
        else
          this.cookieService.set("packing_charge_posting_type", "0");

        for (var i = 0; i < environment.pgateway_types.length; i++) {
          if (environment.pgateway_types[i]["paymenttype"].toLowerCase() == "payumoney" || environment.pgateway_types[i]["paymenttype"].toLowerCase() == "razorpay") {
            this.paymentgateway_value[0] = environment.pgateway_types[i]["paymentgateway_value"];
            this.paymentgateway_value[0]["paymenttypeunkid"] = environment.pgateway_types[i]["paymenttypeunkid"];
            this.paymentgateway_value[0]["paymenttype"] = environment.pgateway_types[i]["paymenttype"];
          }
        }
        this.currencySymbol = environment.currencySymbol;

        if (response.payload.packingcharge) {
          this.cookieService.set("packingcharge", response.payload.packingcharge);
        } else {
          this.cookieService.set("packingcharge", "0");
        }

        if (response.payload.packingchargetype) {
          this.cookieService.set("packingchargetype", response.payload.packingchargetype);
        } else {
          this.cookieService.set("packingchargetype", "0");
        }

        if (response.payload.packinglabel) {
          this.cookieService.set("packinglabel", response.payload.packinglabel);
        } else {
          this.cookieService.set("packinglabel", "0");
        }

        if (response.payload.servicecharge) {
          this.cookieService.set("servicecharge", response.payload.servicecharge);
        } else {
          this.cookieService.set("servicecharge", "0");
        }
        if (response.payload.servicechargetype) {
          this.cookieService.set("servicechargetype", response.payload.servicechargetype);
        } else {
          this.cookieService.set("servicechargetype", "0");
        }

        if (response.payload.servicechargelabel) {
          this.cookieService.set("servicechargelabel", response.payload.servicechargelabel);
        } else {
          this.cookieService.set("servicechargelabel", "0");
        }
        if (response.payload.servicechargepostingtype == undefined)
          this.cookieService.set("service_charge_posting_type", "0");
        else
          this.cookieService.set("service_charge_posting_type", response.payload.servicechargepostingtype);

        if (response.payload.internetcharge) {
          this.cookieService.set("internetcharge", response.payload.internetcharge);
        } else {
          this.cookieService.set("internetcharge", "0");
        }
        if (response.payload.internetchargetype) {
          this.cookieService.set("internetchargetype", response.payload.internetchargetype);
        } else {
          this.cookieService.set("internetchargetype", "0");
        }

        if (response.payload.internetchargelabel) {
          this.cookieService.set("internetchargelabel", response.payload.internetchargelabel);
        } else {
          this.cookieService.set("internetchargelabel", "0");
        }
        if (response.payload.internetchargepostingtype == undefined)
        {
          this.cookieService.set("internet_charge_posting_type", "0");
        }
        else
        {
          this.cookieService.set("internet_charge_posting_type", response.payload.internetchargepostingtype);
        }
          this.cookieService.set("delivery_charge_applyon", response.payload.delivery_charge_applyon);
          this.cookieService.set("internet_charge_applyon", response.payload.internet_charge_applyon);
          this.cookieService.set("packing_charge_applyon", response.payload.packing_charge_applyon);
          this.cookieService.set("service_charge_applyon", response.payload.service_charge_applyon);
        // no charge user cookie set
        if (response.payload.emp_login == undefined)
          this.cookieService.set("emp_login", "0");
        else
          this.cookieService.set("emp_login", response.payload.emp_login);

        if(this.cookieService.get("emp_login") == "1"){
          // $('input[name=ordertype]').parent().removeClass('col-6');
          // $('input[name=ordertype]').parent().addClass('col-4');
          $('input[name=ordertype][value=D]').parent().css('display','');
        }else{
          $('input[name=ordertype][value=D]').parent().css('display','none');
        }
        this.delivery_charge_applyon = this.cookieService.get("delivery_charge_applyon")?(this.cookieService.get("delivery_charge_applyon")).split(','):'';
        this.internet_charge_applyon = this.cookieService.get("internet_charge_applyon")?(this.cookieService.get("internet_charge_applyon")).split(','):'';
        this.packing_charge_applyon = this.cookieService.get("packing_charge_applyon")?(this.cookieService.get("packing_charge_applyon")).split(','):'';
        this.service_charge_applyon = this.cookieService.get("service_charge_applyon")?(this.cookieService.get("service_charge_applyon")).split(','):'';
        this.selectordertype('D');
        // this.setSubTotalUI();
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
      this.spinner.hide();
    });
  }

  setSubTotalUI() {
    this.spinner.show();
    this.divSubTotal.nativeElement.innerHTML = null;

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Sub Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.cartTotalAmount).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setTaxesUI();
    this.spinner.hide();
  }

  setTaxesUI() {
    this.spinner.show();
    this.grandTotalAmount = parseFloat(this.orderSubTotal).toFixed(2);
    var taxArray = JSON.parse(localStorage.getItem(this.locationId + "_taxData"));
    if (taxArray) {
      for (var idx in taxArray) {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = taxArray[idx].taxDisplayName;
        this.renderer.appendChild(this.divSubTotal.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + parseFloat(taxArray[idx].taxAmount).toFixed(2);
        this.renderer.appendChild(p, span);

        this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(taxArray[idx].taxAmount))).toFixed(2);
      }
    }

    this.grandTotalWithoutPackingCharges = this.grandTotalAmount
    //----------------------------------------------   Packing Charge START
    // this.cookieService.set("packingcharge","0")
    if(this.packing_charge_applyon.length>0){
    this.packingchargeinclude=this.packing_charge_applyon.filter(element => this.orderTypeid.includes(element));
    //console.log('this.packingchargeinclude',this.packingchargeinclude);
    //console.log('this.packing_charge_applyon',this.packing_charge_applyon);
    //console.log('packing_charge_applyon',this.orderTypeid);
    // array_intersect(this.packing_charge_applyon,packing_charge_applyon)
    if(this.packingchargeinclude.length>0)
    {
      if (parseFloat(this.cookieService.get("packingcharge")) > 0) {
        if (this.cookieService.get("packingchargetype") == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");

          var packingchanges: any = 0.00;
          if (this.cookieService.get("packing_charge_posting_type") == "1") {
            packingchanges = (this.grandTotalWithoutPackingCharges * parseFloat(this.cookieService.get("packingcharge"))
            / 100).toFixed(2);
            //console.log('packingchanges',packingchanges);
            //console.log('this.grandTotalWithoutPackingCharges',this.grandTotalWithoutPackingCharges);
            //console.log('parseFloat(this.cookieService.get("packingcharge")',parseFloat(this.cookieService.get("packingcharge")));
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
          } else {
            packingchanges = parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("packingcharge"))
            // / 100).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + packingchanges;
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(packingchanges));
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          //   * count).toFixed(2);
          var packingchanges: any = 0.00;
          if (this.cookieService.get("packing_charge_posting_type") == "1") {
            packingchanges = (this.grandTotalWithoutPackingCharges * parseFloat(this.cookieService.get("packingcharge"))
              / 100).toFixed(2);
          } else {
            packingchanges = (parseFloat(this.cookieService.get("packingcharge")) * count).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + packingchanges;

          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(packingchanges));
        }
      }
    }
  }
    //---------------------------------------------- Packing Charge  END

    // ---------------------------------------------- Service Charge START
    //#region Service Charges
    if(this.service_charge_applyon.length>0){
    this.servicechargeinclude=this.service_charge_applyon.filter(element => this.orderTypeid.includes(element));
    //console.log('this.servicechargeinclude',this.servicechargeinclude);
    //console.log('this.service_charge_applyon',this.service_charge_applyon);
    //console.log('service_charge_applyon',this.orderTypeid);
    // array_intersect(this.packing_charge_applyon,packing_charge_applyon)
    if(this.servicechargeinclude.length>0)
    {
         if (parseFloat(this.cookieService.get("servicecharge")) > 0) {
      if (this.cookieService.get("servicechargetype") == "1") {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.cookieService.get("servicechargelabel");
        this.renderer.appendChild(this.divSubTotal.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");

        var servicechanges: any = 0.00;
        if (this.cookieService.get("service_charge_posting_type") == "1") {
          servicechanges = (this.grandTotalWithoutPackingCharges * parseFloat(this.cookieService.get("servicecharge"))
            / 100).toFixed(2);
          // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("servicecharge")).toFixed(2);
        } else {
          servicechanges = parseFloat(this.cookieService.get("servicecharge")).toFixed(2);
          // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("servicecharge"))
          // / 100).toFixed(2);
        }
        span.innerHTML = this.currencySymbol + " " + servicechanges;
        this.renderer.appendChild(p, span);

        this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(servicechanges));
      } else {
        var qty = [];
        var count = 0;
        for (var q = 0; q < this.placeOrderItemList.length; q++) {
          if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
            qty.push(this.placeOrderItemList[q]["item_id"]);
            count++;
          }
        }
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.cookieService.get("servicechargelabel");
        this.renderer.appendChild(this.divSubTotal.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
        //   * count).toFixed(2);
        var servicechanges: any = 0.00;
        if (this.cookieService.get("service_charge_posting_type") == "1") {
          servicechanges = (this.grandTotalWithoutPackingCharges * parseFloat(this.cookieService.get("servicecharge"))
            / 100).toFixed(2);
        } else {
          servicechanges = (parseFloat(this.cookieService.get("servicecharge")) * count).toFixed(2);
        }
        span.innerHTML = this.currencySymbol + " " + servicechanges;

        this.renderer.appendChild(p, span);

        this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(servicechanges));
      }
         }
    }
  }
    //#endregion
    //---------------------------------------------- Service Charge END

    // ---------------------------------------------- Internet Handling Charge START
    //#region Internet handling charges
    if(this.internet_charge_applyon.length>0){
    this.internethandlingchargeinclude=this.internet_charge_applyon.filter(element => this.orderTypeid.includes(element));
    //console.log('this.internethandlingchargeinclude',this.internethandlingchargeinclude);
    //console.log('this.internet_charge_applyon',this.internet_charge_applyon);
    //console.log('internet_charge_applyon',this.orderTypeid);
    // array_intersect(this.packing_charge_applyon,packing_charge_applyon)
    if(this.internethandlingchargeinclude.length>0)
    {
      if (parseFloat(this.cookieService.get("internetcharge")) > 0) {
        if (this.cookieService.get("internetchargetype") == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1 internethandling1");
          p.innerHTML = this.cookieService.get("internetchargelabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark internethandling");

          var internetchanges: any = 0.00;
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            internetchanges = (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            internetchanges = parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          this.internetchanges=internetchanges;
          span.innerHTML = this.currencySymbol + " " + this.internetchanges;
          this.renderer.appendChild(p, span);
          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(this.internetchanges));
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1 internethandling1");
          p.innerHTML = this.cookieService.get("internetchargelabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark internethandling");
          // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          //   * count).toFixed(2);
          var internetchanges: any = 0.00;
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            internetchanges = (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
          } else {
            internetchanges = (parseFloat(this.cookieService.get("internetcharge")) * count).toFixed(2);
          }
          this.internetchanges=internetchanges;
          span.innerHTML = this.currencySymbol + " " + this.internetchanges;
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + parseFloat(this.internetchanges));
        }
      }
    }
  }
    //#endregion
    // ---------------------------------------------- Internet Handling Charge END

    // ---------------------------------------------- Delivery Charge START
    if(this.delivery_charge_applyon.length>0){
    this.deliverychargeinclude=this.delivery_charge_applyon.filter(element => this.orderTypeid.includes(element));
    //console.log('this.deliverychargeinclude',this.deliverychargeinclude);
    //console.log('this.delivery_charge_applyon',this.delivery_charge_applyon);
    //console.log('delivery_charge_applyon',this.orderTypeid);
    // array_intersect(this.packing_charge_applyon,packing_charge_applyon)
    //console.log('delivery charge',this.DeliveryCharge);
    if(this.deliverychargeinclude.length>0)
    {
      if (this.DeliveryCharge.length > 0) {
        if (this.DeliveryCharge[0]["deliverychargetype"] == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + parseFloat(this.DeliveryCharge[0]["deliverycharge"]).toFixed(2);
          this.renderer.appendChild(p, span);

          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            this.deliverycharegepercentage = ((parseFloat(this.DeliveryCharge[0]["deliverycharge"])) * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            this.deliverycharegepercentage = 0.00;
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          //console.log('if deliverychargepercentage',this.deliverycharegepercentage);
          //console.log('if internetchanges',this.internetchanges);
          this.internetchanges=this.internetchanges+this.deliverycharegepercentage;
          var inner=this.currencySymbol + " " + (parseFloat(this.internetchanges)
          ).toFixed(2);
          $(".internethandling" ).remove();
          $(".internethandling1" ).append('<span class="float-right text-dark">'+inner+'</span>');
          // this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.deliverycharegepercentage))).toFixed(2);
          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]))).toFixed(2);
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + (parseFloat(this.DeliveryCharge[0]["deliverycharge"])
            ).toFixed(2);
          this.renderer.appendChild(p, span);
         
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            this.deliverycharegepercentage = ((parseFloat(this.DeliveryCharge[0]["deliverycharge"])) * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            this.deliverycharegepercentage = 0.00;
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          //console.log('else deliverychargepercentage',this.deliverycharegepercentage);
          //console.log('else internetchanges',this.internetchanges);
          this.internetchanges = (parseFloat(this.internetchanges) + parseFloat(this.deliverycharegepercentage)).toFixed(2);
          //console.log('else 1before internetchanges',this.internetchanges);
          var inner=this.currencySymbol + " " + (parseFloat(this.internetchanges)
          ).toFixed(2);
          $(".internethandling" ).remove();
          $(".internethandling1" ).append('<span class="float-right text-dark">'+inner+'</span>');
          // this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.deliverycharegepercentage))).toFixed(2);
          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]))).toFixed(2);
        }
        // this.grandTotalAmount = this.grandTotalAmount - this.roundoffamt;
      }
    }
  }
    // ---------------------------------------------- Delivery Charge END

    if (this.discount) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discount.amount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      this.grandTotalAmount = parseFloat(this.grandTotalAmount) - (parseFloat(this.discount.amount));
    }

    if (environment.roundoff != undefined && environment.roundoff != null) {
      const pRoundOff: HTMLParagraphElement = this.renderer.createElement('p');
      pRoundOff.setAttribute("class", "mb-1");
      pRoundOff.innerHTML = "Rounding off ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pRoundOff);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");

      if (environment.roundoff == "0.1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount).toFixed(1)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);
        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(1);
      }
      else if (environment.roundoff == "1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount).toFixed(0)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else if (environment.roundoff == "10") {
        var am = Math.round(this.grandTotalAmount / 10) * 10 - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else {
        var amt = parseFloat(parseFloat(this.grandTotalAmount).toFixed(2)) - parseFloat(this.grandTotalAmount)
        spanDiscount.innerHTML = this.currencySymbol + " 0.00";
        this.roundoffamt = "0.00";

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + amt;
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(2);
      }
      this.renderer.appendChild(pRoundOff, spanDiscount);
    }
    this.setGrandTotalUI();
    this.spinner.hide();
  }

  setGrandTotalUI() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divSubTotal.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 pb-2");
    h6GrandTotal.innerHTML = "Grand Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right");
    spanGrandTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.grandTotalAmount);
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);
    this.spinner.hide();
  }

  getProfile() {
    var obj = {
      "user_id": this.userId
    };

    this.spinner.show();
    this.profileService.getProfile(obj).subscribe(response => {
      if (response.status == "ok") {
        this.data = response.payload;
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
      this.spinner.hide();
    });
  }

  addAddress() {
    $("#AddressModal").modal("show");

    $("#lblOption0").addClass("focus active");
    $("#option0").attr('checked', 'checked');

    let encryptedPlaceOrder = btoa('placeorder');
    // this.router.navigate(['/manageaddress/' + encryptedPlaceOrder]);
  }

  locateAddress(lng, lat) {
    if (this.addressData.address.length == 0 && this.addressData.city.length == 0 && this.addressData.pincode.length == 0) {
      this.loader.load().then(function (google) {
        var myLatLng = new google.maps.LatLng(lat, lng),
          myOptions = {
            zoom: 18,
            center: myLatLng,
          },
          map1 = new google.maps.Map(document.getElementById('map'), myOptions),
          marker = new google.maps.Marker({ position: myLatLng, map: map1, draggable: true, });

        map1.addListener("marker_change", () => {
          window.setTimeout(() => {
            map1.panTo(marker.getPosition());
            this.lati = marker.getPosition().lat();
            this.lngi = marker.getPosition().lng();
          }, 3000);
        });
        marker.addListener("dragend", () => {
          map1.setCenter(marker.getPosition());
          this.lati = marker.getPosition().lat();
          this.lngi = marker.getPosition().lng();
        });
        marker.setMap(map1);
      });
    }
    else {
      this.setWithAddress();
    }
  }

  setWithAddress() {
    var address = this.addressData.address + ", " + this.addressData.city + ", " + this.addressData.pincode
    var longitude, latitude;
    this.spinner.show();
    this.loader.load().then(function (google) {
      new google.maps.Geocoder().geocode({ 'address': address }, function (results, status) {
        if (status == 'OK') {
          var myLatLng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()),
            myOptions = {
              zoom: 18,
              center: myLatLng,
            },
            map1 = new google.maps.Map(document.getElementById('map'), myOptions),
            marker = new google.maps.Marker({ position: myLatLng, map: map1, draggable: false, });

          marker.setMap(map1);
          longitude = marker.getPosition().lng().toString();
          latitude = marker.getPosition().lat().toString();

          if (longitude)
            $(".searched_longi").val(marker.getPosition().lng().toString());
          if (latitude)
            $(".searched_lati").val(marker.getPosition().lat().toString());

        } else {
          $(".searched_longi").val("");
          $(".searched_lati").val("");
          //this.toastr.error('No address found for your address because of - ' + status);
        }
      })
    });

    // window.setTimeout(() => {
    //   this.data.longitude = longitude;
    //   this.data.latitude = latitude;
    this.spinner.hide()
    // }, 700)
  }

  saveAddress(manageAddress: ManageAddressModel) {
    manageAddress.firstname = (this.cookieService.get("firstName") ? this.cookieService.get("firstName") : "");
    manageAddress.lastname = (this.cookieService.get("lastName") ? this.cookieService.get("lastName") : "");
    manageAddress.phoneno = (this.cookieService.get("phoneNo") ? this.cookieService.get("phoneNo") : "");
    manageAddress.country = "";
    manageAddress.state = "";

    this.addressData.latitude = $(".searched_lati").val();
    this.addressData.longitude = $(".searched_longi").val();

    manageAddress.latitude = this.addressData.latitude;
    manageAddress.longitude = this.addressData.longitude;
    manageAddress.location_id = this.cookieService.get("location");

    manageAddress.address_type = "0";
    var rbAddressType = document.forms[0].elements['address_type'];
    for (var i = 0; i < rbAddressType.length; i++) {
      if (rbAddressType[i].checked) {
        manageAddress.address_type = rbAddressType[i].value;
      }
    }

    if (this.manageAddressForm.valid) {
      if ((this.addressData.longitude == undefined || this.addressData.longitude.trim().length == 0) ||
        (this.addressData.latitude == undefined || this.addressData.latitude.trim().length == 0)) {
        this.toastr.error("Enter valid address");
        return
      }

      this.spinner.show();

      this.addressService.addUserAddress(manageAddress).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.resetAddressForm();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });

    }
  }

  resetAddressForm() {
    this.getUserAddress();
    this.manageAddressForm.resetForm();
    $("#AddressModal").modal("hide");

    $("#lblOption1").removeClass("focus active");
    $("#option1").attr('checked', false);

    $("#lblOption2").removeClass("focus active");
    $("#option2").attr('checked', false);
  }

  getUserAddress() {
    var obj = {}
    this.spinner.show();
    this.addressService.getUserAddress(obj).subscribe(response => {
      if (response.status == "ok") {
        if (response.payload != "") {
          this.addressList = response.payload;
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  setaddress(address) {
    this.ConfirmAddress[0] = address;
    var obj = {
      "location_id": this.locationId,
      "ordertype": 3,
      "useraddress_id": this.ConfirmAddress[0].useraddress_id,
      "cart_amount": this.grandTotalWithoutPackingCharges,
    };
    this.spinner.show();
    this.placeOrderService.getDeliveryCharge(obj).subscribe(response => {
      this.spinner.hide();
      if (response.status == "ok") {
        if (response.payload.deliverycharge == undefined) {
          return
        }
        this.DeliveryCharge[0] = response.payload;

        this.setSubTotalUI();
      } else {
        this.toastr.error(response.message);
      }
    }, (err: HttpErrorResponse) => {
      ////console.log.log(err.toString())
      this.toastr.error(err.toString());
      //this.authenticationService.logout();
      this.spinner.hide();
    });

  }

  RemoveAddress() {
    this.ConfirmAddress = [];
    if (this.DeliveryCharge.length > 0) {
      this.DeliveryCharge = [];
      this.setSubTotalUI();
    }
  }

  selectordertype(type) {
    if (type == "P") {
      this.orderType = "P";
      this.orderTypeid = ["0"];
      this.DeliveryCharge = [];
      this.ConfirmAddress = [];
      this.cookieService.set("orderTypeid", this.orderTypeid);
      this.setSubTotalUI();
    } else if (type == "D") {
      this.orderType = "D";
      this.orderTypeid = ["1"];
      this.DeliveryCharge = [];
      this.ConfirmAddress = [];
      this.cookieService.set("orderTypeid", this.orderTypeid);
      this.setSubTotalUI();
    } else {
      this.orderType = "H";
      this.orderTypeid = ["3"];
      this.cookieService.set("orderTypeid", this.orderTypeid);
      this.setSubTotalUI();
    }
  }

  placeOrder(payment_method) {
    //Note is remaining
    $("#btnPlaceOrder").attr("disabled", true);
    $("#btnPlaceOrderOnline").attr("disabled", true);

    if (this.orderType == "H" && this.ConfirmAddress.length == 0) {
      this.toastr.error("Select delivery address or change order type");
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
      return
    }
    var orderDetail = [];

    if (localStorage.getItem(this.locationId + "_orderDetail")) {
      this.spinner.show();
      orderDetail = JSON.parse(localStorage.getItem(this.locationId + "_orderDetail"));

      if (this.orderType == "H") {
        orderDetail["ordertype"] = 3
        orderDetail["useraddress_id"] = this.ConfirmAddress[0]["useraddress_id"]
      } else if (this.orderType == "P") {
        orderDetail["ordertype"] = 2
      } else if (this.orderType == "D") {
        orderDetail["ordertype"] = 1
      }
      orderDetail["adjustment_amount"] = this.roundoffamt;

      this.service.validateOrder(orderDetail).subscribe(response => {
        if (response.status == "ok") {
          orderDetail["payment_method"] = payment_method;

          if (orderDetail["payment_method"] == "ONLINE") {
            this.onlinePay("ABC123", response.payload.razorpayorderid, orderDetail);
          } else {
            this.confirmOrder(orderDetail);
          }
        } else {
          this.toastr.error(response.message);
          $("#btnPlaceOrder").attr("disabled", false);
          $("#btnPlaceOrderOnline").attr("disabled", false);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        // this.authenticationService.logout();
        this.toastr.error(err.toString());
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
        this.spinner.hide();
      });
      this.spinner.hide();
    } else {
      this.toastr.error("Order Detail Not Available");
    }
  }

  confirmOrder(orderDetail) {
    this.spinner.show();
    orderDetail.note = "";
    //orderDetail.payment_method = "COD";
    orderDetail.order_serve_date = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    this.service.placeOrder(orderDetail).subscribe(response => {
      if (response.status == "ok") {
        // this.cookieService.set("orderId", response.payload.foliono + "_" + response.payload.external_id);
        // this.orderNo = response.payload.foliono + "_" + response.payload.external_id
        this.cookieService.set("orderId", response.payload.foliono);
        this.orderNo = response.payload.foliono;

        if (orderDetail["payment_method"] == "ONLINE") {
          this.changeOrderStatus(this.orderNo, response.payload.external_id, this.grandTotalAmount);
        }
        // else {
        // //this.router.navigate(["/ordersuccess"]);
        $("#divPlaceOrder").hide();
        $("#divOrderSuccess").show();
        this.setSubTotalUIOrderSuccess();
        // }

      } else {
        this.toastr.error(response.message);
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      // this.authenticationService.logout();
      this.toastr.error(err.toString());
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
      this.spinner.hide();
    });
  }

  onlinePay(orderid, razorpayorderid, orderDetail) {
    this.spinner.show();
    if (this.paymentgateway_value.length > 0) {
      if (this.paymentgateway_value[0]["paymenttype"].toLowerCase() == "razorpay") {
        var options = {
          "key": this.paymentgateway_value[0]["keyid"],
          // "key":"rzp_test_m3HG7mgkgDh4QZ",
          "amount": this.grandTotalAmount * 100, // 2000 paise = INR 20
          "name": " opus infiniti",
          "currency": this.currencySymbol,
          "description": "Order #",
          "order_id": razorpayorderid,
          "image": "assets/img/logo/logo1.jpg",
          "notes": {},
          "theme": {
            "color": "blue"
          },
          "modal": {
            "ondismiss": function () {
              $("#btnPlaceOrder").attr("disabled", false);
              $("#btnPlaceOrderOnline").attr("disabled", false);
            }
          }
        };
        options["handler"] = ((response) => {
          this.confirmOrder(orderDetail);
          // this.changeOrderStatus(orderid, response.razorpay_payment_id, this.grandTotalAmount);
        });
        var rzp1 = new Razorpay(options);
        rzp1.open();
      }
      // else if (this.paymentgateway_value[0]["paymenttype"] == "PayuMoney") {
      //   // payumoneyaccessurl: "https://pos.netsolitsolution.com/payumoney/process.php"
      //   // payumoneyfailureurl: "https://pos.netsolitsolution.com/payumoney/failure.php"
      //   // payumoneysuccessurl: "https://pos.netsolitsolution.com/payumoney/success.php"
      //   //merchantid: "mntBR7mg"
      //   //secretkey: "qHXqTWK20h"

      //   const formData = new FormData();
      //   formData.append('pnd',"aaaaaaaaaa");
      //   formData.append('pst',"aaaaaaaaaa");
      //   formData.append('appname',"sundaramsky");
      //   formData.append('ext',"PM");
      //   formData.append('udf1',"32a689e18ae722c58c7d2a14bd22c75f");
      //   formData.append('ext_user', environment.queryStringData);
      //   const url = this.paymentgateway_value[0]["payumoneyaccessurl"];

      //   this.http.post<any>(url, formData).subscribe(response => {
      //     // if (response. == "ok") {

      //     // }
      //     this.spinner.hide();
      //   }, (err: HttpErrorResponse) => {
      //     // this.authenticationService.logout();
      //     $("#btnPlaceOrder").attr("disabled", false);
      //     this.spinner.hide();
      //   });
      // }
    }
    this.spinner.hide();
  }

  changeOrderStatus(orderid, payment_id, grandTotalAmount) {
    this.spinner.show();

    var data = {
      "payment_id": payment_id,
      "order_id": orderid,
      "amount": grandTotalAmount,
      "payment_method": this.paymentgateway_value[0]["paymenttype"],
      "payment_status": "Success",
      "payment_note": this.paymentgateway_value[0]["paymenttype"],
      "paymenttypeunkid": this.paymentgateway_value[0]["paymenttypeunkid"],
      "location_id": this.locationId
    };

    this.placeOrderService.updatePaymentStatus(data).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        $("#divPlaceOrder").hide();
        $("#divOrderSuccess").show();
        // this.setSubTotalUIOrderSuccess();
        this.spinner.hide();
        //this.router.navigateByUrl('/ordersuccess');
        //this.router.navigate(["/ordersuccess"]);
      } else {
        this.toastr.error(response.message);
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
    });

    this.spinner.hide();
  }

  //#region 
  setSubTotalUIOrderSuccess() {
    this.spinner.show();
    this.divOrderSummary.nativeElement.innerHTML = null;

    const h4SubTotal: HTMLParagraphElement = this.renderer.createElement('h4');
    h4SubTotal.innerHTML = "Order Summary ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h4SubTotal);

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Total ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.orderSubTotal).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setTaxesUIOrderSuccess();
    this.spinner.hide();
  }

  setTaxesUIOrderSuccess() {
    this.spinner.show();
    var taxArray = JSON.parse(localStorage.getItem(this.locationId + "_taxData"));
    this.grandTotalAmount1 = parseFloat(this.orderSubTotal).toFixed(2);
    //console.log('taxArray',taxArray);
    for (var idx in taxArray) {
      const p: HTMLParagraphElement = this.renderer.createElement('p');
      p.setAttribute("class", "mb-1");
      p.innerHTML = taxArray[idx].taxDisplayName;
      this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

      const span: HTMLParagraphElement = this.renderer.createElement('span');
      span.setAttribute("class", "float-right text-dark");
      span.innerHTML = this.currencySymbol + " " + parseFloat(taxArray[idx].taxAmount).toFixed(2);
      this.renderer.appendChild(p, span);
      //console.log('grand',this.grandTotalAmount1);
      this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + (parseFloat(taxArray[idx].taxAmount))).toFixed(2);
    }
    this.grandTotalWithoutPackingCharges1 = this.grandTotalAmount1;
    //console.log('grandTotalWithoutPackingCharges1',this.grandTotalWithoutPackingCharges1);
    if(this.packing_charge_applyon.length>0){
    if(this.packingchargeinclude.length>0)
    {
      if (parseFloat(this.cookieService.get("packingcharge")) > 0) {
        if (this.cookieService.get("packingchargetype") == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
          var packingchanges: any = 0.00;
          if (this.cookieService.get("packing_charge_posting_type") == "1") {
            packingchanges = (this.grandTotalWithoutPackingCharges1 * parseFloat(this.cookieService.get("packingcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
          } else {
            packingchanges = parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount1 * parseFloat(this.cookieService.get("packingcharge"))
            // / 100).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + packingchanges;

          this.renderer.appendChild(p, span);

        this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(packingchanges));
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          //   * count).toFixed(2);
          var packingchanges: any = 0.00;
          if (this.cookieService.get("packing_charge_posting_type") == "1") {
            packingchanges = (this.grandTotalWithoutPackingCharges1 * parseFloat(this.cookieService.get("packingcharge"))
              / 100).toFixed(2);
          } else {
            packingchanges = (parseFloat(this.cookieService.get("packingcharge")) * count).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + packingchanges;
          this.renderer.appendChild(p, span);

          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(packingchanges));
        }
      }
    }
  }
    //---------------------------------------------- Packing Charge  END
    //#region Service Charges
    if(this.service_charge_applyon.length>0){
    if(this.servicechargeinclude.length>0)
    {
      if (parseFloat(this.cookieService.get("servicecharge")) > 0) {
        if (this.cookieService.get("servicechargetype") == "1") {
          //console.log('enter service charge');
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("servicechargelabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");

          var servicechanges: any = 0.00;
          if (this.cookieService.get("service_charge_posting_type") == "1") {
            servicechanges = (this.grandTotalWithoutPackingCharges1 * parseFloat(this.cookieService.get("servicecharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("servicecharge")).toFixed(2);
          } else {
            servicechanges = parseFloat(this.cookieService.get("servicecharge")).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("servicecharge"))
            // / 100).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + servicechanges;
          this.renderer.appendChild(p, span);

          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(servicechanges));
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("servicechargelabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          //   * count).toFixed(2);
          var servicechanges: any = 0.00;
          if (this.cookieService.get("service_charge_posting_type") == "1") {
            servicechanges = (this.grandTotalWithoutPackingCharges1 * parseFloat(this.cookieService.get("servicecharge"))
              / 100).toFixed(2);
          } else {
            servicechanges = (parseFloat(this.cookieService.get("servicecharge")) * count).toFixed(2);
          }
          span.innerHTML = this.currencySymbol + " " + servicechanges;

          this.renderer.appendChild(p, span);
          //console.log(this.renderer);

          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(servicechanges));
        }
      }
    }
  }
    //#endregion
    //---------------------------------------------- Service Charge END

    // ---------------------------------------------- Internet Handling Charge START
    //#region Internet handling charges
    if(this.internet_charge_applyon.length>0){
    if(this.internethandlingchargeinclude.length>0)
    {
      if (parseFloat(this.cookieService.get("internetcharge")) > 0) {
        if (this.cookieService.get("internetchargetype") == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1 internethandling1" );
          p.innerHTML = this.cookieService.get("internetchargelabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark internethandling");

          var internetchanges: any = 0.00;
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            internetchanges = (this.grandTotalAmount1 * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            internetchanges = parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          this.internetchanges=internetchanges;
          span.innerHTML = this.currencySymbol + " " + this.internetchanges;
          this.renderer.appendChild(p, span);

          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(this.internetchanges));
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1 internethandling1");
          p.innerHTML = this.cookieService.get("internetchargelabel");
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark internethandling");
          // span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          //   * count).toFixed(2);
          var internetchanges: any = 0.00;
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            internetchanges = (this.grandTotalAmount1 * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
          } else {
            internetchanges = (parseFloat(this.cookieService.get("internetcharge")) * count).toFixed(2);
          }
          this.internetchanges=internetchanges;
          span.innerHTML = this.currencySymbol + " " + internetchanges;

          this.renderer.appendChild(p, span);

          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + parseFloat(this.internetchanges));
        }
      }
    }
  }
    //#endregion
    // ---------------------------------------------- Internet Handling Charge END
//console.log('delivery charge',this.DeliveryCharge);
    // ---------------------------------------------- Delivery Charge START
    if(this.delivery_charge_applyon.length>0){
    if(this.deliverychargeinclude.length>0)
    {
      if (this.DeliveryCharge.length > 0) {
        //console.log('enter delivery charge');
        if (this.DeliveryCharge[0]["deliverychargetype"] == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + parseFloat(this.DeliveryCharge[0]["deliverycharge"]).toFixed(2);
          this.renderer.appendChild(p, span);
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            this.deliverycharegepercentage = ((parseFloat(this.DeliveryCharge[0]["deliverycharge"])) * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            this.deliverycharegepercentage = 0.00;
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          //console.log('if deliverychargepercentage',this.deliverycharegepercentage);
          //console.log('if internetchanges',this.internetchanges);
          this.internetchanges=this.internetchanges+this.deliverycharegepercentage;
          
          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]))).toFixed(2);
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + (parseFloat(this.DeliveryCharge[0]["deliverycharge"])
            ).toFixed(2);
          this.renderer.appendChild(p, span);
          if (this.cookieService.get("internet_charge_posting_type") == "1") {
            this.deliverycharegepercentage = ((parseFloat(this.DeliveryCharge[0]["deliverycharge"])) * parseFloat(this.cookieService.get("internetcharge"))
              / 100).toFixed(2);
            // span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("internetcharge")).toFixed(2);
          } else {
            this.deliverycharegepercentage = 0.00;
            // span.innerHTML = this.currencySymbol + " " + (this.grandTotalAmount * parseFloat(this.cookieService.get("internetcharge"))
            // / 100).toFixed(2);
          }
          //console.log('else deliverychargepercentage',this.deliverycharegepercentage);
          //console.log('else internetchanges',this.internetchanges);
          this.internetchanges = (parseFloat(this.internetchanges) + parseFloat(this.deliverycharegepercentage)).toFixed(2);
          //console.log('else 1before internetchanges',this.internetchanges);
          var inner=this.currencySymbol + " " + (parseFloat(this.internetchanges)
          ).toFixed(2);
          $(".internethandling" ).remove();
          $(".internethandling1" ).append('<span class="float-right text-dark internethandling">'+inner+'</span>');
          // this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + (parseFloat(this.deliverycharegepercentage))).toFixed(2);
          this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) )).toFixed(2);
          // this.grandTotalAmount1 = (parseFloat(this.grandTotalAmount1) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
        }
      }
    }
  }
    if (this.discount) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divOrderSummary.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discount.amount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1) - (parseFloat(this.discount.amount));
    }
    if (environment.roundoff != undefined && environment.roundoff != null) {
      const pRoundOff: HTMLParagraphElement = this.renderer.createElement('p');
      pRoundOff.setAttribute("class", "mb-1");
      pRoundOff.innerHTML = "Rounding off ";
      this.renderer.appendChild(this.divOrderSummary.nativeElement, pRoundOff);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");

      if (environment.roundoff == "0.1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount1).toFixed(1)) - parseFloat(this.grandTotalAmount1);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);
        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1) + parseFloat(am.toFixed(2));
        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1).toFixed(1);
      }
      else if (environment.roundoff == "1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount1).toFixed(0)) - parseFloat(this.grandTotalAmount1);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1) + parseFloat(am.toFixed(2));
        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1).toFixed(0);
      }
      else if (environment.roundoff == "10") {
        var am = Math.round(this.grandTotalAmount1 / 10) * 10 - parseFloat(this.grandTotalAmount1);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1) + parseFloat(am.toFixed(2));
        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1).toFixed(0);
      }
      else {
        var amt = parseFloat(parseFloat(this.grandTotalAmount1).toFixed(2)) - parseFloat(this.grandTotalAmount1)
        spanDiscount.innerHTML = this.currencySymbol + " 0.00";
        this.roundoffamt = "0.00";

        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1) + amt;
        this.grandTotalAmount1 = parseFloat(this.grandTotalAmount1).toFixed(2);
      }
      this.renderer.appendChild(pRoundOff, spanDiscount);
    }

    this.setGrandTotalUIOrderSuccess();
    this.spinner.hide();
  }

  setGrandTotalUIOrderSuccess() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divOrderSummary.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 text-success");
    h6GrandTotal.innerHTML = "TO PAY ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right text-success");
    spanGrandTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.grandTotalAmount1).toFixed(2);
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);

    this.clearLocalStorage();
    this.spinner.hide();
  }

  backToHome() {
    this.spinner.show();
    this.clearLocalStorage();
    this.router.navigate(['/home']);
    //this.router.navigateByUrl('/home');
    this.spinner.hide();
  }

  clearLocalStorage() {
    localStorage.removeItem(this.locationId + "_items");
    localStorage.removeItem(this.locationId + "_placeOrder");
    localStorage.removeItem(this.locationId + "_orderDetail");
    localStorage.removeItem(this.locationId + "_taxData");
    localStorage.removeItem(this.locationId + "_discount");

    // localStorage.clear()

    this.cookieService.delete('orderId');
    this.sharedService.sendClickEvent();
  }
  //#endregion
}
