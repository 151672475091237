import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  constructor(private toastr: ToastrService, private cookieService: CookieService, private spinner: NgxSpinnerService, private checkoutService: CheckoutService) { }

  ngOnInit(): void {
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    } 
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
