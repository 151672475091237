<app-header></app-header>
<router-outlet></router-outlet>
<app-menu-footer></app-menu-footer>
<app-menu-sidebar></app-menu-sidebar>
<div class="modal fade ss-login" id="LoginModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true" data-backdrop="static" data-keyboard="false"
   style="height: 500px; top: 100px; overflow-y: hidden;">
<div class="modal-dialog">
   <div class="modal-content">
      <div class="modal-header">
         <button type="button" class="close ss-left" data-dismiss="modal" aria-label="Close"
            (click)="resetForm();">
         <span aria-hidden="true">
         </span>
         </button>
         <h5 class="modal-title" id="exampleModalLabel">Login {{formTitle}}</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm();">
         <span aria-hidden="true">&times;</span>
         </button>
      </div>
      <div class="modal-body">
          <ng-container *ngIf="logintype==1">
         <form class="pt-2" #LoginForm="ngForm" id="LoginForm" class="mt-4" (ngSubmit)="sendLoginOTP()">
         <!--login() -->
         <div class="pt-2">
            <ng-container *ngIf="logintype == 1">
            <div class="form-row">
               <div class="col-md-12 form-group">
                  <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <div id="button-email" type="button" class="btn btn-outline-secondary">
                           <i class="feather-phone-call"></i>
                        </div>
                     </div>
                     <input type="text" #email="ngModel" ngModel name="email" minlength="10"
                     maxlength="10" placeholder="Enter Mobile No" class="form-control" id="email"
                     [(ngModel)]="userEmail" numbersOnly required>
                     <!--  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
                  </div>
                  <span *ngIf="LoginForm.submitted && email.invalid">
                     <p *ngIf="email.errors.required" class="error_message" style="color: red;">
                        Mobile No. is required
                     </p>
                     <p *ngIf="email.errors.minlength" class="error_message" style="color: red;">
                        Mobile No. must be of 10 digit
                     </p>
                     <!-- <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                        Email is invalid
                        </p> -->
                  </span>
                  <!-- <p *ngIf="emailerrors != undefined && emailerrors != '' " class="error_message"
                     style="color: red;">
                     {{emailerrors}}
                     </p> -->
               </div>
            </div>
            </ng-container>
            <ng-container *ngIf="logintype == 0">
            <div class="form-row">
               <div class="col-md-12 form-group">
                  <label class="form-label">Email <span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <div id="button-email" type="button" class="btn btn-outline-secondary"><i
                           class="feather-mail"></i>
                        </div>
                        
                     </div>
                     <input type="text" #email="ngModel" ngModel name="email"  placeholder="Enter Email" class="form-control" id="email"
                        [(ngModel)]="userEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
                    </div>
                     <span *ngIf="LoginForm.submitted && email.invalid">
                        <p *ngIf="email.errors.required" class="error_message" style="color: red;">
                           Email is required
                        </p>
                        <!-- <p *ngIf="email.errors.minlength" class="error_message" style="color: red;">
                           Mobile No. must be of 10 digit
                           </p> -->
                        <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                           Email is invalid
                        </p>
                        <p *ngIf="emailerrors != undefined && emailerrors != '' " class="error_message"
                           style="color: red;">
                           {{emailerrors}}
                        </p>
                     </span>
                 
               </div>
               </div>
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Password <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #password="ngModel" ngModel
                        name="password" id="password" placeholder="Enter Password"
                        [(ngModel)]="userPassword" autocomplete="off" required>
                     </div>
                     <span *ngIf="LoginForm.submitted && password.invalid">
                        <p *ngIf="password.errors.required" class="error_message" style="color: red;">
                           Password is required
                        </p>
                     </span>
                  </div>
               </div>
            </ng-container>
            </div>
            <div class="modal-footer p-0 border-0">
               <p class="pt-1 pb-2 text-center m-auto" *ngIf="emp_login != 1">Don’t have an account? <a href="javascript:void(0);"
                  data-dismiss="modal" data-toggle="modal" data-target="#SignupPopup">Register Here</a>
               </p>
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-md btn-block"
                     style="border-radius: 30px;">Continue</button>
               </div>
               <!-- <p class="pt-1 pb-2 text-center m-auto">
                  <a href="javascript:void(0);" style="color: #094f8d;" data-dismiss="modal"
                      data-toggle="modal" data-target="#ForgotPasswordModal"
                      (click)="forgotPass()"><strong>Forgot Password?</strong></a>
                  </p> -->
            </div>
            </form>
        </ng-container>
        <ng-container *ngIf="logintype==0">
            <form class="pt-2" #LoginForm="ngForm" id="LoginForm" class="mt-4" (ngSubmit)="login1()">
            <!--login() -->
            <div class="pt-2">
               <ng-container *ngIf="logintype == 1">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <div id="button-email" type="button" class="btn btn-outline-secondary">
                              <i class="feather-phone-call"></i>
                           </div>
                        </div>
                        <input type="text" #email="ngModel" ngModel name="email" minlength="10"
                        maxlength="10" placeholder="Enter Mobile No" class="form-control" id="email"
                        [(ngModel)]="userEmail" numbersOnly required>
                        <!--  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" -->
                     </div>
                     <span *ngIf="LoginForm.submitted && email.invalid">
                        <p *ngIf="email.errors.required" class="error_message" style="color: red;">
                           Mobile No. is required
                        </p>
                        <p *ngIf="email.errors.minlength" class="error_message" style="color: red;">
                           Mobile No. must be of 10 digit
                        </p>
                        <!-- <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                           Email is invalid
                           </p> -->
                     </span>
                     <!-- <p *ngIf="emailerrors != undefined && emailerrors != '' " class="error_message"
                        style="color: red;">
                        {{emailerrors}}
                        </p> -->
                  </div>
               </div>
               </ng-container>
               <ng-container *ngIf="logintype == 0">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Email <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <div id="button-email" type="button" class="btn btn-outline-secondary"><i
                              class="feather-mail"></i>
                           </div>
                           
                        </div>
                        <input type="text" #email="ngModel" ngModel name="email"  placeholder="Enter Email" class="form-control" id="email"
                           [(ngModel)]="userEmail" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
                       </div>
                        <span *ngIf="LoginForm.submitted && email.invalid">
                           <p *ngIf="email.errors.required" class="error_message" style="color: red;">
                              Email is required
                           </p>
                           <!-- <p *ngIf="email.errors.minlength" class="error_message" style="color: red;">
                              Mobile No. must be of 10 digit
                              </p> -->
                           <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                              Email is invalid
                           </p>
                           <p *ngIf="emailerrors != undefined && emailerrors != '' " class="error_message"
                              style="color: red;">
                              {{emailerrors}}
                           </p>
                        </span>
                    
                  </div>
                  </div>
                  <div class="form-row">
                     <div class="col-md-12 form-group">
                        <label class="form-label">Password <span class="text-danger">*</span></label>
                        <div class="input-group">
                           <div class="input-group-append">
                              <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                 class="feather-lock"></i></span>
                           </div>
                           <input type="password" class="form-control" #password="ngModel" ngModel
                           name="password" id="password" placeholder="Enter Password"
                           [(ngModel)]="userPassword" autocomplete="off" required>
                        </div>
                        <span *ngIf="LoginForm.submitted && password.invalid">
                           <p *ngIf="password.errors.required" class="error_message" style="color: red;">
                              Password is required
                           </p>
                        </span>
                     </div>
                  </div>
               </ng-container>
               </div>
               <div class="modal-footer p-0 border-0">
                  <p class="pt-1 pb-2 text-center m-auto" *ngIf="emp_login != 1">Don’t have an account? <a href="javascript:void(0);"
                     data-dismiss="modal" data-toggle="modal" data-target="#SignupPopup">Register Here</a>
                  </p>
                  <div class="col-12 m-0 p-0">
                     <button type="submit" class="btn btn-primary btn-md btn-block"
                        style="border-radius: 30px;">Continue</button>
                  </div>
                  <!-- <p class="pt-1 pb-2 text-center m-auto">
                     <a href="javascript:void(0);" style="color: #094f8d;" data-dismiss="modal"
                         data-toggle="modal" data-target="#ForgotPasswordModal"
                         (click)="forgotPass()"><strong>Forgot Password?</strong></a>
                     </p> -->
               </div>
               </form>
           </ng-container>
            <form class="pt-2" #LoginOTPForm="ngForm" id="LoginOTPForm" class="mt-4" (ngSubmit)="login()"
            style="display: none;">
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">OTP<span class="text-danger">*</span></label>
                     <div class="form-group">
                        <input type="text" class="form-control" maxlength="10" id="loginOTP" name="loginOTP"
                           numbersOnly #loginOTP="ngModel" ngModel placeholder="Enter OTP"
                           autocomplete="off" required autofocus>
                        <span *ngIf="LoginOTPForm.submitted && loginOTP.invalid">
                           <p *ngIf="loginOTP.errors.required" class="error_message" style="color: red;">
                              OTP is required
                           </p>
                        </span>
                     </div>
                     <div style="background: beige;text-align: center;font-size: large;">
                        Time left = <span id="logintimer"></span>
                     </div>
                  </div>
               </div>
               <div class="form-row" id="resendLoginOTP" style="display: none;">
                  <div class="col-lg-12">
                     <div class="form-group" style="text-align: center;">
                        <label class="form-label" style="color: blue;" (click)="resendLoginOTP()"> Resend
                        OTP
                        </label>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div class="modal-footer p-0 border-0"> -->
            <div class="form-row">
               <div class="col-6 pl-3">
                  <button type="submit" class="btn btn-primary btn-block" id="btnloginOTPSubmit"
                     style="border-radius: 30px;width: 97%;">Submit</button>
               </div>
               <div class="col-6">
                  <button type="button" class="btn btn-danger btn-block" (click)="resetForm()"
                     id="resendotpCancelbtnLogin" style="border-radius: 30px;width: 97%;">Cancel</button>
               </div>
            </div>
            <!-- </div> -->
            </form>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login" id="SignupPopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true" data-backdrop="static" data-keyboard="false" style="overflow-y: auto">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
             
                <button *ngIf="logintype==1" type="button" class="close ss-left" data-dismiss="modal" aria-label="Close"
               (click)="resetForm();">
            
              
            <span aria-hidden="true">
            </span>
            </button>
            <button *ngIf="logintype==0" type="button" class="close ss-left" data-dismiss="modal" aria-label="Close"
            (click)="resetForm1();">
            <span aria-hidden="true">
            </span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">Registration {{formTitle}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="resetForm();">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <!-- <h6 class="pt-4">Enter Phone number to continue</h6> -->
            
           
                <ng-container *ngIf="logintype==1">
                    <form *ngIf="logintype == 1" class="pt-2" #SignUpForm="ngForm" class="mt-4" (ngSubmit)="signUp()" id="SignUpForm">
                    <div class="form-row">
                       <div class="col-md-12 form-group">
                          <label class="form-label">First Name <span class="text-danger">*</span></label>
                          <div class="input-group">
                             <div class="input-group-append">
                                <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                   class="feather-edit"></i></span>
                             </div>
                             <input type="text" class="form-control" #firstname="ngModel" ngModel name="firstname"
                                id="firstname" placeholder="Enter First Name" autocomplete="off" required>
                          </div>
                          <span *ngIf="SignUpForm.submitted && firstname.invalid">
                             <p *ngIf="firstname.errors.required" class="error_message" style="color: red;">
                                First name is required
                             </p>
                          </span>
                       </div>
                    </div>
                    <div class="form-row">
                       <div class="col-md-12 form-group">
                          <label class="form-label">Last Name <span class="text-danger">*</span></label>
                          <div class="input-group">
                             <div class="input-group-append">
                                <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                   class="feather-edit"></i></span>
                             </div>
                             <input type="text" class="form-control" #lastname="ngModel" ngModel name="lastname"
                                id="lastname" placeholder="Enter Last Name" autocomplete="off" required>
                          </div>
                          <span *ngIf="SignUpForm.submitted && lastname.invalid">
                             <p *ngIf="lastname.errors.required" class="error_message" style="color: red;">
                                Last name is required
                             </p>
                          </span>
                       </div>
                    </div>
                    <div class="form-row" *ngIf="logintype==1">
                       <div class="col-md-12 form-group">
                          <label class="form-label">Email </label>
                          <!-- <span class="text-danger">*</span> -->
                          <div class="input-group">
                             <div class="input-group-append">
                                <div id="button-email" type="button" class="btn btn-outline-secondary">
                                   <i class="feather-send"></i>
                                </div>
                             </div>
                             <input type="email" #email="ngModel" ngModel name="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" placeholder="Enter Email"
                                class="form-control" id="email">
                          </div>
                          <!-- <span *ngIf="SignUpForm.submitted">
                             <p *ngIf="email != null && email.invalid" class="error_message" style="color: red;">
                                 Email is required
                             </p>
                              <p *ngIf="email != null && email.errors.pattern" class="error_message" style="color: red;">
                                 Email is invalid
                             </p> 
                             </span>-->
                       </div>
                    </div>
                    <div class="form-row" *ngIf="logintype==0">
                        <div class="col-md-12 form-group">
                           <label class="form-label">Email </label>
                           <span class="text-danger">*</span>
                           <div class="input-group">
                              <div class="input-group-append">
                                 <div id="button-email" type="button" class="btn btn-outline-secondary">
                                    <i class="feather-send"></i>
                                 </div>
                              </div>
                              <input type="email" #email="ngModel" ngModel name="email" placeholder="Enter Email"
                                 class="form-control" id="email" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$">
                           </div>
                           <span *ngIf="SignUpForm.submitted">
                              <p *ngIf="email != null && email.invalid" class="error_message" style="color: red;">
                                  Email is required
                              </p>
                               <p *ngIf="email != null && email.errors.pattern" class="error_message" style="color: red;">
                                  Email is invalid
                              </p> 
                              </span>
                        </div>
                     </div>
                    <div class="form-row">
                       <div class="col-md-4 form-group">
                          <label class="form-label">Country Code <span class="text-danger">*</span></label>
                          <div class="input-group">
                             <div class="input-group-append">
                                <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                   class="feather-globe"></i></span>
                             </div>
                             <select class="form-control" id="countrycode" #countrycode="ngModel" ngModel
                             name="countrycode" [(ngModel)]="data.countrycode" required>
                             <option *ngFor="let countries of countryList" [value]="countries.code">
                             {{ countries.code }}
                             </option>
                             </select>
                          </div>
                          <span *ngIf="SignUpForm.submitted && countrycode.invalid">
                             <p *ngIf="countrycode.errors.required" class="error_message" style="color: red;">
                                Country code is required
                             </p>
                          </span>
                       </div>
                       <div class="col-md-8 form-group">
                          <label class="form-label">Mobile Number <span class="text-danger">*</span></label>
                          <div class="input-group">
                             <div class="input-group-append">
                                <div id="button-mobileno" type="button" class="btn btn-outline-secondary">
                                   <i class="feather-phone-call"></i>
                                </div>
                             </div>
                             <input type="text" #mobilenumber="ngModel" ngModel name="mobilenumber" numbersOnly
                                maxlength="10" placeholder="Enter Mobile Number" class="form-control"
                                id="mobilenumber" required>
                          </div>
                          <span *ngIf="SignUpForm.submitted && mobilenumber.invalid">
                             <p *ngIf="mobilenumber.errors.required" class="error_message" style="color: red;">
                                Mobile number is required
                             </p>
                          </span>
                       </div>
                    </div>
                    <div class="form-row" *ngIf="logintype==0">
                       <div class="col-md-12 form-group">
                           <label class="form-label">Password <span class="text-danger">*</span></label>
                           <div class="input-group">
                               <div class="input-group-append">
                                   <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                           class="feather-lock"></i></span>
                               </div>
                               <input type="password" class="form-control viewpass" #password="ngModel" ngModel
                                   name="password" id="password" placeholder="Enter Password" autocomplete="off"
                                   required>
                           </div>
                           <span *ngIf="SignUpForm.submitted && password.invalid">
                               <p *ngIf="password.errors.required" class="error_message" style="color: red;">
                                   Password is required
                               </p>
                           </span>
                       </div>
                       </div>
                       <div class="form-row" *ngIf="logintype==0">
                       <div class="col-md-12 form-group">
                           <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                           <div class="input-group">
                               <div class="input-group-append">
                                   <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                           class="feather-lock"></i></span>
                               </div>
                               <input type="password" class="form-control viewpass" #confirmpassword="ngModel" ngModel
                                   name="confirmpassword" id="confirmpassword" placeholder="Enter Confirm Password"
                                   autocomplete="off" required>
                           </div><span *ngIf="SignUpForm.submitted && confirmpassword.invalid">
                               <p *ngIf="confirmpassword.errors.required" class="error_message" style="color: red;">
                                   Confirm password is required
                               </p>
                           </span>
                       </div>
                       </div>
                       <div class="form-row" *ngIf="logintype==0">
                       <div class="col-md-12 form-group">
                       
                           <div class="input-group">
                               <label class="form-label" for="chkShowPass">
                                   <input type="checkbox" (click)="showhidepassword('viewpass')" name="chkShowPass"
                                       id="chkShowPass">
                                   Show Password </label>
                           </div>
                       </div>
                       </div>
                    <div class="modal-footer p-0 border-0">
                       <p class="pt-1 pb-2 text-center m-auto">Already have an account? <a href="javascript:void(0);"
                          data-dismiss="modal" data-toggle="modal" data-target="#LoginModal">Log in</a></p>
                       <div class="col-12 m-0 p-0">
                          <button type="submit" class="btn btn-primary btn-md btn-block"
                             style="border-radius: 30px;">Continue</button>
                       </div>
                    </div>
                    </form>
                    </ng-container> 
            <ng-container *ngIf="logintype==0">
            <form *ngIf="logintype == 0" class="pt-2" #SignUpForm="ngForm" class="mt-4" (ngSubmit)="signUp1()" id="SignUpForm">
            <div class="form-row">
               <div class="col-md-12 form-group">
                  <label class="form-label">First Name <span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                           class="feather-edit"></i></span>
                     </div>
                     <input type="text" class="form-control" #firstname="ngModel" ngModel name="firstname"
                        id="firstname" placeholder="Enter First Name" autocomplete="off" required>
                  </div>
                  <span *ngIf="SignUpForm.submitted && firstname.invalid">
                     <p *ngIf="firstname.errors.required" class="error_message" style="color: red;">
                        First name is required
                     </p>
                  </span>
               </div>
            </div>
            <div class="form-row">
               <div class="col-md-12 form-group">
                  <label class="form-label">Last Name <span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                           class="feather-edit"></i></span>
                     </div>
                     <input type="text" class="form-control" #lastname="ngModel" ngModel name="lastname"
                        id="lastname" placeholder="Enter Last Name" autocomplete="off" required>
                  </div>
                  <span *ngIf="SignUpForm.submitted && lastname.invalid">
                     <p *ngIf="lastname.errors.required" class="error_message" style="color: red;">
                        Last name is required
                     </p>
                  </span>
               </div>
            </div>
            <div class="form-row" *ngIf="logintype==1">
               <div class="col-md-12 form-group">
                  <label class="form-label">Email </label>
                  <!-- <span class="text-danger">*</span> -->
                  <div class="input-group">
                     <div class="input-group-append">
                        <div id="button-email" type="button" class="btn btn-outline-secondary">
                           <i class="feather-send"></i>
                        </div>
                     </div>
                     <input type="email" #email="ngModel" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" ngModel name="email" placeholder="Enter Email"
                        class="form-control" id="email" required>
                  </div>
                  <!-- <span *ngIf="SignUpForm.submitted">
                     <p *ngIf="email != null && email.invalid" class="error_message" style="color: red;">
                         Email is required
                     </p>
                      <p *ngIf="email != null && email.errors.pattern" class="error_message" style="color: red;">
                         Email is invalid
                     </p> 
                     </span>-->
               </div>
            </div>
            <div class="form-row" *ngIf="logintype==0">
                <div class="col-md-12 form-group">
                   <label class="form-label">Email </label>
                   <span class="text-danger">*</span>
                   <div class="input-group">
                      <div class="input-group-append">
                         <div id="button-email" type="button" class="btn btn-outline-secondary">
                            <i class="feather-send"></i>
                         </div>
                      </div>
                      <input type="email" #email="ngModel" ngModel name="email" placeholder="Enter Email"
                         class="form-control" id="email"  required>
                   </div>
                   <span *ngIf="SignUpForm.submitted">
                      <p *ngIf=" email.invalid" class="error_message" style="color: red;">
                          Email is required
                      </p>
                       <!-- <pre> 
                                                                                <code>
                                                                                {{ email.errors | json }}
                                                                                </code>
                                                                            </pre> -->
                      <!-- {{email}} {{email.errors.pattern}} -->
                       <!-- <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                          Email is invalid
                      </p>  -->
                      </span>
                </div>
             </div>
            <div class="form-row">
               <div class="col-md-4 form-group">
                  <label class="form-label">Country Code <span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                           class="feather-globe"></i></span>
                     </div>
                     <select class="form-control" id="countrycode" #countrycode="ngModel" ngModel
                     name="countrycode" [(ngModel)]="data.countrycode" required>
                     <option *ngFor="let countries of countryList" [value]="countries.code">
                     {{ countries.code }}
                     </option>
                     </select>
                  </div>
                  <span *ngIf="SignUpForm.submitted && countrycode.invalid">
                     <p *ngIf="countrycode.errors.required" class="error_message" style="color: red;">
                        Country code is required
                     </p>
                  </span>
               </div>
               <div class="col-md-8 form-group">
                  <label class="form-label">Mobile Number <span class="text-danger">*</span></label>
                  <div class="input-group">
                     <div class="input-group-append">
                        <div id="button-mobileno" type="button" class="btn btn-outline-secondary">
                           <i class="feather-phone-call"></i>
                        </div>
                     </div>
                     <input type="text" #mobilenumber="ngModel" ngModel name="mobilenumber" numbersOnly
                        minlength="10" maxlength="10" placeholder="Enter Mobile Number" class="form-control"
                        id="mobilenumber" required>
                  </div>
                  <span *ngIf="SignUpForm.submitted && mobilenumber.invalid">
                     <p *ngIf="mobilenumber.errors.required" class="error_message" style="color: red;">
                        Mobile number is required
                     </p>
                  </span>
               </div>
            </div>
            <div class="form-row" *ngIf="logintype==0">
               <div class="col-md-12 form-group">
                   <label class="form-label">Password <span class="text-danger">*</span></label>
                   <div class="input-group">
                       <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                   class="feather-lock"></i></span>
                       </div>
                       <input type="password" class="form-control viewpass" #password="ngModel" ngModel
                           name="password" id="password" placeholder="Enter Password" autocomplete="off"
                           required>
                   </div>
                   <span *ngIf="SignUpForm.submitted && password.invalid">
                       <p *ngIf="password.errors.required" class="error_message" style="color: red;">
                           Password is required
                       </p>
                   </span>
               </div>
               </div>
               <div class="form-row" *ngIf="logintype==0">
               <div class="col-md-12 form-group">
                   <label class="form-label">Confirm Password <span class="text-danger">*</span></label>
                   <div class="input-group">
                       <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                   class="feather-lock"></i></span>
                       </div>
                       <input type="password" class="form-control viewpass" #confirmpassword="ngModel" ngModel
                           name="confirmpassword" id="confirmpassword" placeholder="Enter Confirm Password"
                           autocomplete="off" required>
                   </div><span *ngIf="SignUpForm.submitted && confirmpassword.invalid">
                       <p *ngIf="confirmpassword.errors.required" class="error_message" style="color: red;">
                           Confirm password is required
                       </p>
                   </span>
               </div>
               </div>
               <div class="form-row" *ngIf="logintype==0">
               <div class="col-md-12 form-group">
               
                   <div class="input-group">
                       <label class="form-label" for="chkShowPass">
                           <input type="checkbox" (click)="showhidepassword('viewpass')" name="chkShowPass"
                               id="chkShowPass">
                           Show Password </label>
                   </div>
               </div>
               </div>
            <div class="modal-footer p-0 border-0">
               <p class="pt-1 pb-2 text-center m-auto">Already have an account? <a href="javascript:void(0);"
                  data-dismiss="modal" data-toggle="modal" data-target="#LoginModal">Log in</a></p>
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-md btn-block"
                     style="border-radius: 30px;">Continue</button>
               </div>
            </div>
            </form>
            </ng-container>
            <form class="pt-2 mt-4" #registrationOTPForm="ngForm" id="registrationOTPForm" style="display: none;">
               <div class="row">
                  <div class="col-lg-12">
                     <div class="form-group">
                        <label for="otp" class="form-label">OTP</label>
                        <input type="text" class="form-control" maxlength="10" id="regotp" name="regotp"
                           numbersOnly #regotp="ngModel" ngModel placeholder="Enter OTP" autocomplete="off"
                           required autofocus>
                        <!-- (keypress)="return isNumberKey(event)"  -->
                     </div>
                     <div class="mb-4" style="background: beige;text-align: center;font-size: large;">
                        Time left = <span id="timer"></span>
                     </div>
                  </div>
               </div>
               <div class="row" id="resendOTP" style="display: none;">
                  <div class="col-lg-12">
                     <div class="form-group" style="text-align: center;">
                        <label class="form-label" style="color: blue;" (click)="resendRegistrationOTP()"> Resend
                        OTP
                        </label>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-6 pl-3">
                     <button type="submit" class="btn btn-primary btn-block" style="width: 97%;"
                        (click)="verifyRegistrationOTP()" id="otpSubmitBtn"> Verify OTP </button>
                  </div>
                  <div class="col-6 ">
                     <button type="button" class="btn btn-danger btn-block" (click)="resetForm()"
                        id="resendotpCancelbtnReg" style="border-radius: 30px;width: 97%;">Cancel</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login popup-mini-ss" id="LogoutModal" tabindex="-1" role="dialog"
   aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
   style="height: 200px; top: 100px; overflow-y: hidden;">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Logout</h5>
         </div>
         <div class="modal-body">
            <h6 class="pt-2">Are you sure you want to logout ?</h6>
            <div class="modal-footer p-0 border-0">
               <div class="col-6 m-0 p-1">
                  <button type="button" class="btn btn-primary btn-block" style="border-radius: 20px;"
                     (click)="logout(1)">Yes</button>
               </div>
               <div class="col-6 m-0 p-0">
                  <button type="button" class="btn btn-primary btn-block" style="border-radius: 20px;"
                     (click)="logout(0)">No</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login" id="MenuModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
   aria-hidden="true" data-backdrop="static" data-keyboard="false"
   style="height: 400px; top: 100px; overflow-y: hidden; display: none;">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Location</h5>
            <button type="button" class="close" (click)="resetMenuForm();" style="display: none;">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form class="pt-2" #MenuForm="ngForm" class="mt-4" (ngSubmit)="setLocation(data)">
            <h6 class="pt-2">Search Near By Restaurant </h6>
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <!-- <label class="form-label">Country Code <span class="text-danger">*</span></label> -->
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-globe"></i></span>
                        </div>
                        <select class="form-control" id="city" #city="ngModel" [(ngModel)]="data.city"
                        ngModel name="city" required (change)="onChangeCity($event)">
                        <option *ngFor="let cities of cityList" [value]="cities">
                        {{ cities }}
                        </option>
                        </select>
                     </div>
                     <span *ngIf="MenuForm.submitted && city.invalid">
                        <p *ngIf="city.errors.required" class="error_message" style="color: red;">
                           City is required
                        </p>
                     </span>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <!-- <label class="form-label">Country Code <span class="text-danger">*</span></label> -->
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-globe"></i></span>
                        </div>
                        <select class="form-control" id="location" #location="ngModel"
                        [(ngModel)]="data.location" ngModel name="location" required>
                        <option *ngFor="let locations of locationList" [value]="locations.location_id">
                        {{ locations.name }}
                        </option>
                        </select>
                     </div>
                     <span *ngIf="MenuForm.submitted && location.invalid">
                        <p *ngIf="location.errors.required" class="error_message" style="color: red;">
                           Location is required
                        </p>
                     </span>
                  </div>
               </div>
            </div>
            <div class="modal-footer p-0 border-0">
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-block">Continue</button>
               </div>
            </div>
            </form>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login" id="ChangePasswordModal" tabindex="-1" role="dialog"
   aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
   style="height: 550px; top: 100px; overflow-y: hidden;">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close ss-left" data-dismiss="modal" aria-label="Close"
               (click)="resetChangePasswordForm();">
            <span aria-hidden="true">
            </span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">Change Password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="resetChangePasswordForm();">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form class="pt-2" #ChangePasswordForm="ngForm" class="mt-4" (ngSubmit)="ChangePassword()">
            <!-- <h6 class="pt-2">Enter mobile number to continue</h6> -->
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Current Password <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #currentpassword="ngModel" ngModel
                           name="currentpassword" id="currentpassword" placeholder="Enter Current Password"
                           autocomplete="off" required>
                     </div>
                     <span *ngIf="ChangePasswordForm.submitted && currentpassword.invalid">
                        <p *ngIf="currentpassword.errors.required" class="error_message"
                           style="color: red;">
                           Current password is required
                        </p>
                     </span>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">New Password <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #newpassword="ngModel" ngModel
                           name="newpassword" id="newpassword" placeholder="Enter New Password"
                           autocomplete="off" required>
                     </div>
                     <span *ngIf="ChangePasswordForm.submitted && newpassword.invalid">
                        <p *ngIf="newpassword.errors.required" class="error_message" style="color: red;">
                           New password is required
                        </p>
                     </span>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Confirm New Password <span
                        class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #confirmnewpassword="ngModel" ngModel
                           name="confirmnewpassword" id="confirmnewpassword"
                           placeholder="Enter Confirm New Password" autocomplete="off" required>
                     </div>
                     <span *ngIf="ChangePasswordForm.submitted && confirmnewpassword.invalid">
                        <p *ngIf="confirmnewpassword.errors.required" class="error_message"
                           style="color: red;">
                           Confirm new password is required
                        </p>
                     </span>
                  </div>
               </div>
            </div>
            <div class="modal-footer p-0 border-0">
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-lg btn-block" style="border-radius: 30px;">Change Password</button>
               </div>
            </div>
            </form>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login" id="ForgotPasswordModal" tabindex="-1" role="dialog"
   aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
   style="height: 400px; top: 100px; overflow-y:hidden; overflow-x:auto; ">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <button type="button" class="close ss-left" data-dismiss="modal" aria-label="Close"
               (click)="resetForgotPasswordForm();">
            <span aria-hidden="true">
            </span>
            </button>
            <h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"
               (click)="resetForgotPasswordForm();">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <form class="pt-2" #ForgotPasswordForm="ngForm" class="mt-4" (ngSubmit)="sendOTP()"
            id="divForgotPassword">
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Email OR Mobile No. <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <div id="button-email" type="button" class="btn btn-outline-secondary">
                              <i class="feather-send"></i>
                           </div>
                        </div>
                        <input type="email" #forgotemail="ngModel" ngModel name="forgotemail"
                           placeholder="Enter Email OR Mobile No." class="form-control" id="forgotemail"
                           autocomplete="off" required>
                     </div>
                     <span *ngIf="ForgotPasswordForm.submitted && forgotemail.invalid">
                        <p *ngIf="forgotemail.errors.required" class="error_message" style="color: red;">
                           Email OR Mobile No. is required
                        </p>
                        <div *ngIf="forgotemail.errors.pattern" class="error_message" style="color: red;">
                           Email OR Mobile No. is invalid
                        </div>
                     </span>
                  </div>
               </div>
            </div>
            <div class="modal-footer p-0 border-0">
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-lg btn-block"
                     style="border-radius: 30px;">Send OTP</button>
               </div>
            </div>
            </form>
            <form class="pt-2" #OTPForm="ngForm" class="mt-4" (ngSubmit)="verifyOTP()" id="divOTP"
            style="display: none;">
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">OTP <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <div id="button-email" type="button" class="btn btn-outline-secondary">
                              <i class="feather-send"></i>
                           </div>
                        </div>
                        <input type="text" #otp="ngModel" ngModel name="otp" placeholder="Enter OTP"
                           class="form-control" id="otp" pattern="[0-9]*" required>
                     </div>
                     <span *ngIf="OTPForm.submitted && otp.invalid">
                        <p *ngIf="otp.errors.required" class="error_message" style="color: red;">
                           OTP is required
                        </p>
                     </span>
                  </div>
               </div>
            </div>
            <div class="modal-footer p-0 border-0">
               <div class="col-12 m-0 p-0" id="divTimer">
                  <h3>
                     <div class="countdown" style="text-align: center;text-decoration: underline;"></div>
                  </h3>
                  <button type="submit" class="btn btn-primary btn-lg btn-block"
                     style="border-radius: 30px;">Verify OTP</button>
               </div>
               <p class="pt-1 pb-2 text-center m-auto" id="divResendPass" style="display: none;">
                  <a href="javascript:void(0);" style="color: #094f8d; font-size: larger;"
                     (click)="resendOTP()"><strong>Resend OTP ?</strong></a>
               </p>
            </div>
            </form>
            <form class="pt-2" #ForgotchangePasswordForm="ngForm" id="ForgotchangePasswordForm" class="mt-4"
            (ngSubmit)="forgotChangePassword()" style="display: none;">
            <!-- <h6 class="pt-2">Enter mobile number to continue</h6> -->
            <div class="pt-2">
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">New Password <span class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #newpasswordChange="ngModel" ngModel
                           name="newpasswordChange" id="newpasswordChange" placeholder="Enter New Password"
                           autocomplete="off" required>
                     </div>
                     <span *ngIf="ForgotchangePasswordForm.submitted && newpasswordChange.invalid">
                        <p *ngIf="newpasswordChange.errors.required" class="error_message"
                           style="color: red;">
                           New password is required
                        </p>
                     </span>
                  </div>
               </div>
               <div class="form-row">
                  <div class="col-md-12 form-group">
                     <label class="form-label">Confirm New Password <span
                        class="text-danger">*</span></label>
                     <div class="input-group">
                        <div class="input-group-append">
                           <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                              class="feather-lock"></i></span>
                        </div>
                        <input type="password" class="form-control" #newpasswordconfirm="ngModel" ngModel
                           name="newpasswordconfirm" id="newpasswordconfirm"
                           placeholder="Enter Confirm New Password" autocomplete="off" required>
                     </div>
                     <span *ngIf="ForgotchangePasswordForm.submitted && newpasswordconfirm.invalid">
                        <p *ngIf="newpasswordconfirm.errors.required" class="error_message"
                           style="color: red;">
                           Confirm new password is required
                        </p>
                     </span>
                  </div>
               </div>
            </div>
            <div class="modal-footer p-0 border-0">
               <div class="col-12 m-0 p-0">
                  <button type="submit" class="btn btn-primary btn-lg btn-block">Change Password</button>
               </div>
            </div>
            </form>
         </div>
      </div>
   </div>
</div>
<div class="modal fade ss-login popup-mini-ss rating-popup" id="SingleProductRating" tabindex="-1" role="dialog"
   aria-labelledby="exampleModalLabel" aria-hidden="true">
   <div class="modal-dialog">
      <div class="modal-content">
         <div class="modal-header">
            <h5 class="modal-title" id="SingleProductRatingPlatterName">{{lblItemName}}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
         </div>
         <div class="modal-body">
            <div class="pt-3" class="reviews-rating" id="reviews-rating">
               <div class="voting-count">
                  <div class="rating ratings-highlight">
                     <div id="review"></div>
                     <p class="label-rating text-muted ml-2 mb-0 small"> ({{lblItemRating}} Reviews)</p>
                  </div>
               </div>
            </div>
            <div class="reviews-list" id="reviews-list" #divReviews>
            </div>
         </div>
      </div>
   </div>
</div>
<style>
   #toggle_pwd {
   cursor: pointer;
   margin-top: 10px;
   margin-left: 10px;
   }
</style>
<ngx-spinner bdcolor="rgb(255 255 255 / 100%)" size="medium" type="square-jelly-box" template="&nbsp;">
   <div class="loader-box">
      <div class="icon">
         <i class="fas fa-utensils"></i>
      </div>
   </div>
   <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>