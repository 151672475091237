<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="osahan-contact">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title"> opus infiniti Party Special</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5 mb-3 BG-WHITE">
                    <div class="flex-column">
                        <div class="row ml-0 mr-0">
                            <h2 class="creative-color">For Small to Large Party Orders</h2>
                        </div>
                        <h6 class="text-ordertype">Throwing party for your friends, family or relatives? Don’t worry
                            about the food, Just order it from  opus infiniti before 4 hours. Special Discounts are also
                            available for orders more than 10 platter boxes of same item. </h6>
                        <h6 class="text-ordertype">We Serve For Different Parties - Kitty Party, Birthday Party,
                            Engagement Party, Anniversary Party, Promotion Party, Corporate Party. Small or Large we
                            serve every orders. And yes we wouldn’t ask the reason of your Party😉</h6>
                        <h1 class="creative-color text-center pt-4">How to Order? </h1>
                        <div class="seprator-line"></div>
                        <div class="row ml-0 mr-0">
                            <div class="topics-by-ss ml-0 mr-0 pt-2 col-lg-4 col-md-4 col-xs-12">
                                <div class="content">
                                    <div class="svg-sec mr-2 text-center">
                                        <i class="fa fa-users"></i>
                                    </div>
                                    <h6 class="text-center">Count Heads</h6>
                                    <p class="text-center">Estimate the total number of people invited. If it exceeds
                                        the number 10 than you are eligible for Party Orders. </p>
                                </div>
                            </div>
                            <div class="topics-by-ss ml-0 mr-0 pt-2 col-lg-4 col-md-4 col-xs-12">
                                <div class="content">
                                    <div class="svg-sec mr-2 text-center">
                                        <i class="fa fa-check-square"></i>
                                    </div>
                                    <h6 class="text-center">Decide & Fill Details </h6>
                                    <p class="text-center">Decide what to Eat from our menu and fill all the details.
                                        Order more to get more discount. </p>
                                </div>
                            </div>
                            <div class="topics-by-ss ml-0 mr-0 pt-2 col-lg-4 col-md-4 col-xs-12">
                                <div class="content">
                                    <div class="svg-sec mr-2 text-center">
                                        <i class="fa fa-utensils"></i>
                                    </div>
                                    <h6 class="text-center">Enjoy the Food </h6>
                                    <p class="text-center">Just place the order, sit back & enjoy the party. Your order
                                        will be delivered on time. </p>
                                </div>
                            </div>
                            <strong class="text-center col-12">Orders must be placed before at least 4 hours.</strong>
                        </div>

                    </div>
                </div>
                <!-- Form -->
                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE">
                    <div class="flex-column">
                        <h6 class="font-weight-bold">10+ orders of the same item will avail a special discount of 20%
                        </h6>
                        <p class="text-muted"></p>
                        <form #PartyOrderForm="ngForm" (ngSubmit)="submitPartyOrder()">
                            <div class="form-group">
                                <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                <input type="text" name="yourname" class="form-control" id="yourname" #yourname="ngModel" ngModel
                                    placeholder="Enter Your Name" autocomplete="off" required>
                            </div>
                            <span *ngIf="PartyOrderForm.submitted && yourname.invalid">
                                <p *ngIf="yourname.errors.required" class="error_message" style="color: red;">
                                    Your name is required
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">Phone Number <span class="text-danger">*</span></label>
                                <input type="text" name="phonenumber" class="form-control" id="phonenumber" #phonenumber="ngModel" ngModel
                                    placeholder="Enter Phone Number" numbersOnly pattern="[0-9]*" maxlength="10" minlength="10" autocomplete="off" required>
                            </div>
                            <span *ngIf="PartyOrderForm.submitted && phonenumber.invalid">
                                <p *ngIf="phonenumber.errors.required" class="error_message" style="color: red;">
                                    Phone number is required
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">Enter Members <span class="text-danger">*</span></label>
                                <input type="text" name="members" class="form-control" id="members" #members="ngModel" ngModel
                                    placeholder="Enter total number of members" numbersOnly pattern="[0-9]*" autocomplete="off" required>
                            </div>
                            <span *ngIf="PartyOrderForm.submitted && members.invalid">
                                <p *ngIf="members.errors.required" class="error_message" style="color: red;">
                                    Member is required
                                </p>
                            </span>
                            <div class="form-group date-time-ss">
                                <label class="form-label">Date <span class="text-danger">*</span></label>
                                <input type="date" name="date_time" class="form-control" id="date_time" #date_time="ngModel" ngModel
                                    placeholder="Select Date" onfocus="(this.type='date')" onblur="(this.type='text')"
                                    autocomplete="off" required>
                            </div>
                            <span *ngIf="PartyOrderForm.submitted && date_time.invalid">
                                <p *ngIf="date_time.errors.required" class="error_message" style="color: red;">
                                    Date is required
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">Requirements</label>
                                <textarea class="form-control" name="requirements" id="requirements" #requirements="ngModel" ngModel
                                    placeholder="Hi there, I would like to ..." rows="3" autocomplete="off"></textarea>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>