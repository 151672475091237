import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(private cookieService: CookieService, private router: Router, private activatedRoute: ActivatedRoute) { }

  public get currentUserValue() {
    try {
      var user = this.cookieService.get("user");
      
      if (user)
        return user;
      return user;
    } catch (e) {
      return null;
    }
  }

  logout() {
    // remove user from cookie to log user out
    var locationId = this.cookieService.get("location");

    this.cookieService.delete('orderId');
    this.cookieService.delete('user');
    this.cookieService.delete('userId');
    this.cookieService.delete('userEmail');
    this.cookieService.delete('userName');
    this.cookieService.delete('firstName');
    this.cookieService.delete('lastName');
    this.cookieService.delete('phoneNo');

    localStorage.removeItem(locationId + "_placeOrder");
    localStorage.removeItem(locationId + "_orderDetail");
    localStorage.removeItem(locationId + "_taxData");
    localStorage.removeItem(locationId + "_discount");

    $('#LogoutModal').modal('hide');
    window.location.href = "index.html";
  }
}
