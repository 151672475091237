import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { ProfileService } from './profile.service';
import { ProfileModel } from './profile.model';
import { AuthenticationService } from '../_services/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  @ViewChild('ProfileForm') profileForm: NgForm;

  constructor(private toastr: ToastrService, private service: ProfileService, private router: Router, private authenticationService: AuthenticationService, 
    private cookieService: CookieService, private spinner: NgxSpinnerService, private checkoutService: CheckoutService) { }

  data: ProfileModel;
  cookieValue: any;
  userId: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  ngOnInit(): void {
    // document.getElementById("loader").style.visibility = "hidden";
    this.cookieValue = this.cookieService.get("userId");
    if (this.cookieValue) {
      this.userId = this.cookieValue;

      this.getProfile();
    }

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    
    this.data = new ProfileModel();
  }

  getProfile() { 
    var obj = {
      "user_id": this.userId,
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.getProfile(obj).subscribe(response => {
      if (response.status == "ok") {
        this.data = response.payload;
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
      this.spinner.hide();
    });
  }

  updateProfile(profile: ProfileModel) {
    // document.getElementById("loader").style.visibility = "visible";
    // document.querySelector("body").style.visibility = "hidden";
    if (this.profileForm.valid) {
      this.spinner.show();
      this.service.updateProfile(profile).subscribe(response => {
        // document.getElementById("loader").style.visibility = "hidden";
        // document.querySelector("body").style.visibility = "visible";
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.getProfile();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
