import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';
import { ShareFeedBackService } from './share-feedback.service';
declare var $: any;

@Component({
  selector: 'app-share-feedback',
  templateUrl: './share-feedback.component.html',
  styleUrls: ['./share-feedback.component.css']
})
export class ShareFeedbackComponent implements OnInit {

  locationData: any;
  feedbackData: Array<any> = [];
  isOrderAvailable: any;
  orderTime: any;
  @ViewChild('divFeedbackData', { static: true }) divFeedbackData: ElementRef;

  constructor(private toastr: ToastrService, private router: Router, private renderer: Renderer2, private cookieService: CookieService, private spinner: NgxSpinnerService, private checkoutService: CheckoutService, private service: ShareFeedBackService) { }

  ngOnInit() {
    if (!this.cookieService.get("user")) {
      document.onreadystatechange = function () {
        if (document.readyState !== "complete") {
        } else {
          $('#LoginModal').modal('show');
        }
      };
      $('#LoginModal').modal('show');
    }

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    this.getFeedbackDetail();
    this.divFeedbackData.nativeElement.innerHTML = null;
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getFeedbackDetail() {
    var obj = {
      "location_id": this.cookieService.get("location")
    };
    this.spinner.show();
    this.service.getFeedbackDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.feedbackData = response.payload;
        if (this.feedbackData.length > 0) {
          this.setFeedbackData();
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
  setFeedbackData() {
    this.spinner.show();
    this.divFeedbackData.nativeElement.innerHTML = null;

    for (var i = 0; i < this.feedbackData.length; i++) {
      const mainDiv: HTMLParagraphElement = this.renderer.createElement('div');
      mainDiv.setAttribute("class", "mb-0 col-md-12 form-group mb-3");

      const divQuestion: HTMLParagraphElement = this.renderer.createElement('label');
      divQuestion.setAttribute("class", "form-label");
      divQuestion.innerHTML = this.feedbackData[i]["title"];
      this.renderer.appendChild(mainDiv, divQuestion);

      if (this.feedbackData[i]["type"] == "Radio") {
        const ansMain: HTMLParagraphElement = this.renderer.createElement('div');
        ansMain.setAttribute("class", "btn-group btn-group-toggle w-100");
        ansMain.setAttribute("data-toggle", "buttons");

        for (var j = 0; j < this.feedbackData[i]["answer"].length; j++) {
          const divOption: HTMLParagraphElement = this.renderer.createElement('label');
          divOption.setAttribute("class", "btn btn-outline-secondary");
          divOption.innerHTML = this.feedbackData[i]["answer"][j]["text"];

          const ansInput: HTMLParagraphElement = this.renderer.createElement('input');
          ansInput.setAttribute("value", this.feedbackData[i]["answer"][j]["text"]);

          ansInput.setAttribute("id", "radio" + this.feedbackData[i]["feedback_id"]);
          ansInput.setAttribute("name", this.feedbackData[i]["feedback_id"]);
          ansInput.setAttribute("type", "radio");

          this.renderer.appendChild(divOption, ansInput);

          this.renderer.appendChild(ansMain, divOption);

        }
        this.renderer.appendChild(mainDiv, ansMain);
      }
      else if (this.feedbackData[i]["type"] == "Textarea") {

        const ansInput: HTMLParagraphElement = this.renderer.createElement('textarea');
        ansInput.setAttribute("name", this.feedbackData[i]["feedback_id"]);
        ansInput.setAttribute("class", "form-control");
        ansInput.setAttribute("id", this.feedbackData[i]["feedback_id"]);
        ansInput.setAttribute("rows", "4");
        this.renderer.appendChild(mainDiv, ansInput);

      }
      else if (this.feedbackData[i]["type"] == "Textbox") {
        const ansInput: HTMLParagraphElement = this.renderer.createElement('input');
        ansInput.setAttribute("name", this.feedbackData[i]["feedback_id"]);
        ansInput.setAttribute("class", "form-control");
        ansInput.setAttribute("id", this.feedbackData[i]["feedback_id"]);
        ansInput.setAttribute("type", "text");
        this.renderer.appendChild(mainDiv, ansInput);
      }
      this.renderer.appendChild(this.divFeedbackData.nativeElement, mainDiv);
    }
    this.spinner.hide();
  }

  saveFeedback() {
    this.spinner.show();
    var obj = {
    };
    var answer;
    for (var i = 0; i < this.feedbackData.length; i++) {
      if (this.feedbackData[i]["type"] == "Radio") {
        answer = $('input[name="' + this.feedbackData[i]["feedback_id"] + '"]:checked').val();
      } else {
        answer = $('#' + this.feedbackData[i]["feedback_id"]).val();
      }
      obj[i] = { "feedback_id": this.feedbackData[i]["feedback_id"], "answer": answer };
    }
    this.service.saveFeedbackDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        this.setFeedbackData();
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
