import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionService } from './manage-subscriptions.service';
import { environment } from 'src/environments/environment';
declare var $: any;
import * as moment from 'moment';

@Component({
  selector: 'app-manage-subscriptions',
  templateUrl: './manage-subscriptions.component.html',
  styleUrls: ['./manage-subscriptions.component.css']
})
export class ManageSubscriptionsComponent implements OnInit {

  @ViewChild('ModifierForm') ModifierForm: NgForm;

  SubscriptionList: any;
  locationData: any;
  isOrderAvailable: any;
  orderTime: any;
  lblItemName: any;
  itemDetails = {};
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemUnits: any;
  itemTypes: any;
  lblItemRate: any;
  itemDetailTypeImage: any;
  lblTotalAmount: any;
  currencySymbol: any;
  weeklyItemDetails: any;
  weekname: any;
  Weekly_item_detail = {};
  itemqty: any;
  cookieValue: any;
  locationId: any;
  selectedIndex: any;
  tomorrow:any;

  constructor(private toastr: ToastrService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private service: SubscriptionService) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;

    this.cookieValue = this.cookieService.get("location");
    if (this.cookieValue)
      this.locationId = this.cookieValue;

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    this.getSubscriptionList();

    this.tomorrow = moment(new Date())
      .add(1, 'days')
      .format('YYYY-MM-DD');

    $('#startDate').val(this.tomorrow);
    $('#startDate').attr('min', this.tomorrow);

  }

  getSubscriptionList() {
    if (!this.cookieService.get("location")) {
      this.toastr.error("Please select location");
      window.location.href = "index.html";
      return;
    }

    var obj = {
      "location_id": this.cookieService.get("location")
    }

    this.spinner.show();
    this.service.gettiffinList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.SubscriptionList = response.payload;
        if (this.SubscriptionList != undefined && this.SubscriptionList.length > 0) {
          var tiffineType = "";
          for (var i = 0; i < this.SubscriptionList.length; i++) {
            tiffineType = "";
            for (var j = 0; j < this.SubscriptionList[i]["item_type_data"].length; j++) {
              if (tiffineType != "")
                tiffineType += ", ";

              tiffineType += this.SubscriptionList[i]["item_type_data"][j].text_tiffin_type;
            }
            this.SubscriptionList[i]["tiffine_type"] = tiffineType
          }
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
      this.toastr.error(err.toString());
    });
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getItemDetails(itemDetail, i) {
    this.cookieValue = this.cookieService.get("user");
    localStorage.setItem("itemDetail", JSON.stringify(itemDetail));
    if (this.cookieValue == undefined || this.cookieValue == "") {
      $("#LoginModal").modal("show");
      return
    }
    
    this.router.navigate(['/managesubscriptionsdetail']).then(() => {
      window.location.reload();
    });
    // this.spinner.show();

    // this.lblItemName = itemDetail.name;
    // // this.itemDetailIsJain = itemDetail.is_jain;
    // $('#ItemAddToCart').modal('show');

    // this.itemDetails = itemDetail;

    // this.itemDetailPrice = this.itemDetails['price'];
    // this.itemDetailName = this.itemDetails['name'];
    // this.itemDetailDescription = this.itemDetails['description'];
    // this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];

    // this.itemUnits = itemDetail.units;
    // // this.itemTypes = itemDetail.types;
    // this.lblItemName = itemDetail.name;
    // this.lblItemRate = itemDetail.price;
    // this.itemDetailTypeImage = this.itemDetailImage;
    // this.lblTotalAmount = this.itemDetailPrice;

    // this.selectedIndex = i;
    // this.spinner.hide();
  }

  getViewDetailsforweeks(itemDetail,i)
  {
     this.spinner.show();

    this.lblItemName = itemDetail.name;
    // this.itemDetailIsJain = itemDetail.is_jain;
    $('#ItemAddToCart1').modal('show');

    this.itemDetails = itemDetail;

    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];

    this.itemUnits = itemDetail.units;
    // this.itemTypes = itemDetail.types;
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;

    this.selectedIndex = i;
    this.spinner.hide();
  }
  viewWeeklyItemDetail(itemDetails) {
    $('#weeklyItemDetail').modal('show');
    $('#ItemAddToCart').modal('hide');
    this.weeklyItemDetails = itemDetails;
    this.weekname = this.weeklyItemDetails["week_name"];
    this.Weekly_item_detail = this.weeklyItemDetails["item_detail"];
  }

  addToCart() {
    this.cookieValue = this.cookieService.get("user");
    if (this.cookieValue == undefined || this.cookieValue == "") {
      $("#LoginModal").modal("show");
      $('#ItemAddToCart').modal('hide');
      return
    }
    var startDate = $("#startDate").val();
    if(startDate == undefined || startDate == ''){
      this.toastr.error("Select subscription start date");
      return;
    }

    if (confirm("Are you sure you want to buy this subcription ?")) {
      $('#ItemAddToCart').modal('hide');

      var objItems = {
        "cart_total": this.SubscriptionList[this.selectedIndex].price,
        "cart_items": 1,
        "sub_total": this.SubscriptionList[this.selectedIndex].price,
        "discount": 0,
        "promocode": "",
        "promocode_id": "",
        "items": this.SubscriptionList[this.selectedIndex],
        "startDate": startDate
      }
      var objCart = {
        "note": $("#addNote").val(),
        "location_id": this.locationId,
        "order_serve_date": "",
        "payment_method": "",
        "cart": objItems
      }

      localStorage.setItem(this.locationId + "_subscriptionDetail", JSON.stringify(objCart));
      this.router.navigate(['/placeordersubscription']);

    }
  }

  weeklyItemDetailClose() {
    $("#ItemAddToCart").modal('show');
    $('#weeklyItemDetail').modal('hide');
  }

  resetAddToCart() {
    this.spinner.show();
    // this.ModifierForm.resetForm();
    // this.ngOnInit();
    $("#ItemAddToCart").modal('hide');
    $("#ItemAddToCart1").modal('hide');
    $('#weeklyItemDetail').modal('hide');
    $('#startDate').val(this.tomorrow);

    this.lblTotalAmount = 0;
    this.spinner.hide();
  }
}
