import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';
import { AuthenticationService } from '../_services/authentication.service';
import { LocationModel } from '../profile/profile.model';
import { SharedService } from '../_services/sharedServices.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.css']
})
export class OrderSuccessComponent implements OnInit {
  @ViewChild('divOrderSummary', { static: true }) divOrderSummary: ElementRef;

  cookieValue: any;
  locationId: any;
  locationData: LocationModel;
  currencySymbol: any;

  placeOrderList: any;
  taxList: Array<any> = [];
  grandTotalAmount: any;
  orderSubTotal: any;
  discount: any;
  orderNo: any;

  constructor(private cookieService: CookieService, private spinner: NgxSpinnerService, private renderer: Renderer2, private toastr: ToastrService, private service: CheckoutService, private router: Router, 
    private authenticationService: AuthenticationService, private sharedService:SharedService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.currencySymbol = environment.currencySymbol;
    this.locationData = new LocationModel();
    this.orderNo = "";
    this.divOrderSummary.nativeElement.innerHTML = null;

    if (this.cookieService.get("location")) {
      this.cookieValue = this.cookieService.get("location");
      this.locationId = this.cookieValue;

      this.getLocationDetail();

      if (localStorage.getItem(this.locationId + "_discount")) {
        this.discount = JSON.parse(localStorage.getItem(this.locationId + "_discount"));        
        // document.getElementById("divCouponRemove").style.display = "block";
      } else {
        // document.getElementById("divCouponRemove").style.display = "none";
      }

      if (localStorage.getItem(this.locationId + "_placeOrder")) {
        this.placeOrderList = JSON.parse(localStorage.getItem(this.locationId + "_placeOrder"));
        this.orderSubTotal = (this.discount ? (parseFloat(this.discount.amount) + parseFloat(this.placeOrderList.cart.sub_total)) : parseFloat(this.placeOrderList.cart.sub_total));
        this.grandTotalAmount = this.orderSubTotal;

        if (this.cookieService.get("orderId")) {
          this.orderNo = "#" + this.cookieService.get("orderId");

          this.setSubTotalUI();
        }
      } else {
        this.router.navigate(['/home']);
      }
    } else {
      this.router.navigate(['/home']);
    }
  }

  getLocationDetail() {
    var obj = {
      "location_id": this.locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;        
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
      this.spinner.hide();
    });
  }

  setSubTotalUI() {
    this.spinner.show();
    this.divOrderSummary.nativeElement.innerHTML = null;

    const h4SubTotal: HTMLParagraphElement = this.renderer.createElement('h4');
    h4SubTotal.innerHTML = "Order Summary ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h4SubTotal);

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Total ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol  + " " + parseFloat(this.orderSubTotal).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setTaxesUI();
  }

  setTaxesUI() {
    this.spinner.show();
    var taxArray = JSON.parse(localStorage.getItem(this.locationId + "_taxData"));
    if (taxArray) {
      for (var idx in taxArray) {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = taxArray[idx].taxDisplayName;
        this.renderer.appendChild(this.divOrderSummary.nativeElement, p);
  
        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + parseFloat(taxArray[idx].taxAmount).toFixed(2);
        this.renderer.appendChild(p, span);
  
        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + (parseFloat(taxArray[idx].taxAmount));
      }
    }

    if (this.discount) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divOrderSummary.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discount.amount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      this.grandTotalAmount = parseFloat(this.grandTotalAmount) - (parseFloat(this.discount.amount));
    }

    this.setGrandTotalUI();
  }

  setGrandTotalUI() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divOrderSummary.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 text-success");
    h6GrandTotal.innerHTML = "TO PAY ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right text-success");
    spanGrandTotal.innerHTML = this.currencySymbol  + " " + parseFloat(this.grandTotalAmount).toFixed(2);
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);

    this.clearLocalStorage();
    this.spinner.hide();
  }

  backToHome() {
    this.spinner.show();
    this.clearLocalStorage();
    //this.router.navigate(['/home']);
    this.router.navigateByUrl('/home');
    this.spinner.hide();
  }

  clearLocalStorage() {
    localStorage.removeItem(this.locationId + "_items");
    localStorage.removeItem(this.locationId + "_placeOrder");
    localStorage.removeItem(this.locationId + "_orderDetail");
    localStorage.removeItem(this.locationId + "_taxData");
    localStorage.removeItem(this.locationId + "_discount");
    
    // localStorage.clear()
    
    this.cookieService.delete('orderId');
    this.sharedService.sendClickEvent();
  }
}
