<div class="bg-primary p-2 shadow-lg bg-white rounded ">
    <div class="text-white">
        <div class="title d-flex align-items-center">
            <div class="col-4">
                <a class="toggle hc-nav-trigger hc-nav-1" role="button" aria-controls="hc-nav-1" href="#">
                    <span></span>
                </a>
            </div>
            <div class="logo-main col-4 pl-0 pr-0"><a href="javascript:void(0);" routerLink="/home"><img style="width:200px !important" class="logo main-logo"
                        src="assets/img/logo/logo1.png"></a></div>
            <div class="font-weight-bold ss-contact-head pr-0 col-4">
                <div class="text-white font-weight-bold">
                    <a class="text-white" href="tel:"><img src="assets/img/phone-call.svg"
                            class="text-white"></a>
                    <a class="text-white" href=""><img
                            src="assets/img/facebook.svg" class="text-white"></a>
                    <a class="text-white" href=""><img
                            src="assets/img/instagram.svg" class="text-white"></a>
                </div>
            </div>
        </div>
    </div>
</div>
