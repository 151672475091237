import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';
declare var $: any;

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  constructor(private toastr: ToastrService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private service: CheckoutService) { }

  offerList: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;
  offersTotal: any;

  ngOnInit() {
    this.getOfferList();

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
  }

  getOfferList() {
    if (!this.cookieService.get("location")) {
      this.toastr.error("Please select location");
      window.location.href = "index.html";
      return;
    }

    var obj = {
      "location_id": this.cookieService.get("location")
    }

    this.spinner.show();
    this.service.getPromoCodeList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.offerList = response.payload;
        this.offersTotal = this.offerList.length;
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  copyOfferCode(inputElement,pId) {
    document.getElementById(pId).removeAttribute("disabled");
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    document.getElementById(pId).setAttribute("disabled", "true");
    this.toastr.info("Code Copied!");
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
