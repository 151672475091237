import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy,PathLocationStrategy  } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './_helpers/JwtInterceptor';
import { ErrorInterceptor } from './_helpers/ErrorInterceptor';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { MenuFooterComponent } from './menu-footer/menu-footer.component';
import { MenuSidebarComponent } from './menu-sidebar/menu-sidebar.component';
import { MenuCategoryComponent } from './menu-category/menu-category.component';
import { CookieService } from 'ngx-cookie-service';
import { ProfileComponent } from './profile/profile.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { ManageOrdersComponent } from './manage-orders/manage-orders.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ManageAddressComponent } from './manage-address/manage-address.component';
import { NumberDirective } from './_helpers/numbersonly.directive';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PartyOrdersComponent } from './party-orders/party-orders.component';
import { ShareFeedbackComponent } from './share-feedback/share-feedback.component';
import { ReadFeedbackComponent } from './read-feedback/read-feedback.component';
import { OffersComponent } from './offers/offers.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ManageSubscriptionsComponent } from './manage-subscriptions/manage-subscriptions.component';
import { PlaceordersubscriptionComponent } from './placeordersubscription/placeordersubscription.component';
import { UsersubscriptionsComponent } from './usersubscriptions/usersubscriptions.component';
import { UsersubscriptionordersComponent } from './usersubscriptionorders/usersubscriptionorders.component';
import { PlaceextraordersubscriptionComponent } from './placeextraordersubscription/placeextraordersubscription.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ManageSubscriptionDetailComponent } from './manage-subscription-detail/manage-subscription-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    MenuFooterComponent,
    MenuSidebarComponent,
    MenuCategoryComponent,
    ProfileComponent,
    CheckoutComponent,
    PlaceOrderComponent,
    OrderSuccessComponent,
    ManageOrdersComponent,
    TermConditionComponent,
    PrivacyPolicyComponent,
    AboutUsComponent,
    ManageAddressComponent,
    NumberDirective,
    ContactUsComponent,
    PartyOrdersComponent,
    ShareFeedbackComponent,
    ReadFeedbackComponent,
    OffersComponent,
    ManageSubscriptionsComponent,
    PlaceordersubscriptionComponent,
    UsersubscriptionsComponent,
    UsersubscriptionordersComponent,
    PlaceextraordersubscriptionComponent,
    ManageSubscriptionDetailComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxPaginationModule,
    FormsModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxSpinnerModule,
    GoogleMapsModule,
  ],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
