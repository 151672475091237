import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var currentUser = this.authenticationService.currentUserValue;

        // //Local
        // "app-id": "aaaaaaaaaa",
        // "app-secret": "aaaaaaaaaa",

        // Staging
        // "app-id": "005c82b9372d5eccee26d57c0ef7de2ebd",
        // "app-secret": "00f59fe7698c51870a10da37587e6f6c16",

        // // Raivat Kitchen
        // "app-id": "008ef93387e47fec6585240c66b82b07f0",
        // "app-secret": "00e7957a3bcd4fc847ccc3f9d0d679e265",

        //VegieBite
        //app-id : 00f8c8416ddbe515465363f541ca3943d4
        //app-secret : 00bc8e94537c98495309219eb535a86e4b

        //ggl
        //app-id : 0023f63615ce7ae30a9c26a7f7cb2ae4a3
        //app-secret : 0051a99667cd352f00ae604189312b4436

        if (currentUser) {
            request = request.clone({
                setHeaders: {
                    "auth-token": `${currentUser}`,
                    "app-id": "00a1b7f6385d8cb311ccb84ba8cdd8a637",
                    "app-secret": "008a38a282de354d17c2811665005f2aa8",
                }
            });
        } else {
            request = request.clone({
                setHeaders: {
                    "app-id": "00a1b7f6385d8cb311ccb84ba8cdd8a637",
                    "app-secret": "008a38a282de354d17c2811665005f2aa8",
                }
            });
        }
        
        return next.handle(request);
    }
}