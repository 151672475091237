<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-manage-orders">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Manage Orders</h2>
            </div>
            <div class="cart-resize">
                <div class="p-3 osahan-cart-item">
                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
                        <div class="d-flex flex-column pt-2">
                            <div class="row ml-0 mr-0">
                                <div class="col-lg-12 col-md-12 col-xs-12 m-0" style="text-align: center;"
                                    id="divNoOrders">No Orders Available</div>

                                <div class="col-lg-4 col-md-4 col-xs-12 m-0" *ngFor="let orders of orderList">
                                    <div class="card card-body" style="margin: 15px 0;" *ngIf="orders !=  null"
                                        [ngStyle]="{ 'background-color': orders.order_status == 0 ? '#fff3e2' : (orders.order_status == 1 ? '#eeffee' : 
                                                (orders.order_status == 2 ? '#e8faff' : (orders.order_status == 3 ? '#ffeae9' : (orders.order_status == 5 ? '#ffffe6' : 
                                                (orders.order_status == 6 ? '#eeffee' : (orders.order_status == 7 ? '#e8faff' : 'none')))))) }">
                                        <br>
                                        <!-- <h6 class="card-title"><b>Order No :</b><br> #{{orders.order_id}}_{{orders.external_id}}</h6> -->
                                        <h6 class="card-title"><b>Order No :</b><br> #{{orders.order_id}}</h6>
                                        <h6 class="card-title"><b>Order Date :</b> {{orders.order_datetime}}</h6>
                                        <h6 class="card-title"><b>Pickup Date :</b> {{orders.order_serve_date}}</h6>
                                        <hr>
                                        <p class="card-text"><b>Pay Mode: </b>
                                            <span *ngIf="orders.payment_note != ''"
                                                style="background: #51A351; border: 1px dashed white; padding: 5px; font-family: lato, ubuntu, helvetica, sans-serif; color: white;">
                                                {{orders.payment_note}}
                                            </span>
                                        </p>
                                        <p class="card-text"><b>No. of Items:</b> {{orders.order_items}}</p>
                                        <!-- <hr> -->
                                        <h6 class="card-subtitle mb-2 text-muted"><b>Total:</b> {{currencySymbol}}
                                            {{orders.orderAmt}}</h6>
                                        <p class="card-text" *ngIf="orders.order_status == 0"
                                            style="color: #ff9500; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 1"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 2"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 3"
                                            style="color: #bd362f; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 5"
                                            style="color: #A6A303; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 6"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>
                                        <p class="card-text" *ngIf="orders.order_status == 7"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.order_status_display}}</p>

                                        <a href="javascript:void(0);"
                                            class="card-link btn btn-primary btn-block button_slide slide_right"
                                            (click)="getOrderDetail(orders.external_id); ">View
                                            Detail</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white rounded shadow mt-3 profile-details row ml-0 mr-0 p-3 mb-5">
                        <!-- <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6"
                            routerLink="/manageaddress">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-truck bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Addresses </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a> -->
                        <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6"
                            routerLink="/profile">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-map-pin bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Profile </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="OrderDetail" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Order Detail</b>
                </h5>
                <button type="button" class="close" (click)="closeOrderDetailModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #OrderDetailForm="ngForm" (ngSubmit)="submitReview()">
                    <div class="row ml-0 mr-0">
                        <div class="card col-lg-12 col-md-12 col-xs-12">
                            <div class="card-body">
                                <div class="form-row">
                                    <div class="col-md-12 col-12 col-sm-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span><b>Order Id: </b> <span style="float: right;">#{{invoiceId}}</span>
                                            </span><br>
                                            <span><b>Order Date: </b><span style="float: right;">{{orderDate}}</span>
                                            </span><br>
                                            <span><b>Pickup Date: </b></span><span
                                                style="float: right;">{{pickupDate}}</span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-8 col-12 col-sm-6">
                                        <div style="text-align: right; display: block;">
                                            #{{invoiceId}}
                                            <p>{{orderDate}}</p>
                                            <p>{{pickupDate}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <hr>
                                <div class="form-row" #divItems>
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span><b>Item Total </b>
                                                <div style="float: right;">
                                                    <p>{{currencySymbol}} {{itemTotal}}</p>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{itemTotal}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngFor="let taxes of orderItemTaxes">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <div *ngIf="taxes.is_gst == 0">
                                                <span>{{taxes.name}}
                                                    <div style="float: right;">
                                                        {{currencySymbol}} {{taxes.tax_amount}}
                                                    </div>
                                                </span>
                                            </div>
                                            <div *ngIf="taxes.is_gst == 1">
                                                <span>SGST</span>
                                                <div style="float: right;">
                                                    {{currencySymbol}} {{taxes.tax_amount / 2}}
                                                </div>
                                                <br>
                                                <span>CGST</span>
                                                <div style="float: right;">
                                                    {{currencySymbol}} {{taxes.tax_amount / 2}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <div *ngIf="taxes.is_gst == 0">
                                                <p>{{currencySymbol}} {{taxes.tax_amount}}</p>
                                            </div>
                                            <div *ngIf="taxes.is_gst == 1">
                                                <p>{{currencySymbol}} {{taxes.tax_amount / 2}}</p>
                                                <p>{{currencySymbol}} {{taxes.tax_amount / 2}}</p>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngIf="discountAmount != 0">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Discount</span>
                                            <div style="float: right; ">
                                                <p>{{currencySymbol}} {{discountAmount}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{discountAmount}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngIf="packingAmount != 0">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Packing Charge</span>
                                            <div style="float: right; ">
                                                <p>{{currencySymbol}} {{packingAmount}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{packingAmount}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngIf="deliveryAmount != 0">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Delivery Charge</span>
                                            <div style="float: right; ">
                                                <p>{{currencySymbol}} {{deliveryAmount}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{deliveryAmount}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngIf="serviceCharge != 0">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Service Charge</span>
                                            <div style="float: right; ">
                                                <p>{{currencySymbol}} {{serviceCharge}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{deliveryAmount}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-row" *ngIf="internetCharge != 0">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span>Internet Handling Charge</span>
                                            <div style="float: right; ">
                                                <p>{{currencySymbol}} {{internetCharge}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <p>{{currencySymbol}} {{deliveryAmount}}</p>
                                        </div>
                                    </div> -->
                                </div>
                                <hr>
                                <div class="form-row">
                                    <div class="col-md-12 col-sm-12 col-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <h5>
                                                <b>Total</b>
                                                <div style="float: right;">
                                                    <h6>{{currencySymbol}} {{orderTotal}}</h6>
                                                </div>
                                            </h5>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-6">
                                        <div style="text-align: right; display: block;">
                                            <h6>{{currencySymbol}} {{orderTotal}}</h6>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 mb-2" *ngIf="paymentMode != ''">
                                        <span
                                            style="background: #51A351; border: 1px dashed white; padding: 5px; font-family: lato, ubuntu, helvetica, sans-serif; color: white;">
                                            {{paymentMode}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row ml-0 mr-0 mt-1" id="orderdiv" style="display: none;">
                        <div class="card col-lg-12 col-md-12 col-xs-12">
                            <h6 class="pt-2" *ngIf="isReviewAvailable == 0">Add Your Review</h6>
                            <div class="card-body">
                                <div class="form-row" *ngIf="isReviewAvailable == 1">
                                    <div class="col-md-6">
                                        <div style="height: 100%; line-height: 30px;">
                                            <span><b>{{reviewUser}}</b></span><br>
                                            <p style="margin: auto !important;">{{reviewDate}}</p>
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div style="height: 100%; line-height: 30px; text-align: right;">
                                            <div id="review1"></div>
                                            <!-- <input type="hidden" id="getReviewCount" name="getReviewCount" value="0"> -->
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div style="height: 100%; line-height: 30px;">
                                            <!-- <span><b>{{reviewTitle}}</b></span><br> -->
                                            <p style="margin: auto !important;">{{reviewDescription}}</p>
                                           
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row" *ngIf="isReviewAvailable == 0">
                                    <div class="col-md-12 form-group" style="text-align: center;">
                                        <label class="form-label">Did you enjoy your food?</label>
                                        <div>
                                            <div id="review2">
                                            </div>
                                            <input type="hidden" id="setReviewCount" name="setReviewCount" value="0">
                                        </div>
                                    </div>
                                    <div class="col-md-12 form-group" style="display: none;">
                                        <div class="input-group">
                                            <input type="text" class="form-control" #title="ngModel" ngModel
                                                name="title" id="title" placeholder="Enter Title" autocomplete="off"
                                                >
                                        </div>
                                        <span *ngIf="OrderDetailForm.submitted && title.invalid">
                                            <p *ngIf="title.errors.required" class="error_message" style="color: red;">
                                                Title is required
                                            </p>
                                        </span>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <textarea #description="ngModel" ngModel name="description" class="form-control"
                                            rows="3" placeholder="Enter Description" id="description" autocomplete="off"
                                            required></textarea>
                                        <span *ngIf="OrderDetailForm.submitted && description.invalid">
                                            <p *ngIf="description.errors.required" class="error_message"
                                                style="color: red;">
                                                Description is required
                                            </p>
                                        </span>
                                    </div>
                                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <form  #OrderReviewForItemsForm="ngForm" (ngSubmit)="submitReviewforItems()">
                    <div class="row ml-0 mr-0 mt-1" id="itemdiv" style="display: none;">
                        <div class="card col-lg-12 col-md-12 col-xs-12">
                            <h6 class="pt-2" *ngIf="isReviewAvailableforitems == 0">Add Your Review For Items</h6>
                            <h6 class="pt-2" *ngIf="isReviewAvailableforitems == 1">Items Review</h6>
                            <div class="card-body">
                                <div class="form-row" *ngIf="isReviewAvailableforitems == 1">
                                   
                                        <ng-container  *ngFor="let reviewItemData of reviewItemData">
                                            <div class="col-md-6">
                                                <div style="height: 100%; line-height: 30px;">
                                                    <span><b>{{reviewItemData.itemname}}</b></span><br>
                                                    <p style="margin: auto !important;">{{reviewItemData.reviewdate}}</p>
                                                   
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div style="height: 100%; line-height: 30px; text-align: right;">
                                                    <div id="review_{{reviewItemData.itemid}}"></div>
                                                    <!-- <input type="hidden" id="getReviewCount" name="getReviewCount" value="0"> -->
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div style="height: 100%; line-height: 30px;">
                                                    <!-- <span><b>{{reviewTitle}}</b></span><br> -->
                                                    <p style="margin: auto !important;">{{reviewItemData.reviewdescription}}</p>
                                                   
                                                </div>
                                            </div>
                                            
                                        </ng-container>
                                    
                                </div>

                                <div class="form-row" *ngIf="isReviewAvailableforitems == 0">
                                    <ng-container  *ngFor="let orderItemList of orderItemList;let i = index">
                                    <div class="col-md-8 form-group" >
                                        {{orderItemList.product_name}}
                                    </div>
                                    <div class="col-md-4 form-group"  style="text-align: right;">
                                        <!-- <label class="form-label">Did you enjoy your food?</label> -->
                                        <div>
                                            <div class="reviewitems2" id="Review_{{orderItemList.z_itemid_fk}}">
                                            </div>
                                            <input type="hidden" id="setReviewCount_Review_{{orderItemList.z_itemid_fk}}" name="setReviewCount_{{orderItemList.z_itemid_fk}}" value="0">
                                        </div>
                                    </div>
                                    <div class="col-md-12 form-group" style="display: none;">
                                        <div class="input-group">
                                            <input type="text" class="form-control" #title="ngModel" ngModel
                                                name="title_{{orderItemList.z_itemid_fk}}" id="title_{{orderItemList.z_itemid_fk}}" placeholder="Enter Title" autocomplete="off"
                                                >
                                        </div>
                                        <span *ngIf="OrderReviewForItemsForm.submitted && title.invalid">
                                            <p *ngIf="title.errors.required" class="error_message" style="color: red;">
                                                Title is required
                                            </p>
                                        </span>
                                    </div>
                                    <div class="col-md-12 form-group">
                                        <textarea #description="ngModel" ngModel name="description_{{orderItemList.z_itemid_fk}}" class="form-control"
                                            rows="3" placeholder="Enter Description" id="description_{{orderItemList.z_itemid_fk}}" autocomplete="off"
                                            required></textarea>
                                        <span *ngIf="OrderReviewForItemsForm.submitted && description.invalid">
                                            <p *ngIf="description.errors.required" class="error_message"
                                                style="color: red;">
                                                Description is required
                                            </p>
                                        </span>
                                    </div>
                                </ng-container>
                                    <button type="submit" class="btn btn-primary btn-block">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>