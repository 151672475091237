import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from '../checkout/checkout.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  @ViewChild('ContactUsForm') contactUsForm: NgForm;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;
  locationAddress: any;
  locationPhoneNumber: any;
  locationEmail: any;

  constructor(private spinner: NgxSpinnerService, private toastr: ToastrService, private router: Router, private checkoutService: CheckoutService, private cookieService: CookieService) { }

  ngOnInit(): void {
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
  }

  submitContactUs() {
    if (this.contactUsForm.valid) { 
      this.spinner.show();
      var obj = {
        "location_id": this.cookieService.get("location"),
        "name": this.contactUsForm.controls.yourname.value,
        "email": this.contactUsForm.controls.email.value,
        "phone": this.contactUsForm.controls.phonenumber.value,
        "message": this.contactUsForm.controls.enquiry.value,
      };

      this.checkoutService.saveContactUs(obj).subscribe(response => {
        if (response.status == "ok") {
          this.contactUsForm.resetForm();
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];

        this.locationAddress = this.locationData['address1'] + ", " + this.locationData['address2'];
        this.locationPhoneNumber = this.locationData['phoneno'];
        this.locationEmail = this.locationData['locationemail'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
