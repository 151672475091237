import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
// import { CheckoutService } from '../checkout/checkout.service';
import { ProfileModel, LocationModel } from '../profile/profile.model';
import { ProfileService } from '../profile/profile.service';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import { ManageAddressService } from '../manage-address/manage-address.service';
import { PlaceOrderSubscriptionService } from './placeordersubscription.service';
import { SharedService } from '../_services/sharedServices.service';

declare var Razorpay: any;
declare var $: any;

@Component({
  selector: 'app-placeordersubscription',
  templateUrl: './placeordersubscription.component.html',
  styleUrls: ['./placeordersubscription.component.css']
})
export class PlaceordersubscriptionComponent implements OnInit {
  @ViewChild('divSubTotal', { static: true }) divSubTotal: ElementRef;
  @ViewChild('divOrderSummary', { static: true }) divOrderSummary: ElementRef;

  data: ProfileModel;
  locationData: LocationModel;
  cookieValue: any;
  locationId: any;
  cartTotalAmount: any;
  userId: any;
  currencySymbol: any;

  placeOrderList: any;
  placeOrderItemList: any;
  taxList: Array<any> = [];
  grandTotalAmount: any;
  orderSubTotal: any;
  discount: any;
  orderType: any = "H";
  addressList: Array<any> = [];
  ConfirmAddress: Array<any> = [];
  paymentgateway_value: Array<any> = [];
  DeliveryCharge: Array<any> = [];

  isOrderAvailable: any;
  orderTime: any;
  payuform: any = {};

  orderNo: any;
  roundoffamt: any = 0.00;
  SubscriptionData: any;
  lblItemName: any;
  itemDetails: any;
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemUnits: any;
  lblItemRate: any;
  itemDetailTypeImage: any;
  lblTotalAmount: any;
  startdate: string;

  constructor(private cookieService: CookieService, private renderer: Renderer2, private toastr: ToastrService, private router: Router, private profileService: ProfileService,
    private authenticationService: AuthenticationService, private spinner: NgxSpinnerService, private addressService: ManageAddressService, private sharedService: SharedService, private service: PlaceOrderSubscriptionService, private http: HttpClient) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;
    this.data = new ProfileModel();
    this.locationData = new LocationModel();
    this.orderSubTotal = 0;
    this.orderNo = "";
    this.divOrderSummary.nativeElement.innerHTML = null;
    this.SubscriptionData=JSON.parse(localStorage.getItem('itemDetail'));
    var itemDetail=this.SubscriptionData
    this.lblItemName = itemDetail.name;
    this.itemDetails = itemDetail;
    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];
    this.itemUnits = itemDetail.units;
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;
    this.startdate =localStorage.getItem('startDate');
    $("#divOrderSuccess").hide();

    this.cookieValue = this.cookieService.get("userId");
    if (this.cookieValue) {
      this.userId = this.cookieValue;

      this.getProfile();
      this.getUserAddress();
    }

    if (this.cookieService.get("location")) {
      this.cookieValue = this.cookieService.get("location");
      this.locationId = this.cookieValue;

      this.getLocationDetail();
      // if (localStorage.getItem(this.locationId + "_discount")) {
      //   this.discount = JSON.parse(localStorage.getItem(this.locationId + "_discount"));
      //   // document.getElementById("divCouponRemove").style.display = "block";
      // } else {
      //   // document.getElementById("divCouponRemove").style.display = "none";
      // }
      if (localStorage.getItem(this.locationId + "_subscriptionDetail")) {
        this.placeOrderList = JSON.parse(localStorage.getItem(this.locationId + "_subscriptionDetail"));
        this.orderSubTotal = parseFloat(this.placeOrderList.cart.sub_total);
        this.cartTotalAmount = this.orderSubTotal;
        this.grandTotalAmount = parseFloat(this.orderSubTotal).toFixed(2);
        if (parseInt(this.cartTotalAmount) <= 0) {
          this.router.navigate(['/managesubscriptions']);
          return;
        }
        this.placeOrderItemList = this.placeOrderList.cart.items;
        this.setSubTotalUI();
      } else {
        this.router.navigate(['/managesubscriptions']);
        return;
      }
      //payment gateway checking
      for (var i = 0; i < environment.pgateway_types.length; i++) {
        if (environment.pgateway_types[i]["paymenttype"].toLowerCase() == "payumoney" || environment.pgateway_types[i]["paymenttype"].toLowerCase() == "razorpay") {
          this.paymentgateway_value[0] = environment.pgateway_types[i]["paymentgateway_value"];
          this.paymentgateway_value[0]["paymenttypeunkid"] = environment.pgateway_types[i]["paymenttypeunkid"];
          this.paymentgateway_value[0]["paymenttype"] = environment.pgateway_types[i]["paymenttype"];
        }
        
      }
      //console.log.log('this.paymentgateway_value',this.paymentgateway_value);
    } else {
      this.router.navigate(['/managesubscriptions']);
    }
    this.spinner.hide();
    this.loadExternalScript('https://checkout.razorpay.com/v1/checkout.js');
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  setSubTotalUI() {
    this.spinner.show();
    this.divSubTotal.nativeElement.innerHTML = null;

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Sub Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.cartTotalAmount).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setGrandTotalUI();
    // this.setTaxesUI();
    this.spinner.hide();
  }

  setTaxesUI() {
    this.spinner.show();
    this.grandTotalAmount = parseFloat(this.orderSubTotal).toFixed(2);
    var taxArray = JSON.parse(localStorage.getItem(this.locationId + "_taxData"));
    if (taxArray) {
      for (var idx in taxArray) {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = taxArray[idx].taxDisplayName;
        this.renderer.appendChild(this.divSubTotal.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + parseFloat(taxArray[idx].taxAmount).toFixed(2);
        this.renderer.appendChild(p, span);

        this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(taxArray[idx].taxAmount))).toFixed(2);
      }

      //----------------------------------------------   Packing Charge START
      // this.cookieService.set("packingcharge","0")
      if (parseFloat(this.cookieService.get("packingcharge")) > 0) {
        if (this.cookieService.get("packingchargetype") == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.cookieService.get("packingcharge")))).toFixed(2);
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.cookieService.get("packinglabel");
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
            * count).toFixed(2);
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (
            (parseFloat(this.cookieService.get("packingcharge"))) * count
          )).toFixed(2);
        }
      }
      //---------------------------------------------- Packing Charge  END

      // ---------------------------------------------- Delivery Charge START
      if (this.DeliveryCharge.length > 0) {
        if (this.DeliveryCharge[0]["deliverychargetype"] == "1") {
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + parseFloat(this.DeliveryCharge[0]["deliverycharge"]).toFixed(2);
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]))).toFixed(2);
        } else {
          var qty = [];
          var count = 0;
          for (var q = 0; q < this.placeOrderItemList.length; q++) {
            if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
              qty.push(this.placeOrderItemList[q]["item_id"]);
              count++;
            }
          }
          const p: HTMLParagraphElement = this.renderer.createElement('p');
          p.setAttribute("class", "mb-1");
          p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
          this.renderer.appendChild(this.divSubTotal.nativeElement, p);

          const span: HTMLParagraphElement = this.renderer.createElement('span');
          span.setAttribute("class", "float-right text-dark");
          span.innerHTML = this.currencySymbol + " " + (parseFloat(this.DeliveryCharge[0]["deliverycharge"])
            * count).toFixed(2);
          this.renderer.appendChild(p, span);

          this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
        }
        // this.grandTotalAmount = this.grandTotalAmount - this.roundoffamt;
      }
      // ---------------------------------------------- Delivery Charge END
    }

    if (this.discount) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discount.amount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      this.grandTotalAmount = parseFloat(this.grandTotalAmount) - (parseFloat(this.discount.amount));
    }

    if (environment.roundoff != undefined && environment.roundoff != null) {
      const pRoundOff: HTMLParagraphElement = this.renderer.createElement('p');
      pRoundOff.setAttribute("class", "mb-1");
      pRoundOff.innerHTML = "Rounding off ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pRoundOff);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");

      if (environment.roundoff == "0.1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount).toFixed(1)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);
        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(1);
      }
      else if (environment.roundoff == "1") {
        var am = parseFloat(parseFloat(this.grandTotalAmount).toFixed(0)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else if (environment.roundoff == "10") {
        var am = Math.round(this.grandTotalAmount / 10) * 10 - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);
        this.roundoffamt = am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else {
        var amt = parseFloat(parseFloat(this.grandTotalAmount).toFixed(2)) - parseFloat(this.grandTotalAmount)
        spanDiscount.innerHTML = this.currencySymbol + " 0.00";
        this.roundoffamt = "0.00";

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + amt;
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(2);
      }
      this.renderer.appendChild(pRoundOff, spanDiscount);
    }
    this.setGrandTotalUI();
    this.spinner.hide();
  }

  setGrandTotalUI() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divSubTotal.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 pb-2");
    h6GrandTotal.innerHTML = "Grand Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right");
    spanGrandTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.grandTotalAmount);
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);
    this.spinner.hide();
  }

  getProfile() {
    var obj = {
      "user_id": this.userId,
      "location_id": this.locationId
    };

    this.spinner.show();
    this.profileService.getProfile(obj).subscribe(response => {
      if (response.status == "ok") {
        this.data = response.payload;
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
        this.toastr.error(err.toString());
        this.spinner.hide();
    });
  }

  addAddress() {
    let encryptedPlaceOrder = btoa('placeordersubscription');
    this.router.navigate(['/manageaddress/' + encryptedPlaceOrder]);
  }

  getUserAddress() {
    var obj = {
      "location_id": this.locationId
    }
    this.spinner.show();
    this.addressService.getUserAddress(obj).subscribe(response => {
      if (response.status == "ok") {
        if (response.payload != "") {
          this.addressList = response.payload;
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  setaddress(address) {
    this.ConfirmAddress[0] = address;
    // var obj = {
    //   "location_id": this.locationId,
    //   "ordertype": 3,
    //   "useraddress_id": this.ConfirmAddress[0].useraddress_id
    // };
    // this.spinner.show();
    // this.service.getDeliveryCharge(obj).subscribe(response => {
    //   this.spinner.hide();
    //   if (response.status == "ok") {
    //     if (response.payload.deliverycharge == undefined) {
    //       return
    //     }
    //     this.DeliveryCharge[0] = response.payload;
    //     this.setSubTotalUI();
    //   } else {
    //     this.toastr.error(response.message);
    //   }
    // }, (err: HttpErrorResponse) => {
    //   this.authenticationService.logout();
    //   this.spinner.hide();
    // });

  }

  RemoveAddress() {
    this.ConfirmAddress = [];
    // if (this.DeliveryCharge.length > 0) {
    //   this.DeliveryCharge = [];
    //   this.setSubTotalUI();
    // }
  }

  getLocationDetail() {
    var obj = {
      "location_id": this.locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        //console.log.log(this.locationData);
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.authenticationService.logout();
      this.spinner.hide();
    });
  }

  placeOrder(payment_method) {
    //Note is remaining
    $("#btnPlaceOrder").attr("disabled", true);
    $("#btnPlaceOrderOnline").attr("disabled", true);

    if (this.orderType == "H" && this.ConfirmAddress.length == 0) {
      this.toastr.error("Select delivery address");
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
      return
    }

    if (localStorage.getItem(this.locationId + "_subscriptionDetail")) {
      this.spinner.show();
      // orderDetail = JSON.parse(localStorage.getItem(this.locationId + "_subscriptionDetail"));

      // if (this.orderType == "H") {
      //   orderDetail["ordertype"] = 3
      //   orderDetail["useraddress_id"] = this.ConfirmAddress[0]["useraddress_id"]
      // } else if (this.orderType == "P") {
      //   orderDetail["ordertype"] = 2
      // }
      var paymenttypeunkid = 0;
      for (var i = 0; i < environment.pgateway_types.length; i++) {

        if (payment_method == "ONLINE" && environment.pgateway_types[i].paymenttype == "Razorpay") {
          paymenttypeunkid = environment.pgateway_types[i].paymenttypeunkid;
          break;
        } else if (environment.pgateway_types[i].paymenttype.toLowerCase() == payment_method.toLowerCase()) {
          paymenttypeunkid = environment.pgateway_types[i].paymenttypeunkid;
          break;
        }
      }
      var orderDetail = {
        "location_id": this.locationId,
        "payment_method": payment_method,
        "tiffin_package": this.placeOrderItemList["hashkey"],
        "paymenttypeunkid": paymenttypeunkid,
        "useraddress_id": this.ConfirmAddress[0].useraddress_id,
        "sub_pack_date":this.placeOrderList.cart.startDate
      };

      this.service.validateOrder(orderDetail).subscribe(response => {
        if (response.status == "ok") {
          orderDetail["payment_method"] = payment_method;

          if (orderDetail["payment_method"] == "ONLINE") {
            this.onlinePay("ABC123", response.payload.razorpayorderid, orderDetail);
          } else {
            this.confirmOrder(orderDetail);
          }
        } else {
          this.toastr.error(response.message);
          $("#btnPlaceOrder").attr("disabled", false);
          $("#btnPlaceOrderOnline").attr("disabled", false);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        // this.authenticationService.logout();
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
        this.toastr.error(err.toString())
        this.spinner.hide();
      });
      this.spinner.hide();
    } else {
      this.toastr.error("Order Detail Not Available");
    }
  }

  confirmOrder(orderDetail) {
    this.spinner.show();
    orderDetail.note = "";
    //orderDetail.payment_method = "COD";
    orderDetail.order_serve_date = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en');
    this.service.placeOrder(orderDetail).subscribe(response => {
      if (response.status == "ok") {
        // this.cookieService.set("orderId", response.payload.foliono + "_" + response.payload.external_id);
        // this.orderNo = response.payload.foliono + "_" + response.payload.external_id
        // this.cookieService.set("orderId", response.payload.foliono);
        this.orderNo = response.payload.external_id;
        orderDetail.subscriptionid = response.payload.subscriptionid;

        if (orderDetail["payment_method"] == "ONLINE") {
          this.changeOrderStatus(this.orderNo, response.payload.external_id, this.grandTotalAmount, orderDetail);
        }
        // else {
      
        // //this.router.navigate(["/ordersuccess"]);
        $("#divPlaceOrder").hide();
        $("#divOrderSuccess").show();
        $("#divOrderSuccess").focus();
        this.setSubTotalUIOrderSuccess();
        // }

      } else {
        this.toastr.error(response.message);
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      // this.authenticationService.logout();
      this.toastr.error(err.toString());
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
      this.toastr.error(err.toString())
      this.spinner.hide();
    });
  }

  onlinePay(orderid, razorpayorderid, orderDetail) {
    this.spinner.show();
    
    if (this.paymentgateway_value.length > 0) {
      if (this.paymentgateway_value[0]["paymenttype"].toLowerCase() == "razorpay") {
        var options = {
          "key": this.paymentgateway_value[0]["keyid"],
          // "key":"rzp_test_m3HG7mgkgDh4QZ",
          "amount": this.grandTotalAmount * 100, // 2000 paise = INR 20
          "name": " opus infiniti",
          "currency": this.currencySymbol,
          "description": "Order #",
          "order_id": razorpayorderid,
          "image": "assets/img/logo/logo1.jpg",
          "notes": {},
          "theme": {
            "color": "blue"
          },
          "modal": {
            "ondismiss": function () {
              $("#btnPlaceOrder").attr("disabled", false);
              $("#btnPlaceOrderOnline").attr("disabled", false);
            }
          }
        };
        options["handler"] = ((response) => {
          this.confirmOrder(orderDetail);
          // this.changeOrderStatus(orderid, response.razorpay_payment_id, this.grandTotalAmount);
        });
        var rzp1 = new Razorpay(options);
        rzp1.open();
      }
      // else if (this.paymentgateway_value[0]["paymenttype"] == "PayuMoney") {
      //   // payumoneyaccessurl: "https://pos.netsolitsolution.com/payumoney/process.php"
      //   // payumoneyfailureurl: "https://pos.netsolitsolution.com/payumoney/failure.php"
      //   // payumoneysuccessurl: "https://pos.netsolitsolution.com/payumoney/success.php"
      //   //merchantid: "mntBR7mg"
      //   //secretkey: "qHXqTWK20h"

      //   const formData = new FormData();
      //   formData.append('pnd',"aaaaaaaaaa");
      //   formData.append('pst',"aaaaaaaaaa");
      //   formData.append('appname',"sundaramsky");
      //   formData.append('ext',"PM");
      //   formData.append('udf1',"32a689e18ae722c58c7d2a14bd22c75f");
      //   formData.append('ext_user', environment.queryStringData);
      //   const url = this.paymentgateway_value[0]["payumoneyaccessurl"];

      //   this.http.post<any>(url, formData).subscribe(response => {
      //     // if (response. == "ok") {

      //     // }
      //     this.spinner.hide();
      //   }, (err: HttpErrorResponse) => {
      //     // this.authenticationService.logout();
      //     $("#btnPlaceOrder").attr("disabled", false);
      //     this.spinner.hide();
      //   });
      // }
    }
    this.spinner.hide();
  }

  changeOrderStatus(orderid, payment_id, grandTotalAmount, orderDetail) {
    this.spinner.show();
    var data = {
      "payment_id": payment_id,
      "subscriptionid": orderDetail.subscriptionid,
      "amount": grandTotalAmount,
      "payment_method": orderDetail.payment_method,
      "payment_status": "Success",
      "payment_note": orderDetail.payment_method,
      "paymenttypeunkid": orderDetail.paymenttypeunkid,
      "location_id": this.locationId,
    };

    this.service.updatePaymentStatus(data).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        $("#divPlaceOrder").hide();
        $("#divOrderSuccess").show();
        // this.setSubTotalUIOrderSuccess();
        this.spinner.hide();
        //this.router.navigateByUrl('/ordersuccess');
        //this.router.navigate(["/ordersuccess"]);
      } else {
        this.toastr.error(response.message);
        $("#btnPlaceOrder").attr("disabled", false);
        $("#btnPlaceOrderOnline").attr("disabled", false);
        this.spinner.hide();
      }
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      $("#btnPlaceOrder").attr("disabled", false);
      $("#btnPlaceOrderOnline").attr("disabled", false);
    });

    this.spinner.hide();
  }
  // selectordertype(type) {
  //   if (type == "P") {
  //     this.orderType = "P";
  //     this.DeliveryCharge = [];
  //     this.ConfirmAddress = [];
  //     this.setSubTotalUI();
  //   } else {
  //     this.orderType = "H";
  //   }
  // }

  //#region 
  setSubTotalUIOrderSuccess() {
    this.spinner.show();
    this.divOrderSummary.nativeElement.innerHTML = null;

    const h4SubTotal: HTMLParagraphElement = this.renderer.createElement('h4');
    h4SubTotal.innerHTML = "Order Summary ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h4SubTotal);

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Total ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.orderSubTotal).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setTaxesUIOrderSuccess();
    this.spinner.hide();
  }

  setTaxesUIOrderSuccess() {
    this.spinner.show();
    var taxArray = JSON.parse(localStorage.getItem(this.locationId + "_taxData"));

    for (var idx in taxArray) {
      const p: HTMLParagraphElement = this.renderer.createElement('p');
      p.setAttribute("class", "mb-1");
      p.innerHTML = taxArray[idx].taxDisplayName;
      this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

      const span: HTMLParagraphElement = this.renderer.createElement('span');
      span.setAttribute("class", "float-right text-dark");
      span.innerHTML = this.currencySymbol + " " + parseFloat(taxArray[idx].taxAmount).toFixed(2);
      this.renderer.appendChild(p, span);

      //this.grandTotalAmount = parseFloat(this.grandTotalAmount) + (parseFloat(taxArray[idx].taxAmount));
    }

    if (this.cookieService.get("packingchargetype")) {
      if (this.cookieService.get("packingchargetype") == "1") {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.cookieService.get("packinglabel");
        this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + parseFloat(this.cookieService.get("packingcharge")).toFixed(2);
        this.renderer.appendChild(p, span);

        //this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.cookieService.get("packingcharge")))).toFixed(2);
      } else {
        var qty = [];
        var count = 0;
        for (var q = 0; q < this.placeOrderItemList.length; q++) {
          if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
            qty.push(this.placeOrderItemList[q]["item_id"]);
            count++;
          }
        }
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.cookieService.get("packinglabel");
        this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + (parseFloat(this.cookieService.get("packingcharge"))
          * count).toFixed(2);
        this.renderer.appendChild(p, span);

        // this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (
        //   (parseFloat(this.cookieService.get("packingcharge"))) * count
        // )).toFixed(2);
      }
    }
    //---------------------------------------------- Packing Charge  END

    // ---------------------------------------------- Delivery Charge START
    if (this.DeliveryCharge.length > 0) {
      if (this.DeliveryCharge[0]["deliverychargetype"] == "1") {
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
        this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + parseFloat(this.DeliveryCharge[0]["deliverycharge"]).toFixed(2);
        this.renderer.appendChild(p, span);

        //this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]))).toFixed(2);
      } else {
        var qty = [];
        var count = 0;
        for (var q = 0; q < this.placeOrderItemList.length; q++) {
          if (!qty.includes(this.placeOrderItemList[q]["item_id"])) {
            qty.push(this.placeOrderItemList[q]["item_id"]);
            count++;
          }
        }
        const p: HTMLParagraphElement = this.renderer.createElement('p');
        p.setAttribute("class", "mb-1");
        p.innerHTML = this.DeliveryCharge[0]["deliverylabel"];
        this.renderer.appendChild(this.divOrderSummary.nativeElement, p);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.setAttribute("class", "float-right text-dark");
        span.innerHTML = this.currencySymbol + " " + (parseFloat(this.DeliveryCharge[0]["deliverycharge"])
          * count).toFixed(2);
        this.renderer.appendChild(p, span);

        //this.grandTotalAmount = (parseFloat(this.grandTotalAmount) + (parseFloat(this.DeliveryCharge[0]["deliverycharge"]) * count)).toFixed(2);
      }
    }

    if (this.discount) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divOrderSummary.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discount.amount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      //this.grandTotalAmount = parseFloat(this.grandTotalAmount) - (parseFloat(this.discount.amount));
    }
    if (environment.roundoff != undefined && environment.roundoff != null) {
      const pRoundOff: HTMLParagraphElement = this.renderer.createElement('p');
      pRoundOff.setAttribute("class", "mb-1");
      pRoundOff.innerHTML = "Rounding off ";
      this.renderer.appendChild(this.divOrderSummary.nativeElement, pRoundOff);

      const spanRoundOff: HTMLParagraphElement = this.renderer.createElement('span');
      spanRoundOff.setAttribute("class", "float-right text-dark");

      spanRoundOff.innerHTML = this.currencySymbol + " " + this.roundoffamt;
      this.renderer.appendChild(pRoundOff, spanRoundOff);
    }

    this.setGrandTotalUIOrderSuccess();
    this.spinner.hide();
  }

  setGrandTotalUIOrderSuccess() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divOrderSummary.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 text-success");
    h6GrandTotal.innerHTML = "TO PAY ";
    this.renderer.appendChild(this.divOrderSummary.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right text-success");
    spanGrandTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.grandTotalAmount).toFixed(2);
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);

    this.clearLocalStorage();
    this.spinner.hide();
  }

  backToHome() {
    this.spinner.show();
    this.clearLocalStorage();
    this.router.navigate(['/usersubscriptions']);
    //this.router.navigateByUrl('/home');
    this.spinner.hide();
  }

  clearLocalStorage() {
    localStorage.removeItem(this.locationId + "_subscriptionDetail");
    // localStorage.clear()

    this.cookieService.delete('orderId');
    this.sharedService.sendClickEvent();
  }
  //#endregion
}
