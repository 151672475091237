import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuCategoryService {

  constructor(private http: HttpClient) { }
  
  getMenuCategory(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getmenus?${environment.queryStringData}`, data);
  }

  getMenuList(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/menus/getbyid?${environment.queryStringData}`, data);
  }

  getHomeBanner(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/gethomebanner?${environment.queryStringData}`, data);
  }

  getHomePageCategories(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/gethomepagecategorys?${environment.queryStringData}`, data);
  }
}
