<nav id="main-nav">
    <ul class="second-nav">
        <li>
            <a href="javascript:void(0)" routerLink="/home"><i class="feather-home mr-2"></i> Home</a>
        </li>
        <li>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#LoginModal" onclick="checkbutton()"
                id="login" style="display: none;">
                <!-- <i class="feather-edit mr-2"></i> Login / Register </a> -->
                <i class="feather-edit mr-2"></i> Login </a>
        </li>
        <!-- <li>
            <a href="javascript:void(0)" id="changePassword" style="display: none;" data-toggle="modal"
                data-target="#ChangePasswordModal" onclick="changepasswordbutton()"> <i
                    class="feather-lock mr-2"></i>Change Password</a>
        </li> -->
        <li >
            <a style="display: none;" href="javascript:void(0);" routerLink="/partyorders"><i class="feather-grid mr-2"></i> Party Orders </a>
        </li>
        <li>
            <a href="javascript:void(0);" id="managesubscriptions" routerLink="/managesubscriptions"><i
                    class="feather-grid mr-2"></i>
                Subscriptions </a>
        </li>
        <li>
            <a href="javascript:void(0);" id="usersubscriptions" routerLink="/usersubscriptions"
                style="display: none;"><i class="feather-grid mr-2">
                </i> My Subscriptions </a>
        </li>
        <li>
            <a href="javascript:void(0);" id="usersubscriptionorders" routerLink="/usersubscriptionorders"
                style="display: none;"><i class="feather-grid mr-2"></i>
                My Subscriptions Orders </a>
        </li>
        <li>
            <a href="javascript:void(0);" routerLink="/readfeedback"> <i class="feather-book-open mr-2"></i> Read
                Feedback</a>
        </li>
        <li>
            <a href="javascript:void(0)" routerLink="/sharefeedback"> <i class="feather-user mr-2"></i> Share
                Feedback</a>
        </li>
        <li>
            <a href="javascript:void(0)" id="offers" style="display: none;" routerLink="/offers"> <i
                    class="feather-grid mr-2"></i> Offers</a>
        </li>
        <li>
            <a href="javascript:void(0)" id="manageOrders" style="display: none;" routerLink="/orderhistory"> <i
                    class="feather-paperclip mr-2"></i> Manage Orders</a>
        </li>
        <!-- <li>
            <a href="javascript:void(0)" id="manageAddresses" style="display: none;" routerLink="/manageaddress"> <i
                    class="feather-map-pin mr-2"></i> Manage Addresses</a>
        </li> -->
        <li >
            <a  href="javascript:void(0);" routerLink="/aboutus"><i class="feather-info mr-2"></i> About Us</a></li>
        <li>
            <a  href="javascript:void(0);" routerLink="/contactus"> <i class="feather-phone mr-2"></i> Contact Us</a>
        </li>
        <li >
            <a  href="javascript:void(0);" routerLink="/privacypolicy"> <i class="feather-align-left mr-2"></i> Privacy
                Policy</a>
        </li >
        <li >
            <a href="javascript:void(0);" routerLink="/termcondition"> <i class="feather-list mr-2"></i> Terms &
                Conditions</a>
        </li>
        <li>
            <a href="javascript:void(0);" id="logout" style="display: none;" onclick="logoutbutton()"> <i
                    class="feather-edit mr-2"></i> Logout</a>
        </li>
    </ul>
    <ul class="bottom-nav">
        <li class="email">
            <a class="" href="javascript:void(0)" routerLink="/home">
                <p class="h5 m-0"><i class="feather-home"></i></p>
                Home
            </a>
        </li>
        <li class="github">
            <a  href="javascript:void(0);" routerLink="/aboutus">
                <p class="h5 m-0"><i class="feather-message-circle"></i></p>
                About Us
            </a>
        </li>
        <li class="ko-fi">
            <a  href="javascript:void(0);" routerLink="/contactus">
                <p class="h5 m-0"><i class="feather-phone"></i></p>
                Help
            </a>
        </li>
    </ul>
</nav>
<!-- 
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box">
    <p style="color: white;">Please Wait.</p>
</ngx-spinner> -->