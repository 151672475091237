import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }

  getCity(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/cities?${environment.queryStringData}`, data);
  }

  getLocation(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getlocationbycity?${environment.queryStringData}`, data);
  }

  getLocationDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getbyid?${environment.queryStringData}`, data);
  }
  
  getCountry(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/countrycodes?${environment.queryStringData}`, data);
  }

  registerUser(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/register?${environment.queryStringData}`, data);
  }

  login(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/mobileotpverify?${environment.queryStringData}`, data);
  }
  login1(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/login?${environment.queryStringData}`, data);
  }
  logout(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/logout?${environment.queryStringData}`, data);
  }

  getOfferList(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/offers/get?${environment.queryStringData}`, data); 
  }
  
  getReviewList(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/item/getreviews?${environment.queryStringData}`, data);
  }
  
  getNewArrivalItem(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/getNewArrivalItem?${environment.queryStringData}`, data);
  }

  getTrendingItem(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/getTrendingItem?${environment.queryStringData}`, data);
  }

  getOrderHistory(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/orders/gets?${environment.queryStringData}`, data); 
  }

  getOrderDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/orders/detail?${environment.queryStringData}`, data); 
  }

  getOrderReview(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/orders/review?${environment.queryStringData}`, data); 
  }

  saveOrderReview(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/submitreview?${environment.queryStringData}`, data); 
  }

  getAllReview(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/getreviews?${environment.queryStringData}`, data); 
  }

  changePassword(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/changepassword?${environment.queryStringData}`, data);
  }

  mobileLogin(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/mobilelogin?${environment.queryStringData}`, data);
  }

  resendRegistraionotp(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/resendotp?${environment.queryStringData}`, data);
  }

  mobileResendOTP(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/mobileresendotp?${environment.queryStringData}`, data);
  }

  verifyRegistrationOTP(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/verifyotp?${environment.queryStringData}`, data);
  }

  forgotpassword(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/forgotpassword?${environment.queryStringData}`, data);
  }

  verifyOTP(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/verifyuserotp?${environment.queryStringData}`, data);
  }
  
  resetpassword(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/resetpassword?${environment.queryStringData}`, data);
  }
  
  resendforgototp(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/auth/resendforgototp?${environment.queryStringData}`, data);
  }
  getOrderReviewForItem(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/orders/review?${environment.queryStringData}`, data); 
  }

  saveOrderReviewForItem(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/submititemreview?${environment.queryStringData}`, data); 
  }

}
