<!-- <div id="loader" class="center" style="visibility: visible; display: none;"></div>  -->
<div class="osahan-home-page">
    <div class="bg-light" id="divPlaceOrder">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-placeorder height-fill">
            <div class="px-3 pt-3 pb-2">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Confirm Order</h2>
            </div>
            <div class="bg-light mb-4 pt-2 osahan-cart-item cart-resize">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div class="d-flex flex-column">
                            <div class="row ml-0 mr-0">
                                <!-- LOGIN BLOCK START -->
                                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3">
                                    <h6 class="mb-0 font-weight-bold">
                                        <div class="icon-round"><i class="feather-check-circle mr-2"></i>Logged in</div>
                                    </h6>
                                    <div class="mt-2 pl-4 large">{{data.firstname}} {{data.lastname}} | {{data.phoneno}}
                                    </div>
                                </div>
                                <!-- LOGIN BLOCK END -->
                                <!-- Order Type -->
                                <!-- <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3">
                                    <h6 class="mb-0 font-weight-bold">
                                        <div class="icon-round">
                                            <i class="feather-check-circle mr-2"></i>
                                            Order Type
                                        </div>
                                    </h6> -->
                                <!-- <div class="btns-grp row ml-0 mr-0 pt-3 pb-3 ss-boxes"> -->
                                <!-- <div class="btns-grp row ml-0 mr-0 pt-3 pb-3 ss-boxes">
                                        <label class="p-3 radio-btns-grp-ss col-6 shadow">
                                            <input type="radio" name="ordertype" id="ordertype"
                                                class="custom-control mr-2" value="H" checked="checked"
                                                (click)="selectordertype('H');">Delivery
                                        </label>
                                        <label class="p-3 radio-btns-grp-ss col-6 shadow">
                                            <input type="radio" name="ordertype" id="ordertype"
                                                class="custom-control mr-2" value="P"
                                                (click)="selectordertype('P');">PickUp
                                        </label>
                                    </div>
                                </div> -->
                                <!-- Order Type -->

                                <!-- ADDRESS BLOCK START -->
                                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                    id="ordertypeH">
                                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                        id="ordertypeH" *ngIf="orderType == 'H'">
                                        <h6 class="mb-0 font-weight-bold">
                                            <div class="icon-round"><i class="feather-check-circle mr-2"></i>Delivery
                                                Address</div>
                                        </h6>
                                        <div class="row d-flex p-3 address-boxes" *ngIf="ConfirmAddress.length == 0">
                                            <div class="card col-6 pl-0 pr-0 mb-3 align-items-center">
                                                <a class="text-center w-100 d-flex align-items-center h-100"
                                                    href="javascript:void(0);" (click)="addAddress()"
                                                    style="color: #0056b3;">
                                                    <div class="card-body">
                                                        <h5 class="card-title mb-0">ADD NEW ADDRESS</h5>
                                                        <p class="card-text small">&nbsp;</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="card col-6 pl-0 pr-0 mb-3" *ngFor="let a of addressList">
                                                <div class="card-body">
                                                    <h5 class="card-title">{{a.address_type_label}}</h5>
                                                    <h6 class="card-subtitle mb-2 text-muted">{{a.address}}</h6>
                                                    <p class="card-text small">{{a.deliveryarea}}, {{a.city}},
                                                        {{a.pincode}}</p>
                                                    <a href="javascript:void(0);" (click)="setaddress(a)"
                                                        class="card-link btn btn-primary w-100">Deliver Here</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card col-12 pl-0 pr-0 mb-3 mt-3" *ngIf="ConfirmAddress.length > 0">
                                            <div class="card-body" *ngFor="let c of ConfirmAddress">
                                                <h5 class="card-title">
                                                    <i class="feather-home mr-2"></i>{{c.address_type_label}}
                                                </h5>
                                                <h6 class="card-subtitle mb-2 text-muted">
                                                    {{c.address}}
                                                </h6>
                                                <p class="card-text small">
                                                    {{c.deliveryarea}}, {{c.city}},
                                                    {{c.pincode}}
                                                </p>
                                                <a class="card-link btn btn-primary text-white"
                                                    (click)="RemoveAddress();">
                                                    Change
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                        id="ordertypeP" *ngIf="orderType == 'P'">
                                        <h6 class="mb-0 font-weight-bold">
                                            <div class="icon-round">
                                                <i class="feather-check-circle mr-2"></i>Pickup Address
                                            </div>
                                        </h6>
                                        <div class="row d-flex p-3 address-boxes">
                                            <div class="card col-12 pl-0 pr-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Pickup Point</h5>
                                                    <h6 class="card-subtitle mb-2 text-muted">{{locationData.address1}}
                                                    </h6>
                                                    <p class="card-text">{{locationData.address2}},<br>
                                                        {{locationData.city}}, {{locationData.state}}
                                                        {{locationData.zipcode}}</p>
                                                    <strong>Call on: <a href="tel:{{locationData.phoneno}}"
                                                            class="text-black">{{locationData.phoneno}}</a></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ADDRESS BLOCK END -->

                                <!-- PAYMENT BLOCK START -->
                                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-5 opacity-1"
                                    *ngIf="isOrderAvailable == 1">
                                    <h6 class="font-weight-bold mb-0">
                                        <div class=""><i class="feather-check-circle mr-2"></i>Payment</div>
                                    </h6>
                                    <div class="row ml-0 mr-0">

                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3"
                                            *ngIf="paymentgateway_value.length > 0">
                                            <span>
                                                <p class="card-text mb-2">Pay bill online securely through UPI,
                                                    Credit-Debit cards or
                                                    Net-Banking.</p>
                                                <button class="btn btn-success btn-block btn-sm w-100 p-2"
                                                    style="border-radius: 20px;" (click)="placeOrder('ONLINE');"
                                                    id="btnPlaceOrderOnline">Pay {{currencySymbol}}
                                                    <!-- onlinePay(undefined); ">Pay {{currencySymbol}} -->
                                                    {{grandTotalAmount}}<i class="icofont-long-arrow-right"></i>
                                                </button>
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3" style="display: none;">
                                            <span>
                                                <p class="card-text mb-2">Online payment recommended for better hand
                                                    hygiene
                                                </p>
                                                <button class="btn btn-primary btn-block btn-sm w-100 p-2"
                                                    (click)="placeOrder('CASH')" id="btnPlaceOrder">
                                                    Cash On Delivery {{currencySymbol}} {{grandTotalAmount}}<i
                                                        class="icofont-long-arrow-right"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- PAYMENT BLOCK END -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="bg-white rounded shadow mb-3 py-2">
                            <h6 class="mb-1 font-weight-bold pl-3 pt-2">Order Summary</h6>
                            <div id="cartdetails col-5">
                                <div
                                    class="gold-members d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
                                    <!--  *ngFor="let orders of placeOrderItemList; let i = index" -->
                                    <div class="media align-items-center">
                                        <!-- <img src="{{ placeOrderItemList['tiffinpackageimageurl"] }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"> -->
                                        <div class="media-body">
                                            <p class="m-0">{{placeOrderItemList["name"]}}</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p class="text-gray mb-0 float-right ml-2 text-muted small">
                                            {{currencySymbol}}
                                            {{orderSubTotal}}</p>
                                    </div>
                                </div>
                                <div class="bg-white rounded p-3 pb-4">Sub Total : <p
                                        class="text-gray mb-0 float-right ml-2 large bold">{{currencySymbol}}
                                        {{orderSubTotal}}</p>
                                </div>
                                <!-- <div class="mb-0 input-group pl-3 pr-3">
                                    <div class="input-group-prepend"><span class="input-group-text"><i
                                                class="feather-message-square"></i></span></div><textarea
                                        placeholder="Add any Special Instructions" aria-label="With textarea"
                                        class="form-control" name="SpecialInstructions" id="SpecialInstructions"></textarea>
                                </div> <br> -->
                            </div>
                        </div>
                        <!-- <div class="mb-3 shadow bg-white rounded p-3 py-1 mt-3 clearfix">
                    <div class="input-group-sm input-group">
                        <div class="apply-coupon-div row mx-auto">
                            <div class="col-6 pl-0 pr-0"><svg width="25px" height="25px" viewBox="0 0 19 11">
                                    <path fill="#094f8d"
                                        d="M.634 0C.284 0 0 .274 0 .611v9.778c0 .338.284.611.634.611h17.733c.35 0 .633-.273.633-.611V.611C19 .274 18.716 0 18.367 0H.634zm3.8 1.222c0-.337.284-.611.633-.611.35 0 .634.274.634.611v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V1.222zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V4.889zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.222c0 .338-.284.612-.634.612-.35 0-.633-.274-.633-.612V8.556zm9.5-2.444c.7 0 1.266.547 1.266 1.221 0 .676-.567 1.222-1.266 1.222-.7 0-1.267-.546-1.267-1.222 0-.674.567-1.221 1.267-1.221zm-3.8-3.667c.699 0 1.266.547 1.266 1.222 0 .674-.567 1.221-1.267 1.221-.699 0-1.266-.547-1.266-1.221 0-.675.567-1.222 1.266-1.222zm.504 5.865c-.209.269-.604.323-.883.122-.279-.202-.335-.584-.126-.853l3.8-4.889c.209-.269.604-.323.883-.121.279.201.335.583.126.852l-3.8 4.889z">
                                    </path>
                                </svg><span class="ml-1">Select offer / Apply coupon</span></div>
                            <div class="col-6 float-right text-right pl-0 pr-0"><button id="button-addon2" type="button"
                                    class="btn btn-primary" data-toggle="modal" data-target="#CouponCodesShow"
                                    onclick="viewallcoupons();"> View All Coupons </button></div>
                        </div>
                    </div>
                </div> 
                        <div class="shadow bg-white rounded p-3 clearfix mb-5" #divSubTotal>
                            <p class="mb-1">Discount (-) <span class="float-right text-dark">{{currencySymbol}} 35.00</span></p> -->
                        <!-- <hr> -->
                        <!-- <h6 class=" mb-0 pb-2" style="font-size: 10px;">Round Off <span class="float-right">{{currencySymbol}} 0.40</span></h6> -->
                        <div class="shadow bg-white rounded p-3 clearfix mb-5" #divSubTotal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-light" id="divOrderSuccess">
        <div class="osahan-vieworder height-fill">
            <div class="p-3">
                <!-- <div class="alert alert-info" role="alert">
                    <button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span><span
                            class="sr-only">Close</span></button>
                    Your order is saved.
                </div> -->
                <!-- <h2 class="font-weight-bold m-0 cart-resize with-title">#{{orderNo}}</h2> -->
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div class="row ml-0 mr-0">
                            <div class="osahan-cart-item-profile bg-white rounded shadow BG-WHITE col-12 mb-3">
                                <div class="flex-column">
                                    <div class="row p-3 d-flex">
                                        <div class="col-12 img-icon-size pl-0 pr-0">
                                            <div
                                                class="order-status bg-white z-depth-1 pt-4 pb-4 mt-3 mb-3 text-center bdrradius col-12 pl-0 pr-0">
                                                <div class="order-progress">
                                                    <div class="steps-of-order-progress active">
                                                        <div
                                                            class="pt-3 step-container row align-items-top ml-1 mr-1 border-bottom">
                                                            <div class="col-2 p-0 mb-2">
                                                                <img src="https://raivatkitchen.com/assets/img/ordermessageicon/Received.png"
                                                                    class="order-status-icon" id="orderstatusicon">
                                                                <div class="badge status-badge mt-2 pl-3 pr-3 pb-1 pt-1"
                                                                    id="orderstatustag">Done</div>
                                                            </div>
                                                            <div class="col-10 text-left">
                                                                <h6 class="status-msg" id="orderstatusmessage">Your
                                                                    Subscription Added Successfully!</h6>
                                                                <!-- <p class="small mb-1" id="orderstatusdescription">
                                                                    Waiting for Food Ready.</p> -->
                                                                <span id="orderstatusbtn"><a
                                                                        href="tel:{{locationData.phoneno}}"
                                                                        class="mb-2 small btn btn-primary btn-sm d-none w-auto">
                                                                        Call Kitchen</a></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-xs-12 mb-3">
                        <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12">
                            <div class="flex-column">
                                <div #divOrderSummary>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div
                            class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype">
                            <h6 class="mb-0 font-weight-bold">
                                <div class="icon-round">
                                    <i class="feather-check-circle mr-2"></i>Order Details
                                </div>
                            </h6>
                            <div class="row d-flex p-3 address-boxes">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-8 form-group">
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Subscription: </b>{{lblItemName}} ({{currencySymbol}}
                                                    {{lblItemRate}})</span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Duration: </b> {{itemDetails["duration"]}}
                                                    {{itemDetails["text_duration_type"]}}
                                                </span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Grace period: </b> {{itemDetails["grace_period"]}}
                                                    {{itemDetails["text_grace_period_type"]}} </span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Tiffin type: </b> {{itemDetails["tiffine_type"]}} </span>
                                            </div> 
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Start Date: </b> {{startdate}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="flex-column">
                            <button type="submit" class="btn btn-primary btn-lg btn-block" routerLink="/home"
                                (click)="backToHome()">Back To Home</button>
                        </div>
                    </div>
                </div>



            </div>
        </div>
    </div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>