<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="osahan-contact height-fill">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Share Feedback</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5 BG-WHITE">
                    <div class="flex-column">
                        <h6 class="font-weight-bold">Your valuable feedback will help us improve our services. </h6>
                        <p class="text-muted"></p>
                        <!-- <form>
                            <input type="hidden" value="Parin Patel" name="fdata[name]">
                            <input type="hidden" value="8511150276" name="fdata[phone_number]">
                            <input type="hidden" value="parin@netsolitsolution.com" name="fdata[email]">
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label for="remarks" class="small font-weight-bold">Remarks</label>
                                <textarea type="text" name="fdata[remarks]" class="form-control" id="remarks"
                                    placeholder="Share your remarks" rows="5"></textarea>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Taste:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[taste]" id="option1" value="Excellent">
                                        Excellent
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[taste]" id="option2" value="Good"> Good
                                    </label>
                                </div>
                                <div id="errormsg" class="text-danger"></div>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Food quality:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quality]" id="option1" value="Excellent">
                                        Excellent
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quality]" id="option2" value="Good"> Good
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quality]" id="option3" value="Satisfactory">
                                        Satisfactory
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quality]" id="option3" value="Dissatisfied">
                                        Dissatisfied
                                    </label>
                                </div>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Quantity:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quantity]" id="option1" value="Excellent">
                                        Excellent
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quantity]" id="option2" value="Good"> Good
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quantity]" id="option3" value="Satisfactory">
                                        Satisfactory
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[quantity]" id="option3" value="Dissatisfied">
                                        Dissatisfied
                                    </label>
                                </div>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Delivery Speed:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[delivery_speed]" id="option1"
                                            value="Super fast"> Super fast
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[delivery_speed]" id="option2" value="Fast"> Fast
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[delivery_speed]" id="option3" value="Okay"> Okay
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[delivery_speed]" id="option3" value="Slow"> Slow
                                    </label>
                                </div>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Spices:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[spices]" id="option1" value="Perfect"> Perfect
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[spices]" id="option2" value="Good"> Good
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[spices]" id="option3" value="Very High"> Very
                                        High
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[spices]" id="option3" value="Very Low"> Very Low
                                    </label>
                                </div>
                            </div>
                            <div class="mb-0 col-md-12 form-group mb-3">
                                <label class="form-label">Packaging:</label>
                                <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[packaging]" id="option1" value="Excellent">
                                        Excellent
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[packaging]" id="option2" value="Good"> Good
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[packaging]" id="option3" value="Satisfactory">
                                        Satisfactory
                                    </label>
                                    <label class="btn btn-outline-secondary">
                                        <input type="radio" name="fdata[packaging]" id="option3" value="Dissatisfied">
                                        Dissatisfied
                                    </label>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
                        </form> -->
                        <div id="divFeedbackData" #divFeedbackData >

                        </div>
                        <button type="submit" class="btn btn-primary btn-block" (click)="saveFeedback()">SUBMIT</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>