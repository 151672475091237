import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './_helpers/AuthGuard';
import { HomeComponent } from './home/home.component';
import { MenuCategoryComponent } from './menu-category/menu-category.component';
import { ProfileComponent } from './profile/profile.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PlaceOrderComponent } from './place-order/place-order.component';
import { OrderSuccessComponent } from './order-success/order-success.component';
import { ManageOrdersComponent } from './manage-orders/manage-orders.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ManageAddressComponent } from './manage-address/manage-address.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PartyOrdersComponent } from './party-orders/party-orders.component';
import { ShareFeedbackComponent } from './share-feedback/share-feedback.component';
import { ReadFeedbackComponent } from './read-feedback/read-feedback.component';
import { OffersComponent } from './offers/offers.component';
import { ManageSubscriptionsComponent } from './manage-subscriptions/manage-subscriptions.component';
import { PlaceordersubscriptionComponent } from './placeordersubscription/placeordersubscription.component';
import { UsersubscriptionsComponent } from './usersubscriptions/usersubscriptions.component';
import { UsersubscriptionordersComponent } from './usersubscriptionorders/usersubscriptionorders.component';
import { PlaceextraordersubscriptionComponent } from './placeextraordersubscription/placeextraordersubscription.component';
import { ManageSubscriptionDetailComponent } from './manage-subscription-detail/manage-subscription-detail.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'  },
  { path: 'home', component: HomeComponent },
  { path: 'menucategory', component: MenuCategoryComponent },
  { path: 'menucategory/:categoryId', component: MenuCategoryComponent },
  { path: 'profile', component: ProfileComponent},
  { path: 'checkout', component: CheckoutComponent},
  { path: 'placeorder', component: PlaceOrderComponent},
  { path: 'ordersuccess', component: OrderSuccessComponent},
  { path: 'orderhistory', component: ManageOrdersComponent},
  { path: 'termcondition', component: TermConditionComponent},
  { path: 'privacypolicy', component: PrivacyPolicyComponent},
  { path: 'aboutus', component: AboutUsComponent},
  { path: 'contactus', component: ContactUsComponent},
  { path: 'manageaddress', component: ManageAddressComponent},
  { path: 'manageaddress/:id', component: ManageAddressComponent},
  { path: 'partyorders', component: PartyOrdersComponent},
  { path: 'sharefeedback', component: ShareFeedbackComponent},
  { path: 'readfeedback', component: ReadFeedbackComponent},
  { path: 'offers', component: OffersComponent},
  { path: 'managesubscriptions', component: ManageSubscriptionsComponent},
  { path: 'placeordersubscription', component: PlaceordersubscriptionComponent},
  { path: 'usersubscriptions', component: UsersubscriptionsComponent},
  { path: 'usersubscriptionorders', component: UsersubscriptionordersComponent},
  { path: 'placeextraordersubscription', component: PlaceextraordersubscriptionComponent},
  { path: 'managesubscriptionsdetail', component: ManageSubscriptionDetailComponent}
  // { path: '**', component: HomeComponent }

  // {
  //   // path: 'admin', component: NavigationComponent, canActivate: [AuthGuard],
  //   children: [
  //     { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  //     { path: 'dashboard', component: DashboardComponent },
  //     { path: 'tournament', component: TournamentListComponent },
  //     { path: 'addtournament/:id', component: TournamentComponent },
  //   ]
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
