<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="osahan-contact">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Contact us</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize mb-5 pb-3">
                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
                    <div class="flex-column">

                        <!-- <div id="intro" class="mt-3 pb-1">
                            <h4 class="font-weight-bold mb-3">Reach us</h4>
                            <h6 class="mb-2">
                                {{locationAddress}}</h6>
                            Phone : <a href="tel:{{locationPhoneNumber}}">{{locationPhoneNumber}}</a><br>
                            Email : <a href="javascript:void(0);">{{locationEmail}}</a>
                        </div> -->

                        <!-- <h6 class="font-weight-bold">Tell us about yourself</h6> -->

                        <!-- <form #ContactUsForm="ngForm" (ngSubmit)="submitContactUs()">
                            <div class="form-group">
                                <label class="form-label">Your Name <span class="text-danger">*</span></label>
                                <input type="text" name="yourname" class="form-control" id="yourname" #yourname="ngModel" ngModel 
                                    placeholder="Enter Your Name" autocomplete="off" required>
                            </div>
                            <span *ngIf="ContactUsForm.submitted && yourname.invalid">
                                <p *ngIf="yourname.errors.required" class="error_message" style="color: red;">
                                    Your name is required
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">Email Address <span class="text-danger">*</span></label>
                                <input type="email" name="email" class="form-control" id="email" #email="ngModel" ngModel 
                                    placeholder="Enter Email Address" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" autocomplete="off" required>
                            </div>
                            <span *ngIf="ContactUsForm.submitted && email.invalid">
                                <p *ngIf="email.errors.required" class="error_message" style="color: red;">
                                    Email is required
                                </p>
                                <p *ngIf="email.errors.pattern" class="error_message" style="color: red;">
                                    Email is invalid
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">Phone Number <span class="text-danger">*</span></label>
                                <input type="text" name="phonenumber" class="form-control" id="phonenumber" #phonenumber="ngModel" ngModel 
                                    placeholder="Enter Phone Number" numbersOnly pattern="[0-9]*" maxlength="10" autocomplete="off" required>
                            </div>
                            <span *ngIf="ContactUsForm.submitted && phonenumber.invalid">
                                <p *ngIf="phonenumber.errors.required" class="error_message" style="color: red;">
                                    Phone number is required
                                </p>
                            </span>
                            <div class="form-group">
                                <label class="form-label">HOW CAN WE HELP YOU? <span class="text-danger">*</span></label>
                                <textarea class="form-control" name="enquiry" id="enquiry" #enquiry="ngModel" ngModel 
                                    placeholder="Hi there, I would like to ..." rows="3" autocomplete="off" required></textarea>
                            </div>
                            <span *ngIf="ContactUsForm.submitted && enquiry.invalid">
                                <p *ngIf="enquiry.errors.required" class="error_message" style="color: red;">
                                    Enquiry is required
                                </p>
                            </span>
                            <button type="submit" class="btn btn-primary btn-block">SUBMIT</button>
                        </form> -->

                        <h4 class="font-weight-bold pt-3">Locate us</h4>
                        <div class="mapouter pt-4">
                            <div class="gmap_canvas"><iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14847.479211766884!2d70.4610427!3d21.5128191!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3713f02c5722c56f!2sRaivat%20Kitchen!5e0!3m2!1sen!2sin!4v1603974733309!5m2!1sen!2sin"
                                    width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen=""
                                    aria-hidden="false" tabindex="0"></iframe></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>