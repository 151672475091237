import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaceOrderSubscriptionService {

  constructor(private http: HttpClient) { }
  
  updatePaymentStatus(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/transaction/updatetiffinpaymentstatus?${environment.queryStringData}`, data);
  }
  
  getDeliveryCharge(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/getdeliverycharge?${environment.queryStringData}`, data);
  }

  getTaxes(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/gettaxes?${environment.queryStringData}`, data);
  }
  
  getLocationDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getbyid?${environment.queryStringData}`, data);
  }

  validateOrder(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/validatetiffinsubscription?${environment.queryStringData}`, data);
  }

  placeOrder(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/placetiffinsubscription?${environment.queryStringData}`, data);
  }

  // getPromoCodeList(data) {
  //   return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getpromocodelist?${environment.queryStringData}`, data);
  // }

  // applyPromoCode(data) {
  //   return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/getuserpromocode?${environment.queryStringData}`, data);
  // }

  // saveContactUs(data) {
  //   return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/contactus/save?${environment.queryStringData}`, data);
  // }

  // savePartyOrders(data) {
  //   return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/savePartyOrder?${environment.queryStringData}`, data);
  // }

  // payumoneyRequest(url,data){
  //   return this.http.post(url, data);
  // }

}
