<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="osahan-contact">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Profile</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize mb-5 pb-3">
                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
                    <div class="flex-column">
                        <h6 class="font-weight-bold">Manage your profile</h6>
    
                        <form class="pt-2" #ProfileForm="ngForm" class="mt-4" (ngSubmit)="updateProfile(data)">
                            <div class="form-row">
                                <div class="col-md-12 form-group">
                                    <label class="form-label">First Name <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                                    class="feather-edit"></i></span>
                                        </div>
                                        <input type="text" class="form-control" #firstname="ngModel" ngModel
                                            name="firstname" id="firstname" placeholder="Enter First Name"
                                            autocomplete="off" [(ngModel)]="data.firstname" required>
                                    </div>
                                    <span *ngIf="ProfileForm.submitted && firstname.invalid">
                                        <p *ngIf="firstname.errors.required" class="error_message" style="color: red;">
                                            First name is required
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12 form-group">
                                    <label class="form-label">Last Name <span class="text-danger">*</span></label>
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                                    class="feather-edit"></i></span>
                                        </div>
                                        <input type="text" class="form-control" #lastname="ngModel" ngModel name="lastname"
                                            id="lastname" placeholder="Enter Last Name" autocomplete="off"
                                            [(ngModel)]="data.lastname" required>
                                    </div>
                                    <span *ngIf="ProfileForm.submitted && lastname.invalid">
                                        <p *ngIf="lastname.errors.required" class="error_message" style="color: red;">
                                            Last name is required
                                        </p>
                                    </span>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12 form-group">
                                    <label class="form-label">Mobile Number</label>
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <div id="button-mobileno" type="button" class="btn btn-outline-secondary">
                                                <i class="feather-phone-call"></i>
                                            </div>
                                        </div>
                                        <input type="text" #phoneno="ngModel" ngModel name="phoneno" class="form-control"
                                            id="phoneno" [(ngModel)]="data.phoneno" readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12 form-group">
                                    <label class="form-label">Email</label>
                                    <div class="input-group">
                                        <div class="input-group-append">
                                            <div id="button-email" type="button" class="btn btn-outline-secondary">
                                                <i class="feather-send"></i>
                                            </div>
                                        </div>
                                        <input type="email" #email="ngModel" ngModel name="email" class="form-control"
                                            id="email" [(ngModel)]="data.email" readonly>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg btn-block" style="border-radius: 30px;">UPDATE</button>
                        </form>
                        <div class="row p-3 ">
                            <a href="javascript:void(0)"
                                class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6" routerLink="/orderhistory">
                                <div class="left mr-3">
                                    <h6 class="font-weight-bold m-0 text-dark"><i
                                            class="feather-truck bg-danger text-white p-2 rounded-circle mr-2"></i>
                                        Manage Orders </h6>
                                </div>
                                <div class="right ml-auto">
                                    <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                                </div>
                            </a>
                            <!-- <a href="javascript:void(0)"
                                class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6" routerLink="/manageaddress">
                                <div class="left mr-3">
                                    <h6 class="font-weight-bold m-0 text-dark"><i
                                            class="feather-map-pin bg-danger text-white p-2 rounded-circle mr-2"></i>
                                        Manage Addresses </h6>
                                </div>
                                <div class="right ml-auto">
                                    <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                                </div>
                            </a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>