import { Component, OnInit, resolveForwardRef, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { CheckoutService } from '../checkout/checkout.service';

@Component({
  selector: 'app-party-orders',
  templateUrl: './party-orders.component.html',
  styleUrls: ['./party-orders.component.css']
})
export class PartyOrdersComponent implements OnInit {

  @ViewChild('PartyOrderForm') partyOrderForm: NgForm;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  constructor(private toastr: ToastrService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private checkoutService: CheckoutService) { }

  ngOnInit() {
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
  }

  submitPartyOrder() {
    if (this.partyOrderForm.valid) {
      this.spinner.show
      var obj = {
        "location_id": this.cookieService.get("location"),
        "name": this.partyOrderForm.controls.yourname.value,
        "phone": this.partyOrderForm.controls.phonenumber.value,
        "members": this.partyOrderForm.controls.members.value,
        "partydate": this.partyOrderForm.controls.date_time.value,
        "requirement": this.partyOrderForm.controls.requirements.value,
      };
      
      this.checkoutService.savePartyOrders(obj).subscribe(response => {
        if (response.status == "ok") {
          this.partyOrderForm.resetForm();
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}
