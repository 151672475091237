<style>
  ::-webkit-scrollbar {
    height: 2px;
  }
</style>
<div class="osahan-home-page">
  <div class="bg-light" id="divMenuItem">
    <div
      class="alert alert-info text-center"
      role="alert"
      *ngIf="isOrderAvailable == 0"
    >
      <h4>We are accepting orders {{ orderTime }}.</h4>
    </div>
    <div class="cart-resize">
      <div class="px-3 pt-3 pb-1">
        <h2 class="font-weight-bold m-0 pt-1 with-title">Subscription List</h2>
      </div>
      <div class="most_popular p-3">
        <!-- <div class="row">
                    <div class="col-lg-4 col-xs-12 col-md-4 pb-3" *ngFor="let items of SubscriptionList;let i = index">
                        <div
                            class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm grid-card">
                            <div class="list-card-image">
                                <a href="javascript:void(0)" (click)="getItemDetails(items,i)">
                                    <img src="{{items.tiffinpackageimageurl}}" alt="{{items.tiffinpackageimageurl}}"
                                        style="height: 220px;" onerror="this.src='assets/img/logo/logo-jpg.jpg';"
                                        class="img-fluid item-img w-100">
                                </a>
                            </div>
                            <div class="p-3 position-relative">
                                <div class="list-card-body">
                                    <div class="dish-contents d-flex">
                                        <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0 pb-2">
                                            <h6 class="mb-1 mt-1">
                                                <a href="javascript:void(0)" class="text-black"
                                                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap !important;"
                                                    (click)="getItemDetails(items,i)">
                                                    {{items.name}}
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 pb-2">
                                            <p class="text-gray mb-0 time">
                                                <span
                                                    class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss">
                                                    {{currencySymbol}}
                                                    {{items.price}}/-</span>
                                            </p>
                                        </div>
                                        <div class="col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 pl-0 pr-0">
                                            <p class="small mb-3" [innerHTML]="items.description"></p>
                                        </div>
                                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 ">
                                            <button class="btn btn-primary btn-block add-btn"
                                                (click)="getItemDetails(items,i)" [disabled]="isOrderAvailable == 0">
                                                Add </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
        <div class="container">
          <div
            class="column"
            *ngFor="let items of SubscriptionList; let i = index"
          >
            <div
              class="pricing-card"
              [ngClass]="{
                basic: i % 3 == 0,
                eco: i % 3 == 1,
                pro: i % 3 == 2
              }"
            >
              <div class="pricing-header">
                <span class="plan-title"> {{ items.name }} </span>
                <div class="price-circle">
                  <span class="price-title">
                    <small> {{ currencySymbol }} </small
                    ><span>{{ items.price }}</span>
                  </span>
                  <!-- <span class="info">/ Month</span> -->
                </div>
              </div>
              <div class="badge-box">
                <!-- <span>{{items.description}}</span> -->
              </div>
              <ul>
                <li>
                  <strong>Duration: </strong>
                  {{ items.duration }} {{ items.text_duration_type }}
                </li>
                <li>
                  <strong>Grace period:</strong>
                  {{ items.grace_period }} {{ items.text_grace_period_type }}
                </li>
                <li>
                  <strong>Tiffin type: </strong>
                  {{ items.tiffine_type }}
                </li>
                <!-- <li>
                                    <strong>1 Free</strong> Domain
                                </li>
                                <li>
                                    <strong>1 FTP</strong> Account
                                </li> -->
              </ul>
              <div class="buy-button-box">
                <a
                  href="javascript:void(0)"
                  class="buy-now"
                  (click)="getItemDetails(items, i)"
                  >BUY NOW</a
                >
                &nbsp;&nbsp;&nbsp;<a
                  href="javascript:void(0)"
                  class="buy-now"
                  (click)="getViewDetailsforweeks(items, i)"
                  >VIEW MENU</a
                >
              </div>
            </div>
          </div>
          <!-- <div class="column">
                        <div class="pricing-card eco">
                            <div class="pricing-header">
                                <span class="plan-title">ECO PLAN</span>
                                <div class="price-circle">
                                    <span class="price-title">
                                        <small>$</small><span>16.95</span>
                                    </span>
                                    <span class="info">/ Month</span>
                                </div>
                            </div>
                            <div class="badge-box">
                                <span>Save 15%</span>
                            </div>
                            <ul>
                                <li>
                                    <strong>5</strong> Domains
                                </li>
                                <li>
                                    <strong>50 GB</strong> Disk Space
                                </li>
                                <li>
                                    <strong>250 GB</strong> Bandwith
                                </li>
                                <li>
                                    <strong>2 Free</strong> Domains
                                </li>
                                <li>
                                    <strong>Unlimited FTP</strong> Account
                                </li>
                            </ul>
                            <div class="buy-button-box">
                                <a href="#" class="buy-now">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="pricing-card pro">
                            <div class="popular">POPULAR</div>
                            <div class="pricing-header">
                                <span class="plan-title">PRO PLAN</span>
                                <div class="price-circle">
                                    <span class="price-title">
                                        <small>$</small><span>23.95</span>
                                    </span>
                                    <span class="info">/ Month</span>
                                </div>
                            </div>
                            <div class="badge-box">
                                <span>Save 8%</span>
                            </div>
                            <ul>
                                <li>
                                    <strong>10</strong> Domains
                                </li>
                                <li>
                                    <strong>100 GB</strong> Disk Space
                                </li>
                                <li>
                                    <strong>Unlimited</strong> Bandwith
                                </li>
                                <li>
                                    <strong>3 Free</strong> Domains
                                </li>
                                <li>
                                    <strong>Unlimited FTP</strong> Account
                                </li>
                            </ul>
                            <div class="buy-button-box">
                                <a href="#" class="buy-now">BUY NOW</a>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div class="pricing-card business">
                            <div class="pricing-header">
                                <span class="plan-title">BUSINESS PLAN</span>
                                <div class="price-circle">
                                    <span class="price-title">
                                        <small>$</small><span>59.95</span>
                                    </span>
                                    <span class="info">/ Month</span>
                                </div>
                            </div>
                            <div class="badge-box">
                                <span>Save 5%</span>
                            </div>
                            <ul>
                                <li>
                                    <strong>Unlimited</strong> Domain
                                </li>
                                <li>
                                    <strong>250 GB</strong> Disk Space
                                </li>
                                <li>
                                    <strong>Unlimited</strong> Bandwith
                                </li>
                                <li>
                                    <strong>5 Free</strong> Domains
                                </li>
                                <li>
                                    <strong>Unlimited FTP</strong> Account
                                </li>
                            </ul>
                            <div class="buy-button-box">
                                <a href="#" class="buy-now">BUY NOW</a>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade ss-login"
  id="ItemAddToCart"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
  style="overflow-y: auto"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <b>{{ lblItemName }}</b>
        </h5>
        <button type="button" class="close" (click)="resetAddToCart()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form
          #ModifierForm="ngForm"
          name="ModifierForm"
          (ngSubmit)="addToCart()"
        >
          <div id="adons-options-block">
            <div class="mx-auto w-100">
              <div
                class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100"
              >
                <img
                  src="{{ itemDetailImage }}"
                  alt="{{ itemDetailImage }}"
                  onerror="this.src='assets/img/logo/logo1.jpg';"
                  style="max-height: 475px"
                  class="img-fluid w-100 p-3"
                />
              </div>
              <div
                class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
              >
                <div class="col-6 pl-0 pr-0">
                  <span class="badge badge-success">Subscription</span>
                </div>
                <div class="col-6 text-right pl-0 pr-0">
                  <div class="price-tag card-item-price">
                    {{ currencySymbol }}
                    <span id="hp_final_cost">{{ lblItemRate }}</span
                    >/-
                  </div>
                </div>
                <div class="col-12 pt-1 pl-0 pr-0">
                  <p [innerHTML]="itemDetailDescription"></p>
                </div>
                <!-- <div class="col-12 pt-1 pb-1 pl-0 pr-0" *ngFor="let types of itemTypes">
                                    <span>
                                        <img src="{{ types.image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"><b>{{ types.name}}</b>
                                    </span>
                                </div> -->
              </div>
              <!-- <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
                                *ngIf="itemUnits != undefined && itemUnits.length > 1">
                                <div class="mb-0 col-md-12 form-group mb-3">
                                    <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                        <a *ngFor="let units of itemUnits; let i = index" id="units{{i}}"
                                            class="btn btn-outline-secondary btns-select "
                                            [ngClass]="{ 'active': i == 0 }"
                                            style="border-radius: 30px; margin-left: 10px;" href="javascript:void(0);"
                                            (click)="getModifiers(i,units.modifiers)">
                                            <input type="radio" name="itemunitid{{i}}" data-val="0.00"
                                                class="form-check-input-addon" id="itemunitid{{i}}" value="{{i}}">
                                            {{units.unit_name}} ({{currencySymbol}} {{units.price}})
                                        </a>
                                    </div>
                                </div>
                            </div> -->
              <div
                class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
              >
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="col-md-8 form-group">
                      <div class="input-group" style="line-height: 30px">
                        <span
                          ><b>Subscription: </b>{{ lblItemName }} ({{
                            currencySymbol
                          }}
                          {{ lblItemRate }})</span
                        >
                      </div>
                      <div class="input-group" style="line-height: 30px">
                        <span
                          ><b>Duration: </b> {{ itemDetails["duration"] }}
                          {{ itemDetails["text_duration_type"] }}
                        </span>
                      </div>
                      <div class="input-group" style="line-height: 30px">
                        <span
                          ><b>Grace period: </b>
                          {{ itemDetails["grace_period"] }}
                          {{ itemDetails["text_grace_period_type"] }}
                        </span>
                      </div>
                      <div class="input-group" style="line-height: 30px">
                        <span
                          ><b>Tiffin type: </b>
                          {{ itemDetails["tiffine_type"] }}
                        </span>
                      </div>
                    </div>
                    <!-- <div class="col-md-4 form-group">
                                            <span class="count-number float-right">
                                                <div class="input-group" style="text-align: right; display: block;">
                                                    <button type="button" class="btn-sm left dec btn btnCart"
                                                        id="decrease" value="Decrease Value"
                                                        (click)="countItemChange(0)">
                                                        <i class="feather-minus btnIcon"></i>
                                                    </button>
                                                    <input disabled min="1" max="50" value="1"
                                                        class="count-number-input" id="itemQuantity" type="text">
                                                    <button type="button" class="btn-sm right inc btn btnCart"
                                                        id="increase" value="Increase Value"
                                                        (click)="countItemChange(1)">
                                                        <i class="feather-plus btnIcon"></i>
                                                    </button>
                                                </div>
                                            </span>
                                        </div> -->
                  </div>
                </div>
                <!-- <div class="col-md-12">
                                    <div #divFormRow>
                                    </div>
                                </div> -->
              </div>
              <div
                class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
              >
                <div class="col-md-12">
                  <div class="form-row">
                    <div class="col-md-12 form-group">
                      <!-- itemDetails.week_item_data -->
                      <div
                        class="input-group"
                        style="line-height: 30px; width: 100%"
                        *ngFor="
                          let week of itemDetails['week_item_data'];
                          let i = index
                        "
                      >
                        <span style="float: left; width: 50%"
                          ><b>{{ week.week_name }}: </b></span
                        >
                        <span style="text-align: right; width: 50%">
                          <a
                            href="javascript:void(0)"
                            (click)="viewWeeklyItemDetail(week)"
                            >View Details</a
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div #divJainAddToCart class="pt-3"></div> -->
            </div>
          </div>
          <div class="form-row pt-3">
            <div class="col-md-12 form-group">
              <div class="input-group">
                <div class="input-group-append">
                  <span
                    id="button-name"
                    type="button"
                    class="btn btn-outline-secondary"
                    ><i class="feather-edit"></i
                  ></span>
                </div>
                <textarea
                  #addNote="ngModel"
                  ngModel
                  name="addNote"
                  id="addNote"
                  class="form-control"
                  rows="3"
                  placeholder="Add note here..."
                ></textarea>
              </div>
            </div>
          </div>
          <div class="form-row pt-3">
            <div class="col-md-12 form-group">
              <label> Subscription start date</label><br />

              <div class="input-group">
                <div class="input-group-append">
                  <span
                    id="button-name"
                    type="button"
                    class="btn btn-outline-secondary"
                    ><i class="feather-calendar"></i
                  ></span>
                </div>
                <input
                  type="date"
                  name="startDate"
                  class="form-control"
                  placeholder="Enter Date"
                  onkeydown="return false"
                  id="startDate"
                  autocomplete="off"
                  required
                />
              </div>
            </div>
          </div>
          <div class="form-row pt-3">
            <div class="col-md-12 form-group">
              <div class="input-group">
                <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  [disabled]="isOrderAvailable == 0"
                >
                  BUY {{ currencySymbol }} {{ lblTotalAmount }}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade ss-addon-modal"
  id="weeklyItemDetail"
  role="dialog"
  tabindex="-1"
  style="min-height: 200px"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <b>Item Details - {{ weekname }}</b>
        </h5>
        <button type="button" class="close" (click)="weeklyItemDetailClose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <!-- <button type="button" class="close modal-close" data-dismiss="modal">&times;</button> -->
      </div>
      <div class="modal-body">
        <div
          class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
        >
          <div class="col-md-12">
            <div class="form-row">
              <div class="col-md-12 form-group">
                <!-- itemDetails.week_item_data -->
                <div
                  class="input-group"
                  style="line-height: 30px; width: 100%"
                  *ngFor="let itm of Weekly_item_detail; let i = index"
                >
                  <span style="float: left; width: 50%"
                    ><b>{{ itm.itemname }} </b></span
                  >
                  <span style="float: left; width: 30%"
                    >{{ itm.display_item_type }}
                  </span>
                  <span style="text-align: right; width: 20%">
                    Qty: {{ itm.qty }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade ss-login"
  id="ItemAddToCart1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
  style="overflow-y: auto"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <b>{{ lblItemName }}</b>
        </h5>
        <button type="button" class="close" (click)="resetAddToCart()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <form #ModifierForm="ngForm" name="ModifierForm" (ngSubmit)="addToCart()"> -->
        <div id="adons-options-block">
          <div class="mx-auto w-100">
            <!-- <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="max-height: 475px;"
                                    class="img-fluid w-100 p-3">
                            </div> -->
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-6 pl-0 pr-0">
                <span class="badge badge-success">Subscription</span>
              </div>
              <div class="col-6 text-right pl-0 pr-0">
                <div class="price-tag card-item-price">
                  {{ currencySymbol }}
                  <span id="hp_final_cost">{{ lblItemRate }}</span
                  >/-
                </div>
              </div>
              <div class="col-12 pt-1 pl-0 pr-0">
                <p [innerHTML]="itemDetailDescription"></p>
              </div>
            </div>
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-8 form-group">
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Subscription: </b>{{ lblItemName }} ({{
                          currencySymbol
                        }}
                        {{ lblItemRate }})</span
                      >
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Duration: </b> {{ itemDetails["duration"] }}
                        {{ itemDetails["text_duration_type"] }}
                      </span>
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Grace period: </b>
                        {{ itemDetails["grace_period"] }}
                        {{ itemDetails["text_grace_period_type"] }}
                      </span>
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Tiffin type: </b> {{ itemDetails["tiffine_type"] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-12 form-group">
                    <!-- itemDetails.week_item_data -->
                    <div
                      class="input-group week-items"
                      style="line-height: 30px; width: 100%"
                      *ngFor="
                        let week of itemDetails['week_item_data'];
                        let i = index
                      "
                    >
                      <span style="float: left; width: 20%" class="week-inline"
                        ><b>{{ week.week_name }}: </b>
                        <div></div
                      ></span>
                      <div class="week-parent">
                        <div
                          *ngFor="let week_item_details of week['item_detail']"
                        >
                          <div class="category-items">
                            <div class="category-flex">
                              <div>
                                <b
                                  >{{ week_item_details.display_item_type }}
                                </b>
                              </div>
                              <div>
                                <b>{{ week_item_details.itemname }} </b>
                              </div>
                              <div>
                                <b>Qty:{{ week_item_details.qty }} </b>
                              </div>
                            </div>
                            <div>
                              <span>
                                <pre>{{
                                  week_item_details.item_description
                                }}</pre>
                              </span>
                              <div></div>
                            </div>
                          </div>

                          <!-- <span style="text-align: right; width: 50%;">
                                                    <a href="javascript:void(0)"
                                                        (click)="viewWeeklyItemDetail(week)">View Details</a>
                                                </span> -->
                          <!-- <pre> 
                                                                                <code>
                                                                                {{ week | json }}
                                                                                </code>
                                                                            </pre> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div #divJainAddToCart class="pt-3"></div> -->
              </div>
            </div>
            <!-- <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-edit"></i></span>
                                </div>
                                <textarea #addNote="ngModel" ngModel name="addNote" id="addNote" class="form-control"
                                    rows="3" placeholder="Add note here..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <label> Subscription start date</label><br>

                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-calendar"></i></span>
                                </div>
                                <input type="date" name="startDate" class="form-control" placeholder="Enter Date"
                                    onkeydown="return false" id="startDate" autocomplete="off" required>
                            </div>
                        </div>
                    </div>-->
            <div class="form-row pt-3">
              <div class="col-md-12 form-group">
                <div class="input-group">
                  <button
                    class="btn btn-danger btn-block"
                    (click)="resetAddToCart()"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
</div>
</div>
    <ngx-spinner
      bdcolor="rgb(255 255 255 / 80%)"
      size="medium"
      type="square-jelly-box"
      template="&nbsp;"
    >
      <div class="loader-box">
        <div class="icon">
          <i class="fas fa-utensils"></i>
        </div>
      </div>
      <!-- <p style="color: white;">Please Wait.</p> -->
    </ngx-spinner>
  
