export class ManageAddressModel {
    public useraddress_id: string;
    public deliveryarea: string;
    public address: string = '';
    public city: string = '';
    public pincode: string = '';
    public address_type: string;
    public firstname: string;
    public lastname: string;
    public country: string;
    public state: string;
    public phoneno: string;
    public latitude: string = '';
    public longitude : string = '';
    public location_id : string = '';
}