export class ProfileModel {
    public user_id: string;
    public firstname: string;
    public lastname: number;
    public phoneno: string;
    public email: string;
    public profile_image_url: string;
    public old_profile_image_url: string;
}

export class LocationModel {
    public zipcode: string;
    public locationemail: string;
    public display_name: number;
    public address1: string;
    public address2: string;
    public phoneno: string;
    public country: string;
    public state: string;
    public city: string;
}