<div class="osahan-home-page">
    <div class="bg-light" id="divMenuCategory">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-menus-page d-flex height-fill">
            <div class="px-3 pt-3 pb-1">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Menu Categories</h2>
            </div>
            <div class="category-section container-fluid">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" *ngFor="let menus of menuCategoryList">
                            <div class="cat-item px-1 py-3 animated bounceOutLeft"
                                style="width: 100%; display: inline-block;">
                                <a href="javascript:void(0)"
                                    class="bg-white rounded d-block p-2 text-center shadow d-flex align-items-center"
                                    (click)="getMenuItem(menus.category_id, menus.name)" tabindex="0">
                                    <div class="col-4">
                                        <img src="{{menus.image}}" class="img-fluid mb-2 menu-icon w-80 float-right"
                                            onerror="this.src='assets/img/logo/logo1.jpg';">
                                        <!-- <div class="fixed-bg"
                                            [ngStyle]="{ 'background-image': 'url(\'' + menus.image + '\')' }">
                                        </div> -->
                                    </div>
                                    <h5 class="m-0 col-8 float-left text-left"><b>{{menus.name}}</b></h5>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light" id="divMenuItem">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="cart-resize">
            <div class="bg-light border-bottom p-3">
                <a href="javascript:void(0);" class="text-primary font-weight-bold" (click)="backToMenuCategory()"><i
                        class="feather-chevron-left"></i>
                    Back</a>
            </div>
            <div class="most_popular p-3">
                <h4 class="font-weight-bold m-0 pb-3">{{lblCategoryName}}</h4>
                <div class="row">
                    <div class="col-lg-4 col-xs-12 col-md-4 pb-3" *ngFor="let items of menuItemList;let i = index">
                        <div
                            class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm grid-card">
                            <div class="list-card-image">
                                <a href="javascript:void(0);" class="star position-absolute"
                                    *ngIf="items.avg_rating != ''" (click)="getReviews(items)">
                                    <span class="badge badge-success">
                                        <i class="feather-star"></i>
                                        {{items.avg_rating}} ( {{items.total_review}} Reviews )
                                    </span>
                                </a>
                                <div class="member-plan position-absolute">
                                    <span class="badge badge-dark">
                                        {{lblCategoryName}}
                                    </span>
                                </div>
                                <div class="favourite-heart text-danger position-absolute">
                                    <img src="{{ items.types[0].image }}" style="height: 15px; width: 15px;"
                                        class="veg-dot-ss" style="background-color: white;">
                                </div>
                                <a href="javascript:void(0)" (click)="getItemDetails(items,i)">
                                    <!-- <img src="{{items.image_url.main_image}}" alt="{{items.image_url.main_image}}"
                                        style="height: 220px;" onerror="this.src='assets/img/logo/logo-jpg.jpg';"
                                        class="img-fluid item-img w-100"> -->
                                    <div class="fixed-bg"
                                        [ngStyle]="{ 'background-image': 'url(\'' + items.image_url.main_image + '\')' }">
                                    </div>
                                </a>
                            </div>
                            <div class="p-3 position-relative">
                                <div class="list-card-body">
                                    <div class="dish-contents d-flex">
                                        <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0 pb-2">
                                            <h6 class="mb-1 mt-1">
                                                <a href="javascript:void(0)" class="text-black"
                                                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap !important;"
                                                    (click)="getItemDetails(items,i)">
                                                    {{items.name}}
                                                </a>
                                            </h6>
                                        </div>
                                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 pb-2">
                                            <p class="text-gray mb-0 time">
                                                <span
                                                    class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss"
                                                    *ngIf="isPriceWithTax">
                                                    {{currencySymbol}}
                                                    {{items.display_inc_tax_price}}/-</span>
                                                <span
                                                    class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss"
                                                    *ngIf="!isPriceWithTax">
                                                    {{currencySymbol}}
                                                    {{items.price}}/-</span>
                                            </p>
                                        </div>
                                        <div class="col-12 col-lg-12 col-md-12 col-xs-12 col-sm-12 pl-0 pr-0">
                                            <p class="small mb-3" [innerHTML]="items.description"></p>
                                        </div>
                                        <div class="col-8 form-group mb-0 pl-0">
                                            <div class="custom-control custom-checkbox" *ngIf="items.is_jain == 1">
                                                <input type="checkbox" id="jainItem_{{items.item_id}}"
                                                    name="jainItem_{{items.item_id}}" class="custom-control-input"
                                                    [checked]="items.SelectIsJain" (change)="OnSelectJain(i)">
                                                <label for="jainItem_{{items.item_id}}"
                                                    class="custom-control-label pt-1"><strong>Jain</strong>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0">
                                            <button class="btn btn-primary btn-block add-btn"
                                                (click)="getItemDetails(items,i)" [disabled]="isOrderAvailable == 0">
                                                Add </button>
                                            <span class="small float-right pt-1 pr-0 col-12 text-right">
                                                <span *ngIf="items.isDisplayCustomizable == 1" class="txtCustomizable">
                                                    Customizable </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemUnitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false"
    style="height: 400px; top: 100px; overflow-y: hidden;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title" id="exampleModalLabel"><b>Item Unit: </b>{{lblItemName}}</h5> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="pt-2">
                    <div class="form-row">
                        <!-- <div class="col-md-6 form-group" *ngFor="let units of itemUnits; let i = index"> -->
                        <div class="input-group">
                            <!-- <button type="button" class="btn btn-primary btn-lg btn-block"
                                    (click)="getModifiers(i,units.modifiers)"
                                    [disabled]="isOrderAvailable == 0">{{units.unit_name}}</button> -->
                            <!-- <p style="text-align: center; color: grey;">
                                        Customizable
                                    </p> -->
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemAddToCart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>{{lblItemName}}</b>
                </h5>
                <button type="button" class="close" (click)="resetAddToCart()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #ModifierForm="ngForm" (ngSubmit)="addToCart()">
                    <div id="adons-options-block">
                        <div class="mx-auto w-100">
                            <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <!-- <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="height: 475px;"
                                    class="img-fluid w-100 p-3"> -->
                                <div class="fixed-bg"
                                    [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-6 pl-0 pr-0"><span
                                        class="badge badge-success">{{lblCategoryName}}</span></div>
                                <div class="col-6 text-right pl-0 pr-0">
                                    <div class="price-tag card-item-price">
                                        {{currencySymbol}}
                                        <span id="hp_final_cost" *ngIf="isPriceWithTax">{{lblItemRateWithTax}}</span>
                                        <span id="hp_final_cost" *ngIf="!isPriceWithTax">{{lblItemRate}}</span>/-
                                    </div>
                                </div>
                                <div class="col-12 pt-1 pl-0 pr-0">
                                    <p [innerHTML]="itemDetailDescription"></p>
                                </div>
                                <div class="col-12 pt-1 pb-1 pl-0 pr-0" *ngFor="let types of itemTypes">
                                    <span>
                                        <img src="{{ types.image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"><b>{{ types.name}}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
                                *ngIf="itemUnits != undefined && itemUnits.length > 1">
                                <div class="mb-0 col-md-12 form-group mb-3">
                                    <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                        <a *ngFor="let units of itemUnits; let i = index" id="units{{i}}"
                                            class="btn btn-outline-secondary btns-select "
                                            [ngClass]="{ 'active': i == 0 }"
                                            style="border-radius: 30px; margin-left: 10px;" href="javascript:void(0);"
                                            (click)="getModifiers(i,units.modifiers,temp_i)">
                                            <input type="radio" name="itemunitid{{i}}" data-val="0.00"
                                                class="form-check-input-addon" id="itemunitid{{i}}" value="{{i}}">
                                            <span *ngIf="isPriceWithTax"> {{units.unit_name}} ({{currencySymbol}}
                                                {{units.display_inc_tax_price}})</span>
                                            <span *ngIf="!isPriceWithTax">{{units.unit_name}} ({{currencySymbol}}
                                                {{units.price}})</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-8 form-group">
                                            <div class="input-group" style="height: 100%; line-height: 30px;">
                                                <span *ngIf="isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRateWithTax}})</span>
                                                <span *ngIf="!isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRate}})</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <span class="count-number float-right">
                                                <div class="input-group" style="text-align: right; display: block;">
                                                    <button type="button" class="btn-sm left dec btn btnCart"
                                                        id="decrease" value="Decrease Value"
                                                        (click)="countItemChange(0)">
                                                        <i class="feather-minus btnIcon"></i>
                                                    </button>
                                                    <input disabled min="1" max="50" value="1"
                                                        class="count-number-input" id="itemQuantity" type="text">
                                                    <button type="button" class="btn-sm right inc btn btnCart"
                                                        id="increase" value="Increase Value"
                                                        (click)="countItemChange(1)">
                                                        <i class="feather-plus btnIcon"></i>
                                                    </button>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div #divFormRow>
                                    </div>
                                </div>
                            </div>
                            <div #divJainAddToCart class="pt-3"></div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-edit"></i></span>
                                </div>
                                <textarea #addNote="ngModel" ngModel name="addNote" class="form-control" rows="3"
                                    placeholder="Add note here..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <button type="submit" class="btn btn-primary btn-block"
                                    [disabled]="isOrderAvailable == 0">Add {{currencySymbol}}
                                    {{lblTotalAmount}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade login-component ss-addon-modal" id="ItemDetailModal" role="dialog" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-block">
                    <button type="button" class="close modal-close" data-dismiss="modal">&times;</button>
                    <div id="adons-options-block">
                        <form id="addtocartform" method="post" autocomplete="false">
                            <div class=""><input type="hidden" id="selected_item_id" value="11340063"><input
                                    type="hidden" id="selected_item_price" value="190"><input type="hidden"
                                    id="selected_menu_id" value="784056"><input type="hidden" id="itemFrom"
                                    value="items"></div>
                            <div class="mx-auto w-100">
                                <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                    <!-- <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                        onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="height: 475px;"
                                        class="img-fluid w-100 p-3"> -->
                                    <div class="fixed-bg"
                                        [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                    </div>
                                </div>
                                <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                    <div class="col-6 pl-0 pr-0"><span
                                            class="badge badge-success">{{lblCategoryName}}</span></div>
                                    <div class="col-6 text-right pl-0 pr-0">
                                        <div class="price-tag card-item-price">
                                            {{currencySymbol}}
                                            <span id="hp_final_cost">{{itemDetailPrice}}</span>/-
                                        </div>
                                    </div>
                                    <h6 class="mb-1 font-weight-bold pt-2">{{itemDetailName}}</h6><input type="hidden"
                                        id="totalcost11340063" value="190">
                                    <div class="col-12 pt-1 pb-1 pl-0 pr-0">
                                        <p [innerHTML]="itemDetailDescription"></p>
                                    </div>
                                </div>
                                <div #divJainDetail>
                                </div>
                            </div>
                            <div class="p-0 border-0 py-1">
                                <div class="col-12 m-0 p-0"><button type="button" class="btn btn-primary add-btn w-100"
                                        (click)="getItemDetails(itemInformations,itemDetailIndex)"
                                        [disabled]="isOrderAvailable == 0">Add to Cart</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>