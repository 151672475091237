<div class="osahan-home-page">
    <div class="bg-light">
        <div class="osahan-vieworder height-fill">
            <div class="p-3">
                <div class="alert alert-info" role="alert">
                    <button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span><span
                            class="sr-only">Close</span></button>
                    Your order is saved.
                </div>
                <h2 class="font-weight-bold m-0 cart-resize with-title">{{orderNo}}</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div class="row ml-0 mr-0">
                            <div class="osahan-cart-item-profile bg-white rounded shadow BG-WHITE col-12 mb-3">
                                <div class="flex-column">
                                    <div class="row p-3 d-flex">
                                        <div class="col-12 img-icon-size pl-0 pr-0">
                                            <div
                                                class="order-status bg-white z-depth-1 pt-4 pb-4 mt-3 mb-3 text-center bdrradius col-12 pl-0 pr-0">
                                                <div class="order-progress">
                                                    <div class="steps-of-order-progress active">
                                                        <div
                                                            class="pt-3 step-container row align-items-top ml-1 mr-1 border-bottom">
                                                            <div class="col-2 p-0 mb-2">
                                                                <img src="https://raivatkitchen.com/assets/img/ordermessageicon/Received.png"
                                                                    class="order-status-icon" id="orderstatusicon">
                                                                <div class="badge status-badge mt-2 pl-3 pr-3 pb-1 pt-1"
                                                                    id="orderstatustag">Done</div>
                                                            </div>
                                                            <div class="col-10 text-left">
                                                                <h6 class="status-msg" id="orderstatusmessage">Your
                                                                    order is Preparing!</h6>
                                                                <p class="small mb-1" id="orderstatusdescription">
                                                                    Waiting for Food Ready.</p>
                                                                <span id="orderstatusbtn"><a href="tel:{{locationData.phoneno}}"
                                                                        class="mb-2 small btn btn-primary btn-sm d-none w-auto">
                                                                        Call Kitchen</a></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12">
                            <div class="flex-column">
                                <div #divOrderSummary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12"></div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="flex-column">
                            <button type="submit" class="btn btn-primary btn-lg btn-block" routerLink="/home" (click)="backToHome()">Back To Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>