import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { HomeService } from '../app/home/home.service';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationService } from '../app/_services/authentication.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'RaivatKitchen';

  @ViewChild('SignUpForm') signUpForm: NgForm;
  @ViewChild('LoginForm') loginForm: NgForm;
  @ViewChild('registrationOTPForm') RegOTPForm: NgForm;
  @ViewChild('MenuForm') menuForm: NgForm;
  @ViewChild('ChangePasswordForm') changePasswordForm: NgForm;
  @ViewChild('ForgotPasswordForm') forgotPasswordForm: NgForm;
  @ViewChild('OTPForm') OTPForm: NgForm;
  @ViewChild('ForgotchangePasswordForm') ForgotchangePasswordForm: NgForm;
  @ViewChild('LoginOTPForm') LoginOTPForm: NgForm;
  @ViewChild('divReviews') divReviews: ElementRef;

  countryList: any;
  userEmail: any;
  userPassword: any;

  cookieValue: any;
  cityList: any;
  locationList: any;
  locationId: any;
  data: LocationModel;
  forgotPassOTPResponse: any;

  lblItemName: string;
  lblItemRating: any;
  reviewList: Array<any> = [];
  timerOn = true;
  userToken: any;
  formTitle: any;
  url: any;

  isSubscription:number;
  emp_login: number;
  logintype: string | number;
  emailerrors: string;

  constructor(private toastr: ToastrService, private service: HomeService, private router: Router, private cookieService: CookieService, private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.data = new LocationModel();
    this.userEmail = "";
    this.userPassword = "";
    this.fillCountry();
    
    this.cookieValue = this.cookieService.get("location");

    if (this.cookieValue) {
      this.locationId = this.cookieValue;
      if (this.cookieService.get("currencySymbol")) {
        environment.currencySymbol = this.cookieService.get("currencySymbol");
      }
      if (this.cookieService.get("pgateway_types")) {
        environment.pgateway_types = JSON.parse(this.cookieService.get("pgateway_types"));
      }
      if (this.cookieService.get("roundoff")) {
        environment.roundoff = JSON.parse(this.cookieService.get("roundoff"));
      }
      this.logintype=this.cookieService.get("sms_gateway_enable")?this.cookieService.get("sms_gateway_enable"):0;
      // if(this.cookieService.get("emp_login") == "1"){
      //   document.getElementById("usersubscriptions").style.display = "none";
      //   document.getElementById("usersubscriptionorders").style.display = "none";
      //   document.getElementById("managesubscriptions").style.display = "none";
      // }
      // this.cookieService.set("resettingData", "1");
      this.setCurrencySymbol(this.locationId,false)
    } else {
      this.fillCity();
    }
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.show();
    // }, 5000);
    // this.spinner.show();
  }

  setCurrencySymbol(location_id, reCall) {
    var obj = {
      "location_id": location_id
    };
    // this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      var pgateway_types = [];
      if (response.status == "ok") {
        environment.currencySymbol = response.payload.currency.sign;
        pgateway_types = response.payload.pgateway_types;
        environment.pgateway_types = pgateway_types;
        environment.roundoff = response.payload.roundoff;
        this.cookieService.set("currencySymbol", response.payload.currency.sign);
        this.cookieService.set("pgateway_types", JSON.stringify(pgateway_types));
        this.cookieService.set("google_map_key", response.payload.google_map_key);
        this.cookieService.set("roundoff", response.payload.roundoff);
        this.cookieService.set("order_rating_type", response.payload.order_rating_type);
        this.cookieService.set("sms_gateway_enable",response.payload._sms_gateway_enable);
        this.cookieService.set("delivery_charge_applyon", response.payload.delivery_charge_applyon);
        this.cookieService.set("internet_charge_applyon", response.payload.internet_charge_applyon);
        this.cookieService.set("packing_charge_applyon", response.payload.packing_charge_applyon);
        this.cookieService.set("service_charge_applyon", response.payload.service_charge_applyon);
        if (typeof response.payload.display_price_inc_tax == undefined)
          response.payload.display_price_inc_tax = 0;
        this.cookieService.set("display_price_inc_tax", response.payload.display_price_inc_tax);
        if (response.payload.packingchargepostingtype == undefined)
          this.cookieService.set("packing_charge_posting_type", "0");
        else
          this.cookieService.set("packing_charge_posting_type", response.payload.packingchargepostingtype);

        if (response.payload.subscription_order_limit == undefined)
          this.cookieService.set("subscription_order_limit", "10");
        else
          this.cookieService.set("subscription_order_limit", response.payload.subscription_order_limit);

        if (response.payload.subscription_order_limit == undefined)
          this.cookieService.set("subscription", "0");
        else
          this.cookieService.set("subscription", response.payload.subscription);

        // no charge user cookie set
        if (response.payload.emp_login == undefined)
          this.cookieService.set("emp_login", "0");
        else
          this.cookieService.set("emp_login", response.payload.emp_login);

          this.emp_login=Number(this.cookieService.get('emp_login'));
        
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
      // if(this.cookieService.get("resettingData") != undefined && this.cookieService.get("resettingData") != "1" ){
      if (reCall)
        this.resetMenuForm();
      // }
      return true;
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
      return false;
    });
  }

  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  fillCountry() {
    var obj = {};

    // this.spinner.show();
    this.service.getCountry(obj).subscribe(response => {
      if (response.status == "ok") {
        this.countryList = response.payload;

        if (this.countryList) {

          this.data.countrycode = "91";
        }
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  fillCity() {
    var obj = {};
    // this.spinner.show();

    this.service.getCity(obj).subscribe(response => {
      if (response.status == "ok") {
        this.cityList = response.payload;

        if (this.cityList) {
          this.data.city = this.cityList[0];
        }

        this.fillLocation(this.cityList[0]);
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  fillLocation(strCity) {
    var obj = {
      "city": strCity
    };
    this.spinner.show();

    this.service.getLocation(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationList = response.payload;

        if (this.locationList) {
          this.data.location = this.locationList[0].location_id;

          if (this.cityList.length == 1 && this.locationList.length == 1) {
            this.setLocation(this.data);
            $('#MenuModal').modal('hide');
          } else {
            $('#MenuModal').modal('show');
          }
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  onChangeCity(event: Event) {
    let selectElementText = event.target['options']
    [event.target['options'].selectedIndex].text;

    this.fillLocation(selectElementText);
  }

  resetMenuForm() {
    if (this.cookieService.get("location")) {
      this.menuForm.resetForm();
      $('#MenuModal').modal('hide');
      // this.router.navigate(['/home']);
      window.location.href = "index.html";
    } else {
      this.toastr.warning("Select Location To Continue.");
    }
  }

  setLocation(location: LocationModel) {
    this.cookieService.set("location", location.location);
    this.setCurrencySymbol(location.location, true);
  }
  signUp1() {
    var formValues = this.signUpForm.controls;
    if (formValues.password.value != formValues.confirmpassword.value) {
      this.toastr.error('Password & Confirm Password Must Be Same');
      return;
    }
        // if (formValues.password.value != formValues.confirmpassword.value) {
    //   this.toastr.error('Password & Confirm Password Must Be Same');
    //   return;
    // }
    //pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    var emailRegx = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
    if (formValues.email.value != "" && !(emailRegx.test(formValues.email.value))) {
      this.toastr.error('Enter valid email');
      return
    }
    if (this.signUpForm.valid) {
      var obj = {
        "firstname": formValues.firstname.value,
        "lastname": formValues.lastname.value,
        "email": formValues.email.value,
        "country_code": formValues.countrycode.value,
        "phoneno": formValues.mobilenumber.value,
        "password": formValues.password.value,
        "confirmpassword": formValues.confirmpassword.value,
        "socialuserid": "",
        "socialusername": "",
        "social_type": "",
        "accept_terms": "1"
      };
      this.spinner.show();
      this.service.registerUser(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.userEmail = formValues.email.value;
          this.userPassword = formValues.password.value;
          this.userToken = response.payload.user_token;
          $("#SignUpForm").hide();
          var sms_gateway_enable = this.cookieService.get("sms_gateway_enable")
          if (sms_gateway_enable != undefined && sms_gateway_enable == "1") {
            $("#registrationOTPForm").show();
            this.timer(120);
          } else {
            this.resetForm();
            $('#LoginModal').modal('show');
          }
          // $("#registrationOTPForm").show();
          // this.timer(120);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  signUp() {
    var formValues = this.signUpForm.controls;
    // if (formValues.password.value != formValues.confirmpassword.value) {
    //   this.toastr.error('Password & Confirm Password Must Be Same');
    //   return;
    // }
    //pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"
    var emailRegx = new RegExp("^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
    if (formValues.email.value != "" && !(emailRegx.test(formValues.email.value))) {
      this.toastr.error('Enter valid email');
      return
    }

    if (this.signUpForm.valid) {
      var obj = {
        "firstname": formValues.firstname.value,
        "lastname": formValues.lastname.value,
        "email": formValues.email.value,
        "country_code": formValues.countrycode.value,
        "phoneno": formValues.mobilenumber.value,
        // "password": formValues.password.value,
        // "confirmpassword": formValues.confirmpassword.value,
        "socialuserid": "",
        "socialusername": "",
        "social_type": "",
        "accept_terms": "1",
        "location_id": this.locationId
      };

      this.spinner.show();
      this.service.registerUser(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);

          this.userEmail = formValues.email.value;
          // this.userPassword = formValues.password.value;
          this.userToken = response.payload.user_token;
          $("#SignUpForm").hide();

          if (this.cookieService.get("sms_gateway_enable") != undefined &&  this.cookieService.get("sms_gateway_enable") == "1") {
            $("#registrationOTPForm").show();
            this.timerOn = true;
            this.formTitle = " - OTP Verification";
            this.timer(60);
          } else {
            this.resetForm();
            $('#LoginModal').modal('show');
          }
          // $("#registrationOTPForm").show();
          // this.timer(120);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  timer(remaining) {
    var m = Math.floor(remaining / 60).toString();
    var s = (remaining % 60).toString();

    m = parseInt(m) < 10 ? '0' + m : m;
    s = parseInt(s) < 10 ? '0' + s : s;

    document.getElementById('timer').innerHTML = m + ':' + s;
    document.getElementById('logintimer').innerHTML = m + ':' + s;
    remaining -= 1;

    if (remaining >= 0 && this.timerOn) {
      setTimeout(() => {
        this.timer(remaining)
      }, 1000);
      return;
    }
    if (!this.timerOn) {
      return;
    }

    if (remaining <= 0 && this.timerOn) {
      this.toastr.error("OTP is expired.");
      $("#resendotpCancelbtnReg").focus();
      $("#resendotpCancelbtnLogin").focus();
    }

    $("#resendOTP").show();
    $("#resendLoginOTP").show();

    // $("#otpSubmitBtn").hide();
    // $("#btnloginOTPSubmit").hide();

    $("#resendotpCancelbtnReg").focus();
    $("#resendotpCancelbtnLogin").focus();
  }

  resendRegistrationOTP() {
    // $("#otpSubmitBtn").show();
    $("#resendOTP").hide();

    this.timerOn = true;
    this.timer(60);
    var obj = {
      "user_token": this.userToken,
      "location_id": this.locationId
    };

    this.spinner.show();
    this.service.resendRegistraionotp(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  verifyRegistrationOTP() {
    if (this.RegOTPForm.valid) {
      var obj = {
        "otp_token": this.userToken,
        "otp": this.RegOTPForm.controls.regotp.value,
        "location_id": this.locationId
      };
      this.spinner.show();
      this.service.verifyRegistrationOTP(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);

          var formValues = this.signUpForm.controls;
          this.userEmail = formValues.email.value;
          // this.userPassword = formValues.password.value;
          this.resetForm();
          // $('#LoginModal').modal('show');
          this.cookieService.set("user", response.payload.authUser.user_token);
          this.cookieService.set("userId", response.payload.user_id);
          this.cookieService.set("userEmail", response.payload.email);
          this.cookieService.set("userName", (response.payload.firstname + " " + response.payload.lastname));
          this.cookieService.set("firstName", response.payload.firstname);
          this.cookieService.set("lastName", response.payload.lastname);
          this.cookieService.set("phoneNo", response.payload.phoneno);
          location.reload(true);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    } else {
      this.toastr.error("Enter OTP");
    }
  }

  sendLoginOTP() {
    if (this.loginForm.valid) {
      var formValues = this.loginForm.controls;

      var obj = {
        "mobile_no": formValues.email.value,
        "location_id": this.locationId
      };
      this.spinner.show();
      this.service.mobileLogin(obj).subscribe(response => {
        if (response.status == "ok") {
          this.timerOn = true;
          this.timer(60);
          $("#LoginForm").hide();
          $("#LoginOTPForm").show();
          this.formTitle = " - OTP Verification";
          $("#resendLoginOTP").hide();
          // $("#btnloginOTPSubmit").show();
          this.toastr.success(response.message);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        if (err.error != undefined) {
          this.toastr.error(err.error.toString());
          if(err["payload"].is_not_registered){
            $('#LoginModal').modal('hide');
            $('#SignupPopup').modal('show');
            $("#mobilenumber").val(formValues.email.value);
            this.signUpForm.controls['mobilenumber'].setValue(formValues.email.value);

          }

        } else {
          this.toastr.error(err.toString());
        }
        this.spinner.hide();
      });
    }
  }

  resendLoginOTP() {
    var formValues = this.loginForm.controls;

    var obj = {
      "mobile_no": formValues.email.value,
      "location_id": this.locationId
    };
    this.spinner.show();
    this.service.mobileResendOTP(obj).subscribe(response => {
      if (response.status == "ok") {
        // $("#btnloginOTPSubmit").show();
        $("#resendLoginOTP").hide();
        this.timerOn = true;
        this.timer(60);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  login1() {
    var formValues = this.loginForm.controls;
    console.log('formValues',formValues);
    var email = formValues.email.value;
    var emailRegx = new RegExp("^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
    var mobileRegx = new RegExp("^[0-9]*$");
    if (email != "" && !(emailRegx.test(email) || (mobileRegx.test(email) && email.length > 6 && email.length < 12))) {
      this.emailerrors = "Enter valid email or mobile no.";
      return
    }
    this.emailerrors = "";
    if (this.loginForm.valid) {
      var obj = {
        "email": formValues.email.value,
        "password": formValues.password.value,
        "device_id": btoa(window.navigator.appCodeName)
      };
      this.spinner.show();
      this.service.login1(obj).subscribe(response => {
        if (response.status == "ok") {
          this.cookieService.set("user", response.payload.authUser.user_token);
          this.cookieService.set("userId", response.payload.user_id);
          this.cookieService.set("userEmail", response.payload.email);
          this.cookieService.set("userName", (response.payload.firstname + " " + response.payload.lastname));
          this.cookieService.set("firstName", response.payload.firstname);
          this.cookieService.set("lastName", response.payload.lastname);
          this.cookieService.set("phoneNo", response.payload.phoneno);
          this.toastr.success(response.message);
          this.resetForm1();
          location.reload(true);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }
  resetForm1() {
    this.signUpForm.resetForm();
    this.loginForm.resetForm();
    this.RegOTPForm.resetForm();
    this.timerOn = false;
    $('#LoginModal').modal('hide');
    $('#SignupPopup').modal('hide');
    $('#registrationOTPForm').modal('hide');
    $("#SignUpForm").show();
    $("#registrationOTPForm").hide();
    // this.data.countrycode = "91";
    if (this.router.url == "/sharefeedback") {
      window.location.href = "index.html";
    }
  }


  login() {
    var formValues = this.loginForm.controls;
    var OTPformValues = this.LoginOTPForm.controls;

    if (this.LoginOTPForm.valid) {
      var obj = {
        "otp": OTPformValues.loginOTP.value,
        // "password": formValues.password.value,
        "mobile_no": formValues.email.value,
        "location_id": this.locationId
      };
      this.spinner.show();
      this.service.login(obj).subscribe(response => {
        if (response.status == "ok") {
          this.cookieService.set("user", response.payload.authUser.user_token);
          this.cookieService.set("userId", response.payload.user_id);
          this.cookieService.set("userEmail", response.payload.email);
          this.cookieService.set("userName", (response.payload.firstname + " " + response.payload.lastname));
          this.cookieService.set("firstName", response.payload.firstname);
          this.cookieService.set("lastName", response.payload.lastname);
          this.cookieService.set("phoneNo", response.payload.phoneno);
          this.toastr.success(response.message);

          this.resetForm();
         this.url= $(location).attr('hash');
          //  location.reload();
                                  // <<<---using ()=> syntax
          if(this.url=='#/managesubscriptions' || this.isSubscription==1)
          {
            
            this.router.navigate(['/managesubscriptionsdetail']).then(() => {
              window.location.reload();
            });
          
          }
          else
          {
            location.reload(true);
          }
      
         
          
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  resetForm() {
    this.signUpForm.resetForm();
    this.loginForm.resetForm();
    this.RegOTPForm.resetForm();
    this.LoginOTPForm.resetForm();
    this.timerOn = false;
    this.formTitle = "";

    $('#LoginModal').modal('hide');
    $('#SignupPopup').modal('hide');
    $('#registrationOTPForm').modal('hide');

    $("#SignUpForm").show();
    $("#registrationOTPForm").hide();

    $("#LoginForm").show();
    $("#LoginOTPForm").hide();

    this.fillCountry();

    if (this.router.url == "/sharefeedback") {
      window.location.href = "index.html";
    }
  }

  logout(intStatus) {
    if (intStatus == 1) {

      var obj = {
        "user_token": this.cookieService.get("user"),
        "location_id": this.locationId
      };

      this.spinner.show();
      this.service.logout(obj).subscribe(response => {
        if (response.status == "ok") {
          this.authenticationService.logout();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    } else {
      $('#LogoutModal').modal('hide');
    }
  }

  ChangePassword() {
    var formValues = this.changePasswordForm.controls;

    if (formValues.newpassword.value != formValues.confirmnewpassword.value) {
      this.toastr.error('New Password & Confirm New Password Must Be Same');
      return;
    }

    if (this.changePasswordForm.valid) {
      var obj = {
        "user_id": this.cookieService.get("userId"),
        "oldpassword": formValues.currentpassword.value,
        "newpassword": formValues.newpassword.value,
        "reenterpassword": formValues.confirmnewpassword.value,
        "location_id": this.locationId
      };

      this.spinner.show();
      this.service.changePassword(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);

          this.resetChangePasswordForm();
          this.logout(1);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  resetChangePasswordForm() {
    this.changePasswordForm.resetForm();

    $('#ChangePasswordModal').modal('hide');
  }

  sendOTP() {
    this.forgotPassOTPResponse = null;
    var emailRegx = new RegExp("^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$");
    var mobileRegx = new RegExp("^[0-9]*$");
    var email = this.forgotPasswordForm.controls.forgotemail.value

    if (email != "" && !(emailRegx.test(email) || (mobileRegx.test(email) && email.length > 6 && email.length < 12))) {
      this.toastr.error("Enter valid email or mobile no.");
      return
    }
    if (this.forgotPasswordForm.valid) {
      var obj = {
        "email": this.forgotPasswordForm.controls.forgotemail.value,
        "location_id": this.locationId
      };

      this.spinner.show();
      this.service.forgotpassword(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.forgotPassOTPResponse = response.payload;
          document.getElementById("divForgotPassword").style.display = "none";
          document.getElementById("divOTP").style.display = "block";
          this.startTimer();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  startTimer() {
    var timer2 = "03:00";
    $('#divResendPass').hide();
    $('.countdown').show();
    $('#divTimer').show();

    //intercal for seconds
    var sec = "";
    var min = "";
    var interval = setInterval(function () {
      //timer will be [hour, minute, second]
      var timer = timer2.split(':');
      var minutes = parseInt(timer[0], 10);
      var seconds = parseInt(timer[1], 10);

      if (minutes == 0 && seconds == 0) {
        $('.countdown').hide();
        $('#divTimer').hide();
        $('#divResendPass').show();
        clearTimeout(interval);
        return
      }
      //reduce second by one
      --seconds;
      //calculate new minute and hours
      minutes = (seconds < 0) ? --minutes : minutes;

      seconds = (seconds < 0) ? 59 : seconds;
      //seconds = (seconds < 10) ? parseInt(('0' + seconds)) : seconds;
      sec = seconds.toString();

      minutes = (minutes < 0) ? 59 : minutes;
      //minutes = (minutes < 10) ? parseInt(('0' + minutes))  : minutes;
      min = minutes.toString();

      if (seconds < 10)
        sec = '0' + seconds;
      if (minutes < 10)
        min = '0' + minutes;

      timer2 = min + ':' + sec;
      $('.countdown').html(timer2);

    }, 1000);
  }

  resetForgotPasswordForm() {
    this.forgotPasswordForm.resetForm();
    this.OTPForm.resetForm();

    $('#ForgotPasswordModal').modal('hide');
    $('#LoginModal').modal('show');
  }

  resendOTP() {
    var obj = {
      "email": this.forgotPasswordForm.controls.forgotemail.value,
      "location_id": this.locationId
    };
    this.spinner.show();
    this.service.resendforgototp(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        this.forgotPassOTPResponse = response.payload;
        this.startTimer()
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  verifyOTP() {
    if (this.OTPForm.valid) {

      if (this.forgotPassOTPResponse["otp"] != this.OTPForm.controls.otp.value) {
        this.toastr.error("Enter correct OTP")
        return
      }

      var obj = {
        "user_id": this.forgotPassOTPResponse["userid"],
        "otp": this.OTPForm.controls.otp.value,
        "location_id": this.locationId
      };
      this.spinner.show();
      this.service.verifyOTP(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.forgotPassOTPResponse["token"] = response.payload.token;
          document.getElementById("divOTP").style.display = "none";
          document.getElementById("ForgotchangePasswordForm").style.display = "block";
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  forgotChangePassword() {
    if (this.ForgotchangePasswordForm.valid) {
      if (this.ForgotchangePasswordForm.controls.newpasswordChange.value !== this.ForgotchangePasswordForm.controls.newpasswordconfirm.value) {
        this.toastr.error('Password & Confirm Password Must Be Same');
        return;
      }

      var obj = {
        "password": this.ForgotchangePasswordForm.controls.newpasswordChange.value,
        "confirmpassword": this.ForgotchangePasswordForm.controls.newpasswordconfirm.value,
        "token": this.forgotPassOTPResponse["token"],
        "location_id": this.locationId
      };

      this.spinner.show();
      this.service.resetpassword(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          document.getElementById("ForgotchangePasswordForm").style.display = "none";
          document.getElementById("divForgotPassword").style.display = "block";
          $('#ForgotPasswordModal').modal('hide');
          $('#LoginModal').modal('show');
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  forgotPass() {
    document.getElementById("divForgotPassword").style.display = "block";
    document.getElementById("ForgotchangePasswordForm").style.display = "none";
    document.getElementById("divOTP").style.display = "none";
  }

  // Start --> Review Modal
  getReviews(itemList) {
    this.lblItemName = itemList.name + "(" + itemList.default_unitname + ")";
    this.lblItemRating = parseFloat(itemList.total_review);

    $("#review").rating({
      "stars": 5,
      "half": true,
      "value": itemList.avg_rating,
      "readonly": true,
      "click": function (e) {
      }
    });

    var obj = {
      "item_id": itemList.item_id,
      "location_id": this.locationId
    };

    this.spinner.show();
    this.service.getReviewList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.reviewList = response.payload;
        $('#SingleProductRating').modal('show');
        this.setReviewsUI(this.reviewList);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  setReviewsUI(reviews) {
    this.divReviews.nativeElement.innerHTML = "";

    for (var n = 0; n < reviews.length; n++) {
      const divReview: HTMLParagraphElement = this.renderer.createElement('div');
      divReview.setAttribute("class", "review-item shadow bg-white p-3 pl-4 pr-4 mb-2");
      this.renderer.appendChild(this.divReviews.nativeElement, divReview);

      // // Row Div
      const divRow: HTMLParagraphElement = this.renderer.createElement('div');
      divRow.setAttribute("class", "row");
      this.renderer.appendChild(divReview, divRow);

      const divName: HTMLParagraphElement = this.renderer.createElement('div');
      divName.setAttribute("class", "reviwer-name col-6");
      divName.innerHTML = reviews[n].name;
      this.renderer.appendChild(divRow, divName);

      // // Voting Div
      const divVoting: HTMLParagraphElement = this.renderer.createElement('div');
      divVoting.setAttribute("class", "voting-count");
      this.renderer.appendChild(divReview, divVoting);

      const divRating: HTMLParagraphElement = this.renderer.createElement('div');
      divRating.setAttribute("class", "rating ratings-highlight single-person-rating");
      this.renderer.appendChild(divVoting, divRating);

      const divStarDynamic: HTMLParagraphElement = this.renderer.createElement('div');
      divStarDynamic.setAttribute("id", "review" + n);
      this.renderer.appendChild(divRating, divStarDynamic);

      // // Text Div
      const divText: HTMLParagraphElement = this.renderer.createElement('div');
      divText.setAttribute("class", "review-text");
      divText.innerHTML = (reviews[n].description != "" ? reviews[n].description : reviews[n].title);
      this.renderer.appendChild(divReview, divText);

      $("#review" + n).rating({
        "stars": 5,
        "half": true,
        "value": reviews[n].rating,
        "readonly": true,
        "click": function (e) {
        }
      });
    }
  }

  isNumberKey(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showhidepassword(cls) {
    var allInputs = $(".viewpass").attr('type');
    if (allInputs == "password")
      allInputs = "text"
    else
      allInputs = "password"
    $("." + cls).attr("type", allInputs);
  }
  // End --> Review Modal
}

export class LocationModel {
  public city: string;
  public location: string;
  public countrycode: string;
}