import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlaceOrderService {

  constructor(private http: HttpClient) { }
  
  updatePaymentStatus(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/transaction/updatepaymentstatus?${environment.queryStringData}`, data);
  }
  
  getDeliveryCharge(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/getdeliverycharge?${environment.queryStringData}`, data);
  }

}
