<div class="osahan-menu-fotter fixed-bottom bg-white px-3 py-2 text-center">
    <div class="row">
        <div class="col" id="homeI">
            <!-- selected -->
            <a href="index.html" id="home" class="text-dark small font-weight-bold text-decoration-none" (click)="setMenuFocus('home')">
                <!-- text-primary -->
                <p class="h4 m-0"><i class="feather-home"></i></p>
                Home
            </a>
        </div>
        <div class="col" id="menucategoryI">
            <a href="javascript:void(0);" id="menucategory"
                class="text-dark small font-weight-bold text-decoration-none" (click)="setMenuFocus('menucategory')">
                <p class="h4 m-0"><i class="feather-map-pin"></i></p>
                Menu
            </a>
        </div>
        <div class="col bg-white rounded-circle mt-n4 px-3 py-2">
            <div class="bg-primary rounded-circle mt-n0 shadow bg-primary-blue">
                <span id="totalcartitem" class="cart-pop">{{totalItemsInCart}}</span>
                <a href="javascript:void(0)" class="text-white small font-weight-bold text-decoration-none" routerLink="/checkout">
                    <i class="feather-shopping-cart"></i>
                </a>
            </div>
        </div>

        <div class="col" id="footerOfferI" style="display: none;">
            <a href="javascript:void(0)" id="offerlist"
                class="text-dark small font-weight-bold text-decoration-none" (click)="setMenuFocus('offerlist')">
                <p class="h4 m-0"><i class="feather-heart"></i></p>
                Offers
            </a>
        </div>
        <div class="col" id="footerHistoryI" style="display: none;">
            <a href="javascript:void(0)" id="orderhistory"
                class="text-dark small font-weight-bold text-decoration-none" (click)="setMenuFocus('orderhistory')">
                <p class="h4 m-0"><i class="feather-file-text"></i></p>
                History
            </a>
        </div>
        <div class="col" id="footerSignupI" style="display: none;">
            <a href="javascript:void(0);" id="footerSignupT" data-toggle="modal" data-target="#LoginModal"
                class="text-dark small font-weight-bold text-decoration-none">
                <p class="h4 m-0"><i class="feather-user"></i></p>
                Login
            </a>
        </div>
        <div class="col" id="footerProfileI" style="display: none;">
            <a href="javascript:void(0);" id="profile"
                class="text-dark small font-weight-bold text-decoration-none" (click)="setMenuFocus('profile')">
                <p class="h4 m-0"><i class="feather-user"></i></p>
                Profile
            </a>
        </div>
    </div>
</div>
