import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from '../_services/sharedServices.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.css']
})
export class MenuFooterComponent implements OnInit {
  clickEventsubscription:Subscription;

  cookieValue: any;
  totalItemsInCart: any;

  constructor(private cookieService: CookieService, private sharedService: SharedService, private spinner: NgxSpinnerService, private router: Router) {
    this.clickEventsubscription = this.sharedService.getClickEvent().subscribe(() => {

      if (localStorage.getItem(this.cookieService.get("location") + "_items")) {
        this.setCartTotal(JSON.parse(localStorage.getItem(this.cookieService.get("location") + "_items")));
      } else {
        this.totalItemsInCart = 0;
      }
    })
  }

  ngOnInit() {
    this.spinner.show();
    this.totalItemsInCart = 0;
    this.cookieValue = this.cookieService.get("user");
    if (this.cookieValue) {
      document.getElementById("footerOfferI").style.display = "none";
      document.getElementById("footerSignupI").style.display = "none";

      document.getElementById("footerHistoryI").style.display = "block";
      document.getElementById("footerProfileI").style.display = "block";
    } else {
      document.getElementById("footerOfferI").style.display = "block";
      document.getElementById("footerSignupI").style.display = "block";

      document.getElementById("footerHistoryI").style.display = "none";
      document.getElementById("footerProfileI").style.display = "none";
    }

    if (localStorage.getItem(this.cookieService.get("location") + "_items")) {
      this.setCartTotal(JSON.parse(localStorage.getItem(this.cookieService.get("location") + "_items")));
    }

    // // For Set FOcus On Menu On Load Of Page
    // this.router.events.subscribe(val => {
    //   this.setMenuFocus(this.router.url);
    // });
    this.spinner.hide();
  }

  setCartTotal(cartItem) {
    this.spinner.show();
    var itemAddCart = [];
    itemAddCart = cartItem;

    if (itemAddCart) { 
      var totalCart = 0;
      for (var i = 0; i < itemAddCart.length; i++) {
        totalCart += parseInt(itemAddCart[i].product_quantity);
      }
      this.totalItemsInCart = totalCart;
    } else {
      this.totalItemsInCart = 0;
    }
    this.spinner.hide();
  }

  setMenuFocus(selectedMenuName) {
    // $("#homeI").removeClass("selected");
    // $("#home").removeClass("text-primary");

    // $("#menucategoryI").removeClass("selected");
    // $("#menucategory").removeClass("text-primary");

    // $("#footerOfferI").removeClass("selected");
    // $("#offers").removeClass("text-primary");

    // $("#footerHistoryI").removeClass("selected");
    // $("#orderhistory").removeClass("text-primary");

    // $("#footerProfileI").removeClass("selected");
    // $("#profile").removeClass("text-primary");

    if (selectedMenuName == "home") {
      // $("#homeI").addClass("selected");
      // $("#home").addClass("text-primary");
      this.router.navigate(['/home']);
    }

    if (selectedMenuName == "menucategory") {
      // $("#menucategoryI").addClass("selected");
      // $("#menucategory").addClass("text-primary");
      this.router.navigate(['/menucategory']);
    }

    if (selectedMenuName == "offerlist") {
      // $("#footerOfferI").addClass("selected");
      // $("#offerlist").addClass("text-primary");
      this.router.navigate(['/offers']);
    }

    if (selectedMenuName == "orderhistory") {
      // $("#footerHistoryI").addClass("selected");
      // $("#orderhistory").addClass("text-primary");
      this.router.navigate(['/orderhistory']);
    }

    if (selectedMenuName == "profile") {
      // $("#footerProfileI").addClass("selected");
      // $("#profile").addClass("text-primary");
      this.router.navigate(['/profile']);
    }
  }
}
