import { Component, ViewChild, OnInit, Renderer2, ElementRef } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { HomeService } from '../home/home.service';
import { environment } from 'src/environments/environment';
import { CheckoutService } from '../checkout/checkout.service';
declare var $: any;

@Component({
  selector: 'app-manage-orders',
  templateUrl: './manage-orders.component.html',
  styleUrls: ['./manage-orders.component.css']
})
export class ManageOrdersComponent implements OnInit {
  @ViewChild('OrderDetailForm') orderDetailForm: NgForm;
  @ViewChild('OrderReviewForItemsForm') OrderReviewForItemsForm: NgForm;
  @ViewChild('divItems') divItems: ElementRef;
  isReviewAvailableforitems: number;
  itemlist: any;
  itemreviews: any[];
  review_type: any;
  reviewItemData: any[];
  serviceCharge: any;
  internetCharge: any;

  constructor(private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService, private service: HomeService, private cookieService: CookieService,
    private checkoutService: CheckoutService, private renderer: Renderer2) { }

  currencySymbol: any;
  orderList: any;
  orderDetail: any;
  orderItemList: any;
  orderItemTaxes: any;
  orderReview: any;
  totalOrders: any;


  order_id: any;
  invoiceId: any;
  orderDate: any;
  pickupDate: any;
  orderTotal: any;
  itemTotal: any;
  discountAmount: any;
  packingAmount: any;
  deliveryAmount: any;
  paymentMode: any;

  isReviewAvailable: any;
  reviewUser: any;
  reviewDate: any;
  reviewTitle: any;
  reviewDescription: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  ngOnInit() {
    this.currencySymbol = environment.currencySymbol;
    this.getOrderHistory();
    
    this.itemlist;
    this.review_type=this.cookieService.get("order_rating_type");
    //console.log('this.review_type',this.review_type)
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
  }

  getOrderHistory() {
    var obj = {
      "limit": "0",
      "offset": "0",
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.getOrderHistory(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderList = response.payload;
        this.totalOrders = this.orderList.length;
        // this.orderList.length = 15;
        // this.totalOrders = 15;
        
        $('#divNoOrders').hide();
      } else {
        $('#divNoOrders').show();
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getOrderDetail(orderId) {
    var obj = {
      "external_id": orderId,
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.getOrderDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderDetail = response.payload;
        this.orderItemList = response.payload.items;
        this.itemlist = response.payload.items;
        //console.log('orderItemList',this.orderItemList);
        this.orderItemTaxes = response.payload.meta._taxes;
        // this.getOrderReviewForItemsSuccess();
        // this.invoiceId = this.orderDetail.order_id + "_" + this.orderDetail.external_id;
        this.invoiceId = this.orderDetail.order_id;
        this.orderDate = this.orderDetail.order_datetime;
        this.pickupDate = this.orderDetail.order_serve_date;
        this.orderTotal = this.orderDetail.orderAmt;
        this.itemTotal = this.orderDetail.order_product_amount;
        this.discountAmount = this.orderDetail.order_total_discount;
        this.packingAmount = this.orderDetail.packingcharge_amt;
        this.deliveryAmount = this.orderDetail.deliverycharge_amt;
        this.serviceCharge = this.orderDetail.servicecharge_amt;
        this.internetCharge = this.orderDetail.internetcharge_amt;
        this.paymentMode = this.orderDetail.payment_note;

        this.setOrderItemsUI(this.orderItemList);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
      this.getOrderReview(orderId);
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
    
  }

  getOrderReview(orderId ) {
    var obj = {
      "order_id": orderId,
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.getOrderReview(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderReview = response.payload;
        this.order_id = orderId;
        this.reviewItemData=[];
        if (this.orderReview.length > 0) {
          if(this.review_type==0)
          {
            $("#orderdiv").css("display","");
            $("#itemdiv").css("display","none");
            this.isReviewAvailable = 1;
            this.reviewUser = this.orderReview[0].name;
            this.reviewDate = this.orderReview[0].added_on;
            this.reviewTitle = this.orderReview[0].title;
            this.reviewDescription = this.orderReview[0].description;
            var t= this.getOrderReviewSuccess1();
          }
          else if(this.review_type == 1)
          {
            $("#orderdiv").css("display","none");
            $("#itemdiv").css("display","");
            this.isReviewAvailableforitems=1;
            for(let j=0;j < this.orderReview.length; j++)
            {
              //console.log('order',this.orderReview);
              this.reviewItemData[j]={
                "itemname":this.orderReview[j].itemname,
                "reviewdate":this.orderReview[j].added_on,
                "reviewtitle":this.orderReview[j].title,
                "reviewdescription":this.orderReview[j].description,
                "itemid":this.orderReview[j].z_productid_fk,
              } ;
                setTimeout(()=>{                           // <<<---using ()=> syntax
                  $("#review_"+this.orderReview[j].z_productid_fk).rating({
                    "stars": 5,
                    "half": true,
                    "value":this.orderReview[j].rating,
                    "readonly": true,
                    "click": function (e) {
                    }
                  });
              }, 300);
            }
          }
          $('#OrderDetail').modal('show');
        } else {

          if(this.review_type==0)
          {
            $("#orderdiv").css("display","");
            $("#itemdiv").css("display","none");
            this.isReviewAvailable = 0;
            var t= this.getOrderReviewSuccess();
            $("#setReviewCount").val(0);
          }else if(this.review_type==1)
          {
            $("#orderdiv").css("display","none");
            $("#itemdiv").css("display","");
            this.isReviewAvailableforitems=0;
            this.getOrderReviewForItemsSuccess();
          }
          $('#OrderDetail').modal('show');
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getOrderReviewSuccess() {
    $("#review2").rating({
      "stars": 5,
      "half": true,
      "click": function (e) {
        $("#setReviewCount").val(e.stars);
      }
    });

    if ($("#review2").val() == undefined) {
      setTimeout(() => {  this.getOrderReviewSuccess() }, 100);
    }
    return true
  }
  getOrderReviewSuccess1(){
    $("#review1").rating({
      "stars": 5,
      "half": true,
      "value": this.orderReview[0].rating,
      "readonly": true,
      "click": function (e) {
      }
    });
    if ($("#review1").val() == undefined) {
      setTimeout(() => {  this.getOrderReviewSuccess1() }, 100);
    }
    return true
  } 
  getOrderReviewForItemsSuccess() {
    // if($(".reviewitems2").val()=='')
    // {
    //   $(".reviewitems2").val(undefined);
    // }
    //console.log('here',this.orderItemList);
    for(let i=0;i<this.orderItemList.length;i++)
    {
      //console.log('here enter',this.orderItemList[i].z_itemid_fk)
    $("#Review_"+this.orderItemList[i].z_itemid_fk).rating({
      "stars": 5,
      "half": true,
      "click": function (e) {
        $("#setReviewCount_"+e.event.currentTarget.id).val(e.stars);
      }
    });
    
    if ($("#Review_"+this.orderItemList[i].z_itemid_fk).val() == undefined ) {
      setTimeout(() => {  this.getOrderReviewForItemsSuccess() }, 100);
    }
  }
    return true
  }

  getOrderReviewForItemsSuccess1(){
    $(".reviewitems1").rating({
      "stars": 5,
      "half": true,
      "value": 0,
      "readonly": true,
      "click": function (e) {
      }
    });
    if ($(".reviewitems1").val() == undefined) {
      setTimeout(() => {  this.getOrderReviewForItemsSuccess1() }, 100);
    }
    return true
  }

  submitReview() {
    var formValues = this.orderDetailForm.controls;

    if (this.orderDetailForm.valid) {

      if ($('#setReviewCount').val() <= 0) {
        this.toastr.error("Please give rating");
        return;
      }

      var obj = {
        "email": (this.cookieService.get("userEmail") ? this.cookieService.get("userEmail") : ""),
        "name": (this.cookieService.get("userName") ? this.cookieService.get("userName") : ""),
        "title": (formValues.title.value ? formValues.title.value : ""),
        "rating": $('#setReviewCount').val(),
        "description": formValues.description.value,
        "order_id": this.order_id,
        "location_id": this.cookieService.get("location")
      };

      this.spinner.show();
      this.service.saveOrderReview(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.closeOrderDetailModal();
          this.getOrderHistory();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }
  submitReviewforItems() {
    var formValues = this.OrderReviewForItemsForm.controls;
    this.itemreviews=[];
    //console.log(formValues);
    if (this.OrderReviewForItemsForm.valid) {
      //console.log(this.itemlist);
      for(let i=0;i<this.itemlist.length;i++)
      {
        //console.log($('#setReviewCount_Review_'+this.itemlist[i].z_itemid_fk).val());
        if ($('#setReviewCount_Review_'+this.itemlist[i].z_itemid_fk).val() <= 0) {
          this.toastr.error("Please give rating for "+this.itemlist[i].product_name);
          return;
        }
      }
      for(let i=0;i<this.itemlist.length;i++)
      {
        //console.log(formValues["description_"+this.itemlist[i].z_itemid_fk+""].value !='');
        if(formValues["description_"+this.itemlist[i].z_itemid_fk+""].value !='' && $('#setReviewCount_Review_'+this.itemlist[i].z_itemid_fk).val() >= 0)
        {
          this.itemreviews[i]={
            "description":formValues["description_"+this.itemlist[i].z_itemid_fk+""].value,
            "item_id":this.itemlist[i].orderitem_id,
            "rating":$('#setReviewCount_Review_'+this.itemlist[i].z_itemid_fk).val()
          }
        }
        
      }
      //console.log("this.itemreviews", this.itemreviews);
      var obj = {
        "email": (this.cookieService.get("userEmail") ? this.cookieService.get("userEmail") : ""),
        "name": (this.cookieService.get("userName") ? this.cookieService.get("userName") : ""),
        "title": "",
        "itemreviews": this.itemreviews,
        "order_id": this.order_id,
        "location_id": this.cookieService.get("location")
      };
      //console.log("obj", obj);
      this.spinner.show();
      this.service.saveOrderReviewForItem(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.closeOrderDetailModal();
          this.getOrderHistory();
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        //console.log(err);
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  closeOrderDetailModal() {
    this.orderDetailForm.resetForm();
    this.OrderReviewForItemsForm.resetForm();
    $("#OrderDetail").modal("hide");
  };

  setOrderItemsUI(orderItems) {
    this.divItems.nativeElement.innerHTML = null;
    var finalHTML = "";
    var subTotal = 0;


    // // For Items
    for (var n = 0; n < orderItems.length; n++) {
      finalHTML += "<div class='col-md-12'> " +
        "    <div class='row'> " +
        "        <div class='col-md-6'> " +
        "            <div style='height: 100%; line-height: 30px;'> " +
        "                <li style='list-style-type: circle;'>" + orderItems[n].product_name + (orderItems[n].is_jain == 1 ? " <span style='color: #d9b300;font-weight: 700;'>(Jain)</span> " : '') + " (" + orderItems[n].product_weight + ")</li> " +
        "            </div> " +
        "        </div> " +
        "        <div class='col-md-3'> " +
        "            <div style='text-align: right; display: block;'> " +
        "               <p>" + orderItems[n].product_quantity + " X " + orderItems[n].product_price + "</p> " +
        "            </div> " +
        "       </div> " +
        "        <div class='col-md-3'> " +
        "           <div style='text-align: right; display: block;'> " +
        "                <p>" + this.currencySymbol + " " + (orderItems[n].product_quantity * orderItems[n].product_price) + "</p> " +
        "           </div> " +
        "        </div> " +
        "   </div> " +
        "</div>";
      subTotal += (orderItems[n].product_quantity * orderItems[n].product_price);

      if (orderItems[n].modifiers) {
        // // For Modifiers
        for (var m = 0; m < orderItems[n].modifiers.length; m++) {
          finalHTML += "<div class='col-md-12'> " +
            "    <div class='row'> " +
            "        <div class='col-md-6'> " +
            "            <div style='padding-left: 20px;'> " +
            "                <p>" + orderItems[n].modifiers[m].name + "</p> " +
            "            </div> " +
            "        </div> ";
          if (!orderItems[n].modifiers[m].items) {
            finalHTML += "        <div class='col-md-3'> " +
              "            <div style='text-align: right; display: block;'> " +
              "               <p>" + orderItems[n].modifiers[m].quantity + " X " + orderItems[n].modifiers[m].price + "</p> " +
              "            </div> " +
              "       </div> " +
              "        <div class='col-md-3'> " +
              "           <div style='text-align: right; display: block;'> " +
              "                <p>" + this.currencySymbol + " " + (orderItems[n].modifiers[m].quantity * orderItems[n].modifiers[m].price) + "</p> " +
              "           </div> " +
              "        </div> ";

            subTotal += (orderItems[n].modifiers[m].quantity * orderItems[n].modifiers[m].price);
          }
          finalHTML += "   </div> " +
            "</div>";

          if (orderItems[n].modifiers[m].items) {
            // // For Sub Modifiers
            for (var p = 0; p < orderItems[n].modifiers[m].items.length; p++) {
              finalHTML += "<div class='col-md-12'> " +
                "    <div class='row'> " +
                "        <div class='col-md-6'> " +
                "            <div style='padding-left: 40px;'> " +
                "                <p>" + orderItems[n].modifiers[m].items[p].name + "</p> " +
                "            </div> " +
                "        </div> " +
                "        <div class='col-md-3'> " +
                "            <div style='text-align: right; display: block;'> " +
                "               <p>" + orderItems[n].modifiers[m].items[p].quantity + " X " + orderItems[n].modifiers[m].items[p].price + "</p> " +
                "            </div> " +
                "       </div> " +
                "        <div class='col-md-3'> " +
                "           <div style='text-align: right; display: block;'> " +
                "                <p>" + this.currencySymbol + " " + (orderItems[n].modifiers[m].items[p].quantity * orderItems[n].modifiers[m].items[p].price) + "</p> " +
                "           </div> " +
                "        </div> " +
                "   </div> " +
                "</div>";

              subTotal += (orderItems[n].modifiers[m].items[p].quantity * orderItems[n].modifiers[m].items[p].price);
            }
          }
        }
      }
    }

    this.itemTotal = subTotal;
    this.divItems.nativeElement.innerHTML = finalHTML;
  }
}
