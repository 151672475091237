import { Component, ViewChild, OnInit, Renderer2, ElementRef } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { userSubscriptionOrdersService } from './usersubscriptionorders.service';
import { environment } from 'src/environments/environment';
import { CheckoutService } from '../checkout/checkout.service';


declare var $: any;

@Component({
  selector: 'app-usersubscriptionorders',
  templateUrl: './usersubscriptionorders.component.html',
  styleUrls: ['./usersubscriptionorders.component.css']
})
export class UsersubscriptionordersComponent implements OnInit {
  @ViewChild('OrderDetailForm') orderDetailForm: NgForm;
  @ViewChild('rescheduleForm') rescheduleForm: NgForm;
  @ViewChild('divItems') divItems: ElementRef;
  @ViewChild('extraOrderForm') extraOrderForm: ElementRef;
  orderStatus: any;

  currencySymbol: any;
  orderList: any;
  orderDetail: any;
  orderItemList: any;
  orderItemTaxes: any;
  orderReview: any;
  totalOrders: any;

  order_id: any;
  invoiceId: any;
  orderDate: any;
  pickupDate: any;
  orderTotal: any;
  itemTotal: any;
  discountAmount: any;
  packingAmount: any;
  deliveryAmount: any;
  paymentMode: any;

  isReviewAvailable: any;
  reviewUser: any;
  reviewDate: any;
  reviewTitle: any;
  reviewDescription: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  p = 1;

  folioID: any;
  totalPages: any;
  pageLimit: any;
  p1=1;
  orderListforExtraorder: any;
  totalPagesforExtraorder: any;
  totalExtraOrders: any;
  orderDetail1: any;
  folioID1: number;

  constructor(private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService, private service: userSubscriptionOrdersService, private cookieService: CookieService,
    private checkoutService: CheckoutService, private renderer: Renderer2) { }

  ngOnInit() {
    this.currencySymbol = environment.currencySymbol;
    
    if (this.cookieService.get("user") == undefined || this.cookieService.get("user") == "") {
      this.router.navigate(['/home']);
      return;
    }
    this.pageLimit = this.cookieService.get("subscription_order_limit");
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    // this.getTiffinOrderList(this.p);
    this.selectionTab('currentordertab',this.p);
    
  }
  selectionTab(id,page)
  {
    var i, tablinks;
  
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].style.backgroundColor = "";
      tablinks[i].style.color = "black";
    }
    document.getElementById(id).style.backgroundColor = "#094d89";
    document.getElementById(id).style.color = 'white';
    if(id=="currentordertab")
    {
      let today = new Date().toISOString().slice(0, 10);
      $('#toDate').val('');
      $('#fromDate').val('');
      $('#toDate').val(today);
      $('#fromDate').val(today);
      $('#toDate').prop('readonly', true);
      $('#fromDate').prop('readonly', true);
      $('#tabvalue').val(1);
    }
    else if(id=='futureordertab'){
      let today = new Date()
      let tomorrow =  new Date()
      tomorrow.setDate(today.getDate() + 1);
      var tomorrowdate=tomorrow.toISOString().slice(0, 10);
      //console.log.log('tomorrowdate',tomorrowdate);
      $('#toDate').val('');
      $('#fromDate').val('');
      $('#fromDate').prop('readonly', false);
      $('#fromDate').val(tomorrowdate);
      $('#fromDate').prop('readonly', true);
      $('#toDate').prop('readonly', false);
      $('#toDate').attr('min', $("#fromDate").val());
      $('#tabvalue').val(2);
    }
    else
    {
      $('#toDate').val('');
      $('#fromDate').val('');
      $('#toDate').prop('readonly', false);
      $('#fromDate').prop('readonly', false);
      $('#tabvalue').val(3);
    }
    this.getTiffinOrderList(page);
   
  }
 
  // Get the element with id="defaultOpen" and click on it
 
  changeDate(){
    $('#toDate').val('');
    $('#toDate').attr('min', $("#fromDate").val());
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };
    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
        if (response.payload.subscription_order_limit == undefined)
          this.cookieService.set("subscription_order_limit", "10");
        else
          this.cookieService.set("subscription_order_limit", response.payload.subscription_order_limit);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getTiffinOrderList(page) {
    this.p = page;
    var extra_order='';
    if($('#extra_order').is(':checked')==true)
    {
      extra_order="1";
    }
    var obj = {
      "from_date": $("#fromDate").val(),
      "to_date": $("#toDate").val(),
      "order_no": $("#orderNo").val(),
      "limit": "0",
      "offset": (page - 1) * this.pageLimit,
      "location_id": this.cookieService.get("location"),
      "tiffin_for": $("#tiffin_for").val(),
      "order_status": $("#order_status").val(),
      "extra_order": extra_order,
      "tab_value":$('#tabvalue').val()
    };
    this.spinner.show();
    this.service.getTiffinOrderList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderList = response.payload.data;
        this.totalPages = response.payload.total_page;
        this.totalOrders = response.payload.total_record;
        $('#divNoOrders').hide();
        $('html, body').animate({
          scrollTop: $("#MainOrder").offset().top - 200
        }, 1000);
      } else {
        $('#divNoOrders').show();
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      $('#divNoOrders').show();
      this.orderList = [];
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  } 
  getTiffinOrderListForExtraOrder(page) {
    this.p1 = page;
    // var extra_order='';
    // if($('#extra_order').is(':checked')==true)
    // {
    //   extra_order="1";
    // }
    var obj = {
      "from_date": $("#from_date_extra").val(),
      "to_date": $("#to_date_extra").val(),
      "order_no": "",
      "limit": "0",
      "offset": (page - 1) * this.pageLimit,
      "location_id": this.cookieService.get("location"),
      "tiffin_for": $("#tiffin_for_extra").val(),
      "order_status": "",
      "extra_order": ""
    };
    this.spinner.show();
    this.service.getTiffinOrderList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderListforExtraorder = response.payload.data;
        this.totalPagesforExtraorder = response.payload.total_page;
        this.totalExtraOrders = response.payload.total_record;
        $('#divNoOrdersforextra').hide();
        $('html, body').animate({
          scrollTop: $("#MainOrder").offset().top - 200
        }, 1000);
      } else {
        $('#divNoOrdersforextra').show();
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      $('#divNoOrdersforextra').show();
      this.orderListforExtraorder= [];
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  resetTiffinData() {
    $("#fromDate").val('');
    $("#toDate").val('');
    $("#orderNo").val('');
    $("#tiffin_for").val('');
    $("#order_status").val('');
    $('#extra_order').prop('checked', false)
    // this.getTiffinOrderList(1);
    this.selectionTab('currentordertab',this.p);
  }
  resetTiffinDataForExtraOrder() {
    let today = new Date().toISOString().slice(0, 10);
    $('#to_date_extra').val(today);
    $('#from_date_extra').val(today);
    $("#tiffin_for_extra").val('');
  
    this.getTiffinOrderListForExtraOrder(1);
  }

  getOrderDetail(orderDetail) {
    this.spinner.show();
    this.orderDetail = orderDetail;
    // this.orderItemList = response.payload.items;
    // this.orderItemTaxes = response.payload.meta._taxes;

    // this.invoiceId = this.orderDetail.order_id + "_" + this.orderDetail.external_id;
    this.invoiceId = this.orderDetail.order_id;
    this.orderDate = this.orderDetail.order_datetime;
    this.pickupDate = this.orderDetail.order_serve_date;
    this.orderTotal = this.orderDetail.orderAmt;
    this.itemTotal = this.orderDetail.order_product_amount;
    this.discountAmount = this.orderDetail.order_total_discount;
    this.setOrderItemsUI(this.orderDetail.items);

    $("#OrderDetail").modal("show");

    this.spinner.hide();
  }

  closeAllModal() {
    $("#OrderDetail").modal("hide");
    $("#reScheduleOrderModal").modal("hide");
    $("#AddextraOrderModal").modal("hide");
    $("#extraOrderModal").modal("hide");
    $('#itemQuantity').val(1);
    $('#reScheduleDate').val("");
  };
  closeModalforextraorder() {
    $("#extraOrderModal1").modal("hide");
    $('#itemQuantity1').val(1);

  };

  setOrderItemsUI(orderItems) {
    this.divItems.nativeElement.innerHTML = null;
    var finalHTML = "";
    var subTotal = 0;


    // // For Items
    for (var n = 0; n < orderItems.length; n++) {
      finalHTML += "<div class='col-12'> " +
        "    <div class='row'> " +
        "        <div class='col-6'> " +
        "            <div style='height: 100%; line-height: 30px;'> " +
        "                <li style='list-style-type: circle;'>" + orderItems[n].product_name + (orderItems[n].is_jain == 1 ? " <span style='color: #d9b300;font-weight: 700;'>(Jain)</span> " : '') + " (" + orderItems[n].product_weight + ")</li> " +
        "            </div> " +
        "        </div> " +
        "        <div class='col-3'> " +
        "            <div style='text-align: right; display: block;'> " +
        "               <p>" + orderItems[n].product_quantity + " X " + orderItems[n].product_price + "</p> " +
        "            </div> " +
        "       </div> " +
        "        <div class='col-3'> " +
        "           <div style='text-align: right; display: block;'> " +
        "                <p>" + this.currencySymbol + " " + (orderItems[n].product_quantity * orderItems[n].product_price) + "</p> " +
        "           </div> " +
        "        </div> " +
        "   </div> " +
        "</div>";
      subTotal += (orderItems[n].product_quantity * orderItems[n].product_price);

      if (orderItems[n].modifiers) {
        // // For Modifiers
        for (var m = 0; m < orderItems[n].modifiers.length; m++) {
          finalHTML += "<div class='col-12'> " +
            "    <div class='row'> " +
            "        <div class='col-6'> " +
            "            <div style='padding-left: 20px;'> " +
            "                <p>" + orderItems[n].modifiers[m].name + "</p> " +
            "            </div> " +
            "        </div> ";
          if (!orderItems[n].modifiers[m].items) {
            finalHTML += "        <div class='col-3'> " +
              "            <div style='text-align: right; display: block;'> " +
              "               <p>" + orderItems[n].modifiers[m].quantity + " X " + orderItems[n].modifiers[m].price + "</p> " +
              "            </div> " +
              "       </div> " +
              "        <div class='col-3'> " +
              "           <div style='text-align: right; display: block;'> " +
              "                <p>" + this.currencySymbol + " " + (orderItems[n].modifiers[m].quantity * orderItems[n].modifiers[m].price) + "</p> " +
              "           </div> " +
              "        </div> ";

            subTotal += (orderItems[n].modifiers[m].quantity * orderItems[n].modifiers[m].price);
          }
          finalHTML += "   </div> " +
            "</div>";

          if (orderItems[n].modifiers[m].items) {
            // // For Sub Modifiers
            for (var p = 0; p < orderItems[n].modifiers[m].items.length; p++) {
              finalHTML += "<div class='col-12'> " +
                "    <div class='row'> " +
                "        <div class='col-6'> " +
                "            <div style='padding-left: 40px;'> " +
                "                <p>" + orderItems[n].modifiers[m].items[p].name + "</p> " +
                "            </div> " +
                "        </div> " +
                "        <div class='col-3'> " +
                "            <div style='text-align: right; display: block;'> " +
                "               <p>" + orderItems[n].modifiers[m].items[p].quantity + " X " + orderItems[n].modifiers[m].items[p].price + "</p> " +
                "            </div> " +
                "       </div> " +
                "        <div class='col-3'> " +
                "           <div style='text-align: right; display: block;'> " +
                "                <p>" + this.currencySymbol + " " + (orderItems[n].modifiers[m].items[p].quantity * orderItems[n].modifiers[m].items[p].price) + "</p> " +
                "           </div> " +
                "        </div> " +
                "   </div> " +
                "</div>";

              subTotal += (orderItems[n].modifiers[m].items[p].quantity * orderItems[n].modifiers[m].items[p].price);
            }
          }
        }
      }
    }

    this.itemTotal = subTotal;
    this.divItems.nativeElement.innerHTML = finalHTML;
  }

  reScheduleOrder(orderDetail) {
    this.orderDetail = orderDetail;
    $('#reScheduleOrderModal').modal("show");

    $('#reScheduleDate').attr('min', orderDetail["tiffin_grace_period_start_date"]);
    $('#reScheduleDate').attr('max', orderDetail["tiffin_grace_period_end_date"]);
  }
  AddextraOrder() {

    $('#AddextraOrderModal').modal("show");
    let today = new Date().toISOString().slice(0, 10);
    $('#from_date_extra').val('');
    $('#to_date_extra').val('');
    $('#to_date_extra').val(today);
    $('#from_date_extra').val(today);
    this.getTiffinOrderListForExtraOrder(1);
    // $('#reScheduleDate').attr('min', orderDetail["tiffin_grace_period_start_date"]);
    // $('#reScheduleDate').attr('max', orderDetail["tiffin_grace_period_end_date"]);
  }

  reScheduleOrderSubmit() {
    if ($('#reScheduleDate').val() != undefined && $('#reScheduleDate').val() != "" && $('#reScheduleDate').val().length > 0) {
      if ($('#reScheduleDate').val() < this.orderDetail["tiffin_grace_period_start_date"]) {
        var startdate = this.orderDetail["tiffin_grace_period_start_date"];
        var splitdate = startdate.toString().split("-");
        this.toastr.error("Date should not be less then " + splitdate[2] + "/" + splitdate[1] + "/" + splitdate["0"])
        return;
      } else if ($('#reScheduleDate').val() > this.orderDetail["tiffin_grace_period_end_date"]) {
        var startdate = this.orderDetail["tiffin_grace_period_end_date"];
        var splitdate = startdate.toString().split("-");
        this.toastr.error("Date should not be greater then " + splitdate[2] + "/" + splitdate[1] + "/" + splitdate["0"])
        return;
      }
    }
    var obj = {
      "order_id": this.orderDetail["order_id"],
      "order_delivery_date": $('#reScheduleDate').val(),
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.reScheduleOrder(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        this.closeAllModal();
        this.getTiffinOrderList(this.p);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  extraOrder(orderDetail) {
    this.orderDetail = orderDetail;
    $('#extraOrderModal').modal("show");
    this.folioID = 1;
  }
  extraOrder1(orderDetail) {
    this.orderDetail = orderDetail;
    $('#extraOrderModal1').modal("show");
    this.folioID = 1;
  }

  extraOrderSubmit(id) {

    // var objItems = {
    //   "folioid": this.orderDetail["order_id"],
    //   "qty": $('#itemQuantity').val(),
    // }
    var qty='';
    if(id ==1)
    {
      qty=$('#itemQuantity').val();
    }
    else if(id == 2)
    {
      qty=$('#itemQuantity1').val();
    }
    var objCart = {
      "payment_method": "",
      "cart": {
        "folioid": this.orderDetail["order_id"],
        "qty": qty,
      }
    }

    localStorage.setItem(this.cookieService.get("location") + "_extrasubscriptionDetail", JSON.stringify(objCart));
    $('#extraOrderModal').modal("hide");
    $('#extraOrderModal1').modal("hide");
    $('#AddextraOrderModal').modal("hide");
    this.router.navigate(['/placeextraordersubscription']);
    // var obj = {
    //   "folioid": this.orderDetail["order_id"],
    //   "qty": $('#itemQuantity').val(),
    //   "location_id": this.cookieService.get("location")
    // };

    // this.spinner.show();
    // this.service.addExtraTiffinOrder(obj).subscribe(response => {
    //   if (response.status == "ok") {
    //     this.toastr.success(response.message);
    //     this.closeAllModal();
    //     this.getTiffinOrderList();
    //   } else {
    //     this.toastr.error(response.message);
    //   }
    //   this.spinner.hide();
    // }, (err: HttpErrorResponse) => {
    //   this.spinner.hide();
    // });
  }

  cancleExtraOrder(orderDetail) {
    if (confirm("Are you sure you want to cancel extra order ?")) {
      var obj = {
        "order_id": orderDetail["order_id"],
        "location_id": this.cookieService.get("location")
      };
      this.spinner.show();
      this.service.cancelExtraOrder(obj).subscribe(response => {
        if (response.status == "ok") {
          this.toastr.success(response.message);
          this.getTiffinOrderList(this.p);
        } else {
          this.toastr.error(response.message);
        }
        this.spinner.hide();
      }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
      });
    }
  }

  countItemChange(status) {
    this.spinner.show();
    var value = parseInt($('#itemQuantity').val());
    if (value > 0) {
      if (status == 0) {
        if (value == 1) {
          this.toastr.error("You have to select atleast 1 Qty.");
          this.spinner.hide();
          return;
        }
        value = value - 1;
        $('#itemQuantity').val(value);
      } else {
        value = value + 1;
        $('#itemQuantity').val(value);
      }
    }
    this.spinner.hide();
  }
  countItemChange1(status) {
    this.spinner.show();
    var value = parseInt($('#itemQuantity1').val());
    if (value > 0) {
      if (status == 0) {
        if (value == 1) {
          this.toastr.error("You have to select atleast 1 Qty.");
          this.spinner.hide();
          return;
        }
        value = value - 1;
        $('#itemQuantity1').val(value);
      } else {
        value = value + 1;
        $('#itemQuantity1').val(value);
      }
    }
    this.spinner.hide();
  }

}
