import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class userSubscriptionOrdersService {

  constructor(private http: HttpClient) { }

  getLocationDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/locations/getbyid?${environment.queryStringData}`, data);
  }
  
  getTiffinOrderList(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/tiffinorderlist?${environment.queryStringData}`, data); 
  }
  
  addExtraTiffinOrder(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/addextratiffinorder?${environment.queryStringData}`, data); 
  }
  
  reScheduleOrder(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/canceltiffinorder?${environment.queryStringData}`, data); 
  }
  
  cancelExtraOrder(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/cancelextraorder?${environment.queryStringData}`, data); 
  }

}
