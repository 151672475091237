<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="cart-resize pl-3 pr-3" style="height: 100vh;" *ngIf="offersTotal != 0">
            <div class="pt-3 pb-3">
                <h2 class="font-weight-bold m-0 pt-1 with-title">Latest Offers</h2>
            </div>
            <div class="row ml-0 mr-0">
                <div class="card col-lg-4 col-xs-12 mb-3" *ngFor="let offers of offerList">
                    <div class="card-body">
                        <!-- <h5 class="card-title coupon-code-box" id="coupon-code">{{offers.title}}</h5> -->
                        <input type="text" class="card-title coupon-code-box" id="{{offers.z_promocodeid_pk}}" value="{{offers.promocode}}" #userinput disabled>
                        <h6 class="card-subtitle mb-2 text-muted">
                            <span class="badge badge-dark">{{offers.promocode}}</span>
                        </h6>
                        <p class="card-text"></p>
                        <button class="btn btn-primary btn-block card-link" (click)="copyOfferCode(userinput,offers.z_promocodeid_pk)" value="{{offers.promocode}}">Copy Code</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="cart-resize pl-3 pr-3" style="height: 100vh;" *ngIf="offersTotal == 0">
            <div class="pt-3 pb-3">
                <h2 class="font-weight-bold m-0 pt-1 with-title text-center">No Offers Available</h2>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>